import { ApplicationRef, Component, OnInit } from '@angular/core';
import { VersionService, TranslateService, StorageService } from '@evolenta/core';
import { BreadcrumbService } from '@evolenta/breadcrumbs';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
    public title = 'Main App';

    public options = {
        position: ['bottom', 'left'],
        timeOut: 5000,
        lastOnBottom: true,
        showProgressBar: false,
    };

    public constructor(
        public translate: TranslateService,
        private versionService: VersionService,
        // Сервис нужен для отображения навигационной цепочки после перезагрузки страницы
        private breadcrumbService: BreadcrumbService,
        private storageService: StorageService,
        private appRef: ApplicationRef,
    ) {
        translate.setDefaultLang('ru');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        const storedLanguage = this.storageService.getItem('languageCode');
        translate.use(storedLanguage || 'ru');

        (window as any).appRef = appRef;
    }

    public ngOnInit(): void {
        // void this.versionService.getVersion(true);
    }
}
