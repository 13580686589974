<div [ngClass]="{ 'pl-20 pr-20': mode == 'edit' }">
    <!-- Ошибки при валидации карточки услуги -->
    <ng-template #errorsTemplate
        ><span class="text-semibold">Ошибки услуги:</span>
        <ul [innerHtml]="getErrorsList()" class="no-margin"></ul
    ></ng-template>
    <button
        class="btn btn-danger pull-right btn-badge"
        *ngIf="checkErrorsExist()"
        [popover]="errorsTemplate"
        [outsideClick]="true"
        placement="left"
    >
        ошибки
    </button>

    <p *ngIf="subservice.standardCode"><span class="text-muted">Код услуги: </span> {{ subservice.standardCode }}</p>
    <p *ngIf="appealService.appeal.pguEventId">
        <span class="text-muted">Номер заявления на портале: </span> {{ appealService.appeal.pguEventId }}
    </p>
    <p *ngIf="appealService.appeal.pgu2EventId">
        <span class="text-muted">Номер заявления на портале 2.0: </span> {{ appealService.appeal.pgu2EventId }}
    </p>
    <p *ngIf="appealService.appeal.epguOrderId">
        <span class="text-muted">Номер заявления на ЕПГУ: </span> {{ appealService.appeal.epguOrderId }}
    </p>

    <!-- Активный подстатус -->
    <p *ngIf="appealSubservice.status.isSubstatus">
        <span class="text-muted">Активный подстатус: </span> {{ appealSubservice.status.name }}
        <span class="text-size-small" *ngIf="appealSubservice.status.description"
            >({{ appealSubservice.status.description }})</span
        >
    </p>

    <!-- Вариант услуги -->
    <p *ngIf="subservice.variants && subservice.variants.length > 0">
        <span class="text-muted">Вариант: </span>
        <ng-container *ngIf="appealSubservice.variant">
            <ng-container *ngFor="let item of appealSubservice.variant.selectedVariants; let lst = last">
                {{ item.name }}
                <span class="ml-5 mr-5" *ngIf="!lst">/</span>
            </ng-container>
            <span class="anchor text-size-small ml-5 text-info" (click)="activateTab('variant')" *ngIf="data.allowEdit"
                >изменить</span
            >
        </ng-container>
        <span
            class="anchor"
            [ngClass]="{
                'text-danger': checkElementHasError('variant', 'variant'),
                'text-info': !checkElementHasError('variant', 'variant')
            }"
            *ngIf="!appealSubservice.variant"
            (click)="activateTab('variant')"
            >не выбран</span
        >
    </p>

    <!-- Участники / объекты -->
    <ng-container *ngIf="getSubjectsCount() > 0">
        <p class="mb-3 text-muted">Участники:</p>

        <ng-container *ngIf="subjects.length > 0">
            <ul class="mb-10">
                <ng-container *ngFor="let subject of subjects">
                    <li *ngIf="data.subjects[subject.guid].active">
                        <!-- Header Объекта, вид участия, представитель -->
                        <div class="display-inline-block mb-3">
                            <!-- Наименование объекта, вид участия -->
                            <span class="text-semibold">{{ subject.header }}</span>
                            <!--<span *ngIf="data.objects[object.guid].subKind"> - {{data.objects[object.guid].subKind.firstGroupName}}</span>-->

                            <!-- Группа, категория -->
                            <div
                                class="text-size-small display-inline-block ml-5"
                                *ngIf="data.subjects[subject.guid].group"
                            >
                                <span class="text-muted">(</span>
                                <span class="text-muted">Группа: </span> {{ data.subjects[subject.guid].group?.name }}
                                <span class="text-muted ml-5">Категория: </span>
                                <ng-container *ngIf="data.subjects[subject.guid].categories">
                                    <ng-container
                                        *ngFor="let category of data.subjects[subject.guid].categories; let lst = last"
                                    >
                                        {{ category.name }}<span *ngIf="!lst" class="ml-5 mr-5">/</span>
                                    </ng-container> </ng-container
                                ><span class="text-muted">)</span>
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </ng-container>
    </ng-container>

    <!-- Наличие Дополнительных данных по услуге / варианту -->
    <p
        *ngIf="(appealSubservice.xsd || appealSubservice.variantXsd) && mode == 'view'"
        [ngClass]="{ 'no-margin-bottom': !hasLicense }"
    >
        <i class="icon-exclamation text-info"></i> <span class="text-semibold">Внимание!</span> Дело содержит
        <span class="anchor text-info" (click)="activateTab('additionalData')">дополнительные поля</span>
        <span
            class="text-success ml-10"
            *ngIf="
                ((appealSubservice.xsd && appealSubservice.xsdDataValid) || !appealSubservice.xsd) &&
                ((appealSubservice.variantXsd && appealSubservice.variantXsdDataValid) || !appealSubservice.variantXsd)
            "
            >(данные заполнены корректно)</span
        >
    </p>

    <p *ngIf="hasLicense" class="no-margin-bottom">
        <span class="text-bold mr-5 text-uppercase">{{ licenseTitle }}:</span>
        № {{ appealSubservice.mainElement.license?.number || appealSubservice.mainElement.orderData?.number }}
        <ng-container
            *ngIf="appealSubservice.mainElement.license?.dateIssued || appealSubservice.mainElement.licenseDateIssued"
        >
            от
            {{
                appealSubservice.mainElement.license?.dateIssued || appealSubservice.mainElement.orderData?.date
                    | date : 'shortDate'
            }}
            г.
        </ng-container>
        <a class="text-size-small anchor text-info ml-10" (click)="goToLicense(appealSubservice.mainElement)"
            >перейти</a
        >
    </p>
</div>
