import { Injectable } from '@angular/core';
import { StorageService, RestService, FiltersService, SelectionService, ApplicationsService } from '@evolenta/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { CommonAppealService } from './services/common-appeal.service';
import { SettingsService } from '../../common/services/settings.service';
import { UtilityService } from '../../common/services/utility.service';
import { Config } from '../../common/services/config';
import { settings } from './common-appeals-settings';
import { AppealService } from '../knm/appeals/appeal.service';
import { SUBSERVICES_ARCHIVE_COLLECTION, SUBSERVICES_COLLECTION } from '../../common/constants';

@Injectable()
export class CommonAppealsResolve implements Resolve<any> {
    public constructor(
        private rest: RestService,
        private storage: StorageService,
        private appealService: AppealService,
        private commonAppealService: CommonAppealService,
        private selectionService: SelectionService,
        private filtersService: FiltersService,
        private settingsService: SettingsService,
        private applicationsService: ApplicationsService,
        private utility: UtilityService,
        private http: HttpClient,
    ) {}

    public async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.commonAppealService.metaReglament = null;

        let appeal;
        let complexSubserviceParams = {};
        let printForms;
        await this._getDocumentsSettings();
        await this._getChildrenOrganizationsId();

        const metaReglament = await this._getMetaReglament(route);
        await this._getUnitAdditionalParams(metaReglament);
        const usedSettings = await this._getSettings(metaReglament);
        await this.settingsService.load(
            usedSettings ? this.settingsService.prepareSettings(usedSettings) : settings,
            () => this._getSubjectsObjectsSearchParams(),
        );
        // tslint:disable-next-line:prefer-const
        let { appeals, subservice, appealId } = await this._getData(route);
        if (appealId) {
            appeal = await this._getAppeal(appealId, route);
            complexSubserviceParams = await this._getComplexSubservice(appeal);
            printForms = await this._getPrintForms(appeal);

            if (!subservice && appeal.subservice && appeal.subservice.id) {
                subservice = await this.rest.find('subservices', appeal.subservice.id);
                if (!subservice || Object.keys(subservice).length === 0) {
                    subservice = await this.rest.find('subservices_archive', appeal.subservice.id);
                }
            }
        }

        return { appeal, appeals, subservice, metaReglament, printForms, ...complexSubserviceParams };
    }

    private async _getMetaReglament(route) {
        const metaReglamentCode = route.data.metaReglamentCode ? route.data.metaReglamentCode : null;
        if (!metaReglamentCode) {
            return;
        }

        const metaReglaments = await this.rest.search('metaReglaments', {
            search: { search: [{ field: 'code', operator: 'eq', value: metaReglamentCode }] },
        });
        if (!(metaReglaments && metaReglaments.length)) {
            return;
        }

        this.commonAppealService.metaReglament = metaReglaments[0];

        return metaReglaments[0];
    }

    private async _getUnitAdditionalParams(metaReglament) {
        if (!metaReglament) {
            return;
        }

        const appealBlock = metaReglament.blocks.find(item => item.code === 'appealData');
        if (!(appealBlock && appealBlock.unitAdditionalParams)) {
            return;
        }

        const currentOrganization = this.storage.getItem('currentOrganization');
        let needUpdate = false;
        appealBlock.unitAdditionalParams.forEach(param => {
            if (currentOrganization[param] === undefined) {
                needUpdate = true;
            }
        });

        if (!needUpdate) {
            return;
        }

        const unit = await this.rest.find('organizations', currentOrganization._id);
        appealBlock.unitAdditionalParams.forEach(param => {
            currentOrganization[param] = unit[param] ? unit[param] : null;
        });
        this.storage.setItem('currentOrganization', currentOrganization);
    }

    private async _getDocumentsSettings() {
        const promises = [];
        const uploadedFileMaxSize = this.storage.getItem('uploadedFileMaxSize');
        if (uploadedFileMaxSize === undefined || uploadedFileMaxSize === null) {
            promises.push(
                this.rest.search('settings', {
                    search: { search: [{ field: 'name', operator: 'eq', value: 'uploadedFileMaxSize' }] },
                }),
            );
        }
        const signApplicationParam = this.storage.getItem('signApplicationParam');
        if (!signApplicationParam) {
            promises.push(this.http.post(Config.server + 'getSignApplicationParam', {}).toPromise());
        }

        const data = await Promise.all(promises);

        if (uploadedFileMaxSize === undefined || uploadedFileMaxSize === null) {
            if (data[0] && data[0].length > 0) {
                const sizeInMb = parseInt(data[0][0].value, 10);
                const maxSizeInBt = sizeInMb * 1024 * 1024;
                this.storage.setItem('uploadedFileMaxSize', maxSizeInBt);
            } else {
                this.storage.setItem('uploadedFileMaxSize', 0);
            }
        }
        if (!signApplicationParam) {
            const value = uploadedFileMaxSize === undefined || uploadedFileMaxSize === null ? data[1] : data[0];
            if (value) {
                this.storage.setItem('signApplicationParam', {
                    url: value.value.url,
                    systemUid: value.value.systemUid,
                });
            } else {
                this.storage.setItem('signApplicationParam', {});
            }
        }
    }

    private _getSettings(metaReglament): Promise<any> {
        let usedSettings = null;

        if (!metaReglament) {
            return usedSettings;
        }

        if (!(metaReglament.isOldVersion && metaReglament.appealsDefaultSettings)) {
            const foundCode = metaReglament.blocks.find(item => item.code === 'appealData');

            if (foundCode && foundCode.appealsDefaultSettings) {
                usedSettings = foundCode.appealsDefaultSettings;
            }

            return usedSettings;
        }

        usedSettings = metaReglament.appealsDefaultSettings;

        return usedSettings;
    }

    public async _getSubjectsObjectsSearchParams() {
        const promises = [];
        promises.push(this.filtersService.getCollectionSearchParams('subjects'));
        promises.push(this.filtersService.getCollectionSearchParams('subjectsKno'));
        promises.push(this.filtersService.getCollectionSearchParams('objects'));
        promises.push(this.filtersService.getCollectionSearchParams('objectsKno'));

        await Promise.all(promises);
    }

    private async _getChildrenOrganizationsId() {
        const unit: any = this.storage.getItem('currentOrganization');
        if (unit.childrenOrganizationsIds) {
            return;
        }

        const children = await this.rest.search('organizations', {
            search: { search: [{ field: 'parentId', operator: 'eq', value: unit._id }] },
            prj: 'onlyName',
            size: 1000,
        });
        unit.childrenOrganizationsIds = children.map(item => item._id);
        this.storage.setItem('currentOrganization', unit);
    }

    private async _getData(route): Promise<any> {
        const appealId = route.params.appealId;
        const subserviceId = route.params.subserviceId;
        const complexSubserviceId = route.params.complexSubserviceId;
        const currentUnit: any = this.storage.getItem('currentOrganization');

        if (
            (appealId || subserviceId || complexSubserviceId) &&
            this.commonAppealService.isProcessSetupAppealSubservices
        ) {
            return {};
        }

        if (appealId) {
            return { appealId };
        }

        if (subserviceId) {
            // Создание дела на основе услуги
            const subservice = await this.rest.find(SUBSERVICES_COLLECTION, subserviceId);

            return {
                subservice,
            };
        }

        if (complexSubserviceId) {
            // Создание дела на основе комплексной услуги
            return this.rest.find('subservicesComplex', complexSubserviceId);
        }

        let usedUnitIds = [currentUnit._id];
        if (currentUnit.childrenOrganizationsIds.length) {
            usedUnitIds = usedUnitIds.concat(currentUnit.childrenOrganizationsIds);
        }

        // Получение списка дел
        const search: any[] = [
            {
                field: 'unit.id',
                operator: 'in',
                value: usedUnitIds,
            },
        ];

        if (this.selectionService.isProcessSelect && Array.isArray(this.selectionService.baseSearch)) {
            search.push(...this.selectionService.baseSearch);
        }

        const collection = this.commonAppealService.getAppealsCollection();
        if (route.data.applicationProperty) {
            let unitRegistryTypes = currentUnit.registryTypes ? currentUnit.registryTypes.map(item => item.code) : [];
            const propertyName = route.data.applicationProperty;
            const routePath = route.parent.parent.url[0].path;
            const propertyValue = this.applicationsService.getApplicationPropertyByApplicationPath(
                routePath,
                propertyName,
            );
            if (propertyValue) {
                if (propertyName === 'usedRegistryTypes') {
                    search.push({
                        field: 'subservices.mainElement.registryEntryType.code',
                        operator: 'in',
                        value: propertyValue.filter(
                            item => unitRegistryTypes.length === 0 || unitRegistryTypes.indexOf(item) !== -1,
                        ),
                    });
                } else if (propertyName === 'notUsedRegistryTypes') {
                    if (unitRegistryTypes.length > 0) {
                        unitRegistryTypes = unitRegistryTypes.filter(item => propertyValue.indexOf(item) === -1);
                    }
                    search.push({
                        field: 'subservices.mainElement.registryEntryType.code',
                        operator: 'in',
                        value: unitRegistryTypes,
                    });
                }
            }
        }

        const sortFieldNotNullClause = {
            field: 'datePlaneFinish',
            operator: 'neq',
            value: null,
        };

        search.push(sortFieldNotNullClause);

        const appeals = await this.rest.search(collection, {
            search: { search },
            sort: 'datePlaneFinish',
            prj: 'appealList',
        });

        return {
            appeals,
        };
    }

    /**
     * Получение дела
     * @param appealId - ID дела
     * @returns {Promise<any>}
     * @param route
     */
    private async _getAppeal(appealId, route) {
        const collection = this.commonAppealService.getAppealsCollection();
        const archiveCollection = collection + 'Archive';

        return (await this.rest.find(collection, appealId)) || (await this.rest.find(archiveCollection, appealId));
    }

    /**
     * Получение комплексной услуги
     * @returns {any}
     */
    private async _getComplexSubservice(appeal) {
        let complexSubservice;
        if (appeal.complexSubservice) {
            complexSubservice = await this.rest.find('subservicesComplex', appeal.complexSubservice.id);
        }

        // Получение услуг, по которым создано дело
        if (!appeal.subservice) {
            return;
        }

        const subservices = [];
        const service =
            (await this.rest.find(SUBSERVICES_COLLECTION, appeal.subservice.id)) ||
            (await this.rest.find(SUBSERVICES_ARCHIVE_COLLECTION, appeal.subservice.id));

        return { complexSubservice, subservices, service };
    }

    private async _getPrintForms(appeal) {
        const search = [];
        // Получение только не удаленных записей
        search.push({
            field: 'isDeleted',
            operator: 'neq',
            value: true,
        });
        // Определение организация МФЦ + ОГВ
        let orgs = [appeal.unit.id];
        let responsibleOrganizationIds = [];

        if (appeal.subservice.responsibleOrganization && appeal.subservice.responsibleOrganization.id) {
            responsibleOrganizationIds = [appeal.subservice.responsibleOrganization.id];
        }

        orgs = orgs.concat(responsibleOrganizationIds);
        const subservicesIds = [appeal.subservice.serviceId];

        // Настройка других параметров поиска
        search.push({
            orSubConditions: [
                {
                    field: 'serviceIds',
                    operator: 'in',
                    value: subservicesIds,
                },
                {
                    field: 'orgIds',
                    operator: 'in',
                    value: orgs,
                },
            ],
        });
        search.push({
            andSubConditions: [
                {
                    field: 'notUseServiceIds',
                    operator: 'nin',
                    value: subservicesIds,
                },
                {
                    field: 'notUseOrgIds',
                    operator: 'neq',
                    value: appeal.unit.id,
                },
            ],
        });

        return await this.rest.search('printForms', { search: { search }, size: 100 });
    }
}
