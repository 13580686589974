import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AccessService, ModalDialogComponent, StorageService, ToasterService, TranslateService } from '@evolenta/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { DocumentService } from '../../document.service';
import { AppealSaveService } from '../../../../appeal-save.service';
import { AppealValidateService } from '../../../../appeal-validate.service';
import { Permission } from '../../../../../../../common/services/permission';
import { AppealDocumentCommonComponent } from '../document-common/appeal-document-common.component';
import { AppealDocumentPropertiesComponent } from '../document-properties/appeal-document-properties.component';
import { AppealService } from '../../../../appeal.service';
import * as _ from 'lodash-es';
import { EDIT_MODE, VIEW_MODE } from '../../../../../../../common/constants';
import { RsoService } from '../../../../../../../common/services/rso.service';
import cloneDeep from 'lodash-es/cloneDeep';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorLoggingService } from '../../../../../error-logging.service';
import { TemplatesService } from '../../../../../../templates/templates.component';

@Component({
    selector: 'appeal-document-card',
    templateUrl: 'appeal-document-card.component.html',
})
export class AppealDocumentCardComponent implements OnInit, AfterViewInit {
    @Input() public document; // документ дела
    @Input() public group; // группа документов, к которой принадлежит обрабатываемый документ
    @Input() public mode; // режим работы: view - просмотр, edit - редактирование
    @Input() public activeTab; // активная вкладка
    @Input() public appeal; // формируемое дело
    @Input() public subservice; // описание услуги на основе которых формируется дело
    @Input() public sendEnvelope; // флаг необходимости отправки запроса из конверта сразу после иниициализации компонента
    @Input() public task;
    @Input() public allowEdit = true;

    @Output() public onEdit = new EventEmitter<object>();
    @Output() public onApply = new EventEmitter<any>();
    @Output() public onPrint = new EventEmitter<object>();
    @Output() public onDelete = new EventEmitter<object>();
    @Output() public updateAppealAfterSendStatusEnvelope = new EventEmitter<boolean>();

    public editTabs = [];
    public isProcessSelectDocumentKind = false; // Флаг отображения процесса выбора вида документа из списка доступных видов для группы
    public isProcessEditDocumentName = false; // Флаг редактирования наименования документа (произвольные документы)
    public isProcessSimplifyAddDocumentToComplex = false; // Флаг добавления документа в группу (документы комплексного запроса)
    public availableOtherDocuments = []; // Массив документов, доступных для выбора в виде ссылки
    public isProcessSelectAvailableDocument = false; // Флаг отображения процесса выбора документа, на который ссылается текущий документ

    public data; // описательный массив обрабатываемого документа дела
    public allDocumentsData; // описательный объект всех документов дела
    public startAfterSubservices = null; // Массив услуг, после которых стартует услуга обрабатываемого документа
    public isProcessSelectResultSubserviceLink = false; // Флаг отображения процесса выбора услуги, на результут которой ссылается текущий документ
    @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDialogComponent;
    @ViewChild('deleteTemplateModal', { static: false }) public deleteTemplateModal: ModalDialogComponent;

    public isProcessSendingEnvelope = false; // Флаг осуществления процесса отправки запроса
    @ViewChild('commonData', { static: false }) public documentCommonDataComponent: AppealDocumentCommonComponent;
    @ViewChild('appealDocumentProperties', { static: false })
    public appealDocumentPropertiesComponent: AppealDocumentPropertiesComponent;

    public continueProcessingAppeal; // флаг продолжения операции сохранения (перехода на другой статус) дела после применения изменений в услуги
    public currentOrganization;

    public permissions = Permission; // Набор прав системы

    // Произвольный документ
    public otherDocument = {
        guid: 'other',
        name: 'Произвольный документ',
    };

    public documentsForComplex = []; // Массив видов документов для упрощенного режима комплексного дела
    public selectedDocumentFromList; // Выбранный документ из списка

    public groupsForCopyFiles = [];

    public modalRef: BsModalRef;
    @ViewChild('cloneFilesModal', { static: false }) public cloneFilesModal: TemplateRef<any>;
    @ViewChild('chooseTemplatesModal', { static: false }) public chooseTemplatesModal: TemplateRef<any>;
    @ViewChild('saveTemplateModal', { static: false }) public saveTemplateModal: TemplateRef<any>;
    public cloneFilesData: any = {};
    public localizations;
    public initialized;
    public sedPraktikaModel;
    public selectedDocumentKind;
    private moduleBaseUrl;

    public isDisabledByRso = false;

    public isEnableTemplates = false;
    @Input() public templates;
    public selectedTemplate: any = {};

    public constructor(
        public accessService: AccessService,
        private documentService: DocumentService,
        private appealSaveService: AppealSaveService,
        private validateService: AppealValidateService,
        private storage: StorageService,
        private toaster: ToasterService,
        private modalService: BsModalService,
        private translate: TranslateService,
        private appealService: AppealService,
        private rsoService: RsoService,
        private errorLoggingService: ErrorLoggingService,
        private router: Router,
        private route: ActivatedRoute,
        private templatesService: TemplatesService,
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this._loadTranslations();
        this.route.parent.parent.url.subscribe(urlPath => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });
        this.data = this.documentService.data[this.document.guid]; // описательный массив обарабатываемого документа
        this.allDocumentsData = this.documentService.data;
        this.currentOrganization = this.storage.getItem('currentOrganization');
        if (this.mode === EDIT_MODE) {
            // Задание вкладки по умолчанию
            if (!this.activeTab) {
                this.activeTab = 'common';
            }

            // Инициализация режима выбора вида документа
            if (this.group.docs && !this.document.docGuid && !this.data.name) {
                this.isProcessSelectDocumentKind = true;
            }

            // Инициализация массива видов документов для выбора в составе набора документов комплексного запроса (упрощенный режим)
            if (this.appeal.simplifyMode) {
                this.generateDocumentsListForComplex();
            }

            // Инициализация режима ввода наименования документа (выбор из списка для документов комплекса)
            if (!this.group.guid && !this.data.name) {
                this.isProcessSelectDocumentKind = true;
                if (this.group.code) {
                    this.isProcessSimplifyAddDocumentToComplex = true;
                } else {
                    this.isProcessEditDocumentName = true;
                }
            }

            // КНМ доступных для выбора документов в виде документа-связи
            if (this.document.docGuid) {
                this._checkEnableOtherDocumentLink();
            }

            // Инициализация вкладок
            this._initEditTabs();

            // КНМ возможности создания ссылки на результат предоставления другой услуги
            this._checkEnableOtherSubserviceResultLink();

            // КНМ на уже запущенный процесс отправки запроса
            if (
                this.data.envelope &&
                this.data.envelope.resultSending &&
                this.data.envelope.resultSending.status &&
                (this.data.envelope.resultSending.status === 'process' ||
                    this.data.envelope.resultSending.status === 'needSign')
            ) {
                this.isProcessSendingEnvelope = true;
            }
        }
        this.isDisabledByRso = this.rsoService.canNotEditAppeal(this.appeal);
    }

    public _loadTranslations() {
        this.translate.get(['common', 'appeals.documents']).subscribe((res: any) => {
            this.localizations = res;
        });
    }

    public ngAfterViewInit() {
        if (
            !this.data.doc &&
            this.group &&
            this.group.docs &&
            this.group.docs.length === 1 &&
            this.mode === EDIT_MODE
        ) {
            if (!(this.data.envelope && this.data.envelope.auto)) {
                this.selectDocumentKind(this.group.docs[0]);
                setTimeout(() => {
                    this.apply();
                }, 100);
            }
        }
    }

    /**
     * Формирование списка возможных видов документов для комплексного дела
     */
    public generateDocumentsListForComplex() {
        this.documentsForComplex = [];
        Object.keys(this.documentService.appealSubservicesData).forEach(appealSubserviceGuid => {
            const appealSubserviceGroups =
                this.documentService.appealSubservicesData[appealSubserviceGuid].appealDocumentGroups;
            appealSubserviceGroups.forEach(group => {
                group.docs.forEach(doc => {
                    if (!doc.requestId) {
                        const find = this.documentsForComplex.find(item => item.id === doc.code);
                        if (!find) {
                            this.documentsForComplex.push({ id: doc.code, text: doc.name });
                        }
                    }
                });
            });
        });

        this.documentsForComplex = _.sortBy(this.documentsForComplex, 'text');
    }

    /**
     * Выбор документа из списка всех услуг дела (комплексный запрос)
     * @param value
     */
    public selectDocumentForComplexFromList(value) {
        this.data.name = value.text;
        this.data.docCode = value.id;
        this.isProcessSelectDocumentKind = false;
        this.isProcessSimplifyAddDocumentToComplex = false;
    }

    /**
     * Нажатие ссылки "Изменить"
     */
    public changeDocument() {
        if (this.document.groupCode) {
            this.isProcessSimplifyAddDocumentToComplex = true;
            if (this.data.docCode) {
                const find = this.documentsForComplex.find(item => item.id === this.data.docCode);
                if (find) {
                    this.selectedDocumentFromList = find;
                }
            }

            return;
        }

        this.isProcessEditDocumentName = true;
    }

    /**
     * Определение набора вкладок для редактирования информации в случае обычного документа или документа запроса
     */
    private _initEditTabs() {
        this.editTabs = [
            {
                code: 'common',
                name: !this.document.requestId ? 'Общие данные' : 'Данные запроса',
                visible: true,
            },
            {
                code: 'files',
                name: 'Ответ',
                visible: this.document.requestId && !this.data.link && !this.data.resultSubserviceLink,
            },
            {
                code: 'properties',
                name: 'Дополнительные данные',
                visible:
                    !this.document.requestId &&
                    this.data.allowPartiallyEdit &&
                    !this.data.link &&
                    !this.data.resultSubserviceLink,
            },
        ];

        // Выставление флага активности для вкладки
        if (this.activeTab) {
            const find = this.editTabs.find(item => item.code === this.activeTab);
            if (find) {
                find.active = true;
            }
        }
    }

    /**
     * Выбор вида документа
     * @param doc
     */
    public selectDocumentKind(doc) {
        this.selectedDocumentKind = doc;
        this.documentService.selectDocumentKind(this.document, this.data, doc);
        if (doc.guid !== 'other') {
            this.activeTab = 'common';
            this._checkEnableOtherDocumentLink();
            this._initEditTabs();
        } else {
            this.isProcessEditDocumentName = true;
        }
        this.isProcessSelectDocumentKind = false;
        this.initialized = true;

        if (this.sedPraktikaModel) {
            this.data.envelope.xml = this.sedPraktikaModel;
        }

        this.isEnableTemplates = this.documentService.checkTemplatesEnabled(this.selectedDocumentKind.name);

        if (this.selectedTemplate) {
            this.data.envelope.xml = this.selectedTemplate[0].xml;
        }
    }

    public async fillWithPreviousValues() {
        this.documentService.sedPraktikaNotifications =
            this.localizations['appeals.documents'].sed_praktika.notifications;
        this.sedPraktikaModel = await this.documentService.getSedPraktikaModel(this.appeal, this.selectedDocumentKind);
        if (this.sedPraktikaModel) {
            this.isProcessSelectDocumentKind = true;
            setTimeout(() => {
                this.selectDocumentKind(this.selectedDocumentKind);
                this.toaster.success(this.localizations['appeals.documents'].sed_praktika.notifications.success);
            });
        }
    }

    public clearAll() {
        this.isProcessSelectDocumentKind = true;
        delete this.sedPraktikaModel;
        delete this.data.envelope.xml;
        delete this.documentService.data[this.document.guid].envelope.xml;
        delete this.documentService.data[this.document.guid].envelope.request;
        delete this.documentService.data[this.document.guid].envelopeData;
        setTimeout(() => {
            this.isProcessSelectDocumentKind = false;
            this.selectDocumentKind(this.selectedDocumentKind);

            this.toaster.success(this.localizations['appeals.documents'].sed_praktika.notifications.all_fields_cleaned);
        });
    }

    /**
     * КНМ возможности создания линка на документ из списка документов других услуг (набора документов из группы "Комплексный запрос") из состава комплексной услуги
     */
    private _checkEnableOtherDocumentLink() {
        this.availableOtherDocuments = [];
        if (!this.appeal.subservice) {
            return;
        }

        // TODO проверить на комплексную услугу
        // Выборка возможных линков для документа который является результатом предоставления другой услуги
        if (this.data.resultSubserviceLink) {
            // Выбор принадлежащих услуге документов, от которой зависит текущий документ
            const documentsFromLinkSubservice = this.appeal.documents.filter(
                item => item.subserviceGuid === this.data.resultSubserviceLink.guid,
            );
            // Из полученного массива выбираем только те, которые принадлежат исходящим группам документов (isOutput = true)
            if (
                documentsFromLinkSubservice.length &&
                this.appeal.subservice.guid === this.data.resultSubserviceLink.guid
            ) {
                documentsFromLinkSubservice.forEach(document => {
                    if (
                        this.documentService.data[document.guid].group &&
                        this.documentService.data[document.guid].group.isOutput
                    ) {
                        this.availableOtherDocuments.push({
                            guid: document.guid,
                            name: document.name,
                            group: this.documentService.data[document.guid].group.name,
                            subservice: this.appeal.subservice.shortTitle,
                        });
                    }
                });
            }

            return;
        }

        if (!(this.data.doc && this.data.doc.code)) {
            return;
        }

        // Выборка возможных линков документов при совпадении кода документа
        const availableDocuments = this.appeal.documents.filter(
            item =>
                (item.docCode === this.data.doc.code && !item.link && item.guid !== this.document.guid) ||
                (!item.docCode && item.groupCode === 'complex'),
        );
        if (!availableDocuments.length) {
            return;
        }

        // Определяем услугу, к которой относится текущий документ
        let currentDocumentSubservice = null;
        const currentDocumentGroup = this.subservice.documentGroups.find(item => item.guid === this.group.guid);
        if (currentDocumentGroup) {
            currentDocumentSubservice = this.subservice;
        }

        availableDocuments.forEach(availableDocument => {
            if (availableDocument.subserviceGuid) {
                if (this.subservice._id !== currentDocumentSubservice._id) {
                    const documentGroup = this.subservice.documentGroups.find(
                        item => item.guid === availableDocument.groupGuid,
                    );
                    if (documentGroup) {
                        this.availableOtherDocuments.push({
                            guid: availableDocument.guid,
                            name: availableDocument.name,
                            group: documentGroup.name,
                            subservice: this.subservice.titles.shortTitle,
                        });
                    }
                }
            } else {
                const documentForAdd = {
                    guid: availableDocument.guid,
                    name: availableDocument.name,
                    group: availableDocument.groupName,
                    subservice: null,
                };
                this.availableOtherDocuments.push(documentForAdd);
            }
        });
    }

    /**
     * КНМ возможности создания линка на результат предоставления другой услуги в комплексе услуг
     * (случай, когда в услуге присутствуют значения startAfterGuids)
     *
     */
    private _checkEnableOtherSubserviceResultLink() {
        this.startAfterSubservices = [];
        if (!this.document.subserviceGuid) {
            return;
        }

        if (
            this.appeal.subservice.guid !== this.document.subserviceGuid ||
            !this.appeal.subservice.startAfterGuids ||
            !this.appeal.subservice.startAfterGuids.length
        ) {
            return;
        }

        this.appeal.subservice.startAfterGuids.forEach(subserviceGuid => {
            if (this.appeal.subservice.guid === subserviceGuid) {
                this.startAfterSubservices.push({
                    guid: this.appeal.subservice.guid,
                    title: this.appeal.subservice.shortTitle,
                });
            }
        });
    }

    /**
     * Включение / выключение ссылки на услугу с результатом
     */
    public changeResultSubserviceLink() {
        // Отключение связки с услугой
        if (this.data.resultSubserviceLink) {
            delete this.data.resultSubserviceLink;
        } else {
            if (this.startAfterSubservices.length === 1) {
                this.data.resultSubserviceLink = this.startAfterSubservices[0];
            } else {
                this.isProcessSelectResultSubserviceLink = true;
            }
        }
        this._initEditTabs();
    }

    /**
     * Выбор услуги, на результат выполнения которой будет ссылаться обрабатываемый документ
     * @param subservice
     */
    public selectResultSubserviceLink(subservice) {
        this.data.resultSubserviceLink = subservice;
        this.isProcessSelectResultSubserviceLink = false;
        this._initEditTabs();
    }

    /**
     * Удаление связи с другим документом
     */
    public deleteDocumentLink() {
        delete this.data.link;
    }

    /**
     * Выбор документа дела, на который ссылается обрабатываемый документ
     * @param document
     */
    public selectDocumentLink(document) {
        this.data.link = document.guid;
        this.isProcessSelectAvailableDocument = false;
        this._initEditTabs();
        this.validateService.validateAppeal(this.validateService.processValidate);
    }

    /**
     * Инициализация режима редактирования документа
     */
    public async edit() {
        this.appealSaveService.forbidRedirect = true;
        await this._removeUncheckedDocumentIcon();
        this.onEdit.emit({ document: this.document, group: this.group });
        this.appealService.editing = true;
    }

    private async _removeUncheckedDocumentIcon() {
        this.document.feedbackDocumentUnchecked = false;
        const appealSaveServiceDocument = this.appealSaveService.appeal.documents.find(
            doc => doc.guid === this.document.guid,
        );
        appealSaveServiceDocument.feedbackDocumentUnchecked = false;

        if (this.appeal._id) {
            this.appealSaveService.appeal = cloneDeep(this.appeal);
            await this.appealSaveService.saveAppeal();
        }
    }

    /**
     * Применение изменений
     * @param continueProcessingAppeal - продолжить сохранение (или перевод на другой статус) дела после применения изменений
     */
    public async apply(continueProcessingAppeal = false) {
        this.continueProcessingAppeal = continueProcessingAppeal;

        this.appealSaveService.appeal = cloneDeep(this.appeal); // fix CIT-152 bug
        this.appeal = await this.appealSaveService.saveAppeal();
        // если это документ-запрос и есть Форма доп. полей для запроса
        if (this.document.requestId && this.data.envelope.request && this.data.envelope.request.xsd) {
            this.documentCommonDataComponent.saveEnvelope();
        } else if (this.activeTab === 'properties') {
            this.appealDocumentPropertiesComponent.save(null, true);
        } else {
            this.onApply.emit({
                document: this.document,
                continueProcessingAppeal: continueProcessingAppeal,
                forceClose: true,
            });
        }

        this.appealService.editing = false;
    }

    /**
     * Сохранение формы дополнительных данных на странице реквизитов (если они есть)
     * @param data
     */
    public afterSaveDocumentProperties(data) {
        // Переход на другую вкладку
        if (data.tab) {
            this.activeTab = data.tab;
        }
        // Сохранение изменений
        if (data.apply) {
            this.onApply.emit({ document: this.document, continueProcessingAppeal: this.continueProcessingAppeal });
        }
    }

    /**
     * Сохранение XSD-данных запроса в документ и приме
     */
    public afterGetEnvelopeXsdData() {
        this.onApply.emit({ document: this.document, continueProcessingAppeal: this.continueProcessingAppeal });
    }

    /**
     * Отмена изменений
     */
    public cancel() {
        this.onApply.emit(null);
        this.appealService.editing = false;
    }

    /**
     * Активация вкладки при клике на закладку
     * @param tab - код выбранной вкладки
     */
    public activateTab(tab) {
        if (this.activeTab === 'properties') {
            // Если текущая вкладка "Доп. инфо", то перед переходом на новую сохраняем значения
            this.appealDocumentPropertiesComponent.save(tab);

            return;
        }

        this.activeTab = tab;
        if (this.mode === VIEW_MODE) {
            this.onEdit.emit({ document: this.document, group: this.group, tab: tab });
        }
    }

    /**
     * Инициализация удаления документа
     */
    public delete() {
        this.deleteModal.showModal();
    }

    /**
     * Обработка нажатия кнопок в модальном окне
     * @param type
     */
    public sendDeleteRequest(type) {
        if (type === 'yes') {
            this.appealSaveService.forbidRedirect = true;
            this.onDelete.emit(this.document);
        }
    }

    /**
     * Формирование печатной формы
     */
    public print(printForm = null) {
        if (printForm) {
            this.document.printForm = _.cloneDeep(printForm);
            this.data.printForm = _.cloneDeep(printForm);
        } else {
            this.document.printForm = this.data.printForms[0];
            this.data.printForm = _.cloneDeep(this.data.printForms[0]);
        }
        if (this.mode === EDIT_MODE) {
            this.data.isProcessGeneratePrintForm = true;
            // Применяем данные документа с флагом последующего формирования печатной формы
            this.onApply.emit({
                document: this.document,
                continueProcessingAppeal: this.continueProcessingAppeal,
                applyForPrint: true,
            });
        } else {
            // Передаем управление в родительский элемент для формирования печатной формы
            this.onPrint.emit(this.document);
        }
    }

    /**
     * Отправка запроса (с предварительным сохранением дела)
     */
    public async send() {
        this.errorLoggingService.clearRequestsLog();
        this.isProcessSendingEnvelope = true;
        this.document.envelope = this.data.envelope;
        this.appealSaveService.appeal = cloneDeep(this.appeal);

        // Применение изменений в текущем документе
        const documentIndex = this.appeal.documents.findIndex(item => item.guid === this.document.guid);
        const documentAppealData = this.documentService.generateDocumentDataToAppeal(this.document.guid);
        if (documentIndex !== -1) {
            this.appeal.documents[documentIndex] = documentAppealData; // Обновление данных документа
        } else {
            this.appeal.documents.push(documentAppealData); // Добавление документа в дело
        }
        // Сохранение данных дела на сервере
        try {
            this.appealSaveService.appeal = this.appeal;

            this.appealSaveService.case = this.errorLoggingService.SED;
            this.appeal = await this.appealSaveService.saveAppeal();
            // Обновление текущего документа
            const idx = this.appeal.documents.findIndex(item => item.guid === this.document.guid);
            this.document = _.cloneDeep(this.appeal.documents[idx]);
            this.documentService.tempData = _.cloneDeep(this.documentService.data);

            // Вызов метода отправки запроса из соответствующего компонента для документа
            await this.documentCommonDataComponent.processSendEnvelope();
        } catch (error) {
            this.toaster.error(error);
            await this.errorLoggingService.log(this.errorLoggingService.SED, error);
        }
    }

    /**
     * Отмена статуса процесса отправки конверта после завершения отправки
     */
    public afterSendEnvelope() {
        if (
            (this.data.envelope.status === 'sended' || this.data.envelope.status === 'closed') &&
            this.data.isForwardingRequest
        ) {
            this.updateAppealAfterSendStatusEnvelope.emit(true);
        } else {
            this.isProcessSendingEnvelope = false;
        }
    }

    /**
     * Применение изменения наименования произвольного документа в группе "Прочие"
     */
    public applyDocumentName(value) {
        if (value) {
            this.data.name = value;
        }
        this.isProcessEditDocumentName = false;
        this.isProcessSimplifyAddDocumentToComplex = false;
        this.isProcessSelectDocumentKind = false;
        if (!this.data.docCode) {
            return;
        }

        const find = this.documentsForComplex.find(item => item.code === this.data.docCode);
        if (find && find.name === this.data.name) {
            return;
        }

        this.data.docCode = null;
        this.selectedDocumentFromList = null;
    }

    /**
     * Обновление информации о запросе
     */
    public updateEnvelopeData() {
        this.appealSaveService.updateEnvelopeData(this.document);
    }

    public typedDocumentName(value) {
        this.data.name = value;
    }

    public async fileChange(event) {
        const filesList = event.target.files;
        const validFormat = this._isValidFileFormat(filesList[0]);

        if (filesList.length && validFormat) {
            this.data.queue.push(filesList[0]);

            if (
                this.documentService.data[this.data.guid] &&
                this.documentService.data[this.data.guid].queue &&
                this.documentService.data[this.data.guid].queue.length !== this.data.queue.length
            ) {
                // #CIT-1328

                this.documentService.data[this.data.guid].queue.push(filesList[0]);
            }

            await this.appealSaveService.saveAppeal();
        }
    }

    public async afterDeleteFile() {
        await this.appealSaveService.saveAppeal();
    }

    public cloneFiles(): void {
        const usedDocumentsGroupGuids = this.appeal.documents
            .filter(item => item.groupGuid)
            .map(item => item.groupGuid);
        const subserviceGuid = this.document.subserviceGuid || this.appeal.subservice.guid;

        this.allDocumentsData = this.documentService.data;

        // фильтрация групп документов по наличию документов
        let groups = this.documentService.appealSubservicesData[subserviceGuid].appealDocumentGroups.filter(
            item => item.guid !== this.document.groupGuid && usedDocumentsGroupGuids.indexOf(item.guid) !== -1,
        );

        if (groups.length === 0) {
            this.toaster.info('В деле отсутствуют группы документов в документами');
        }

        groups = _.cloneDeep(groups);
        groups = groups.map(item => ({
            ...item,
            documents: this.appeal.documents.filter(elm => elm.groupGuid === item.guid),
        }));
        this.cloneFilesData = {
            files: this.document.files,
            groups: groups,
            selectedGroup: null,
            selectedDocument: null,
            selectedFiles: [],
        };
        this.modalRef = this.modalService.show(this.cloneFilesModal, { backdrop: 'static', class: 'modal-lg' });
    }

    public async processCloneFiles() {
        const documentToProcess = this.appeal.documents.find(
            item => item.guid === this.cloneFilesData.selectedDocument.guid,
        );
        const queueToProcess = this.allDocumentsData[this.cloneFilesData.selectedDocument.guid];
        if (!documentToProcess.files) {
            documentToProcess.files = [];
        }

        this.cloneFilesData.selectedFiles.forEach(file => {
            documentToProcess.files.push(file);
            if (queueToProcess.queue.length !== documentToProcess.files.length) {
                queueToProcess.queue.push(file);
            }
        });

        this.appealSaveService.appeal.documents = cloneDeep(this.appeal.documents); // fix CIT-48 bug

        await this.appealSaveService.saveAppeal();
        this.modalRef.hide();
        this.toaster.success('Файлы успешно скопированы');
    }

    public checkFileSelectForClone(file) {
        return this.cloneFilesData.selectedFiles.filter(item => item._id === file._id).length > 0;
    }

    public toggleFileSelectForClone(file) {
        const findIndex = this.cloneFilesData.selectedFiles.findIndex(item => item._id === file._id);
        if (findIndex === -1) {
            this.cloneFilesData.selectedFiles.push(file);

            return;
        }

        this.cloneFilesData.selectedFiles.splice(findIndex, 1);
    }

    private _isValidFileFormat(file: any): boolean {
        // Если указан допустимый формат файла
        const availableFormat = [];
        this.group.docs.forEach(doc => {
            if (doc.extensionTypes) {
                availableFormat.push(doc.extensionTypes.split(','));
            }
        });

        if (!this.group.scanFormat && !availableFormat.length) {
            return true;
        }

        let fileExt;
        let formatText = this.group.scanFormat;
        const nameArr = file.name.split('.');
        if (availableFormat.length) {
            formatText = !this.group.scanFormat
                ? availableFormat.join(',')
                : (formatText += ',' + availableFormat.join(','));
        }
        if (nameArr.length > 1) {
            fileExt = nameArr[nameArr.length - 1].toLowerCase();
            if (!formatText.includes(fileExt)) {
                this.toaster.error(
                    `Недопустимый формат файла: ${fileExt}. Допустим для выбора файл в формате: ${formatText}`,
                );

                return false;
            }
        }

        return true;
    }

    public async getEnvelopTemplates() {
        this.templates = await this.templatesService.fetchTemplates();
        this.modalRef = this.modalService.show(this.chooseTemplatesModal, { backdrop: 'static', class: 'modal-lg' });
    }

    public async processSaveTemplate() {
        this.modalRef = this.modalService.show(this.saveTemplateModal, { backdrop: 'static', class: 'modal-lg' });
    }

    public async saveTemplate() {
        this.modalRef.hide();
        const saveTemplateProccess = await this.documentCommonDataComponent.saveEnvelopeTemplate();
        if (saveTemplateProccess.errors && saveTemplateProccess.errors.length > 0) {
            this.toaster.error('На форме данных запроса содержатся ошибки. Шаблон не был сохранен');
            return;
        }
        this.toaster.success('Шаблон запроса был успешно сохранен');
        const newTemplate = await this.templatesService.createNewTemplate(
            this.selectedTemplate.templateName,
            this.documentService.data[this.document.guid].envelope.xml,
        );
    }

    public async fillEnvelopeDataFromTemplate() {
        this.modalRef.hide();
        const template = await this.templatesService.fetchTemplateXml(this.selectedTemplate.guid);
        console.log('fillEnvelopeDataFromTemplate ...');
        if (template && template.length > 0) {
            this.selectedTemplate = template;
            this.isProcessSelectDocumentKind = true;
            setTimeout(() => {
                this.selectDocumentKind(this.selectedDocumentKind);
                this.toaster.success('Данные из шаблона успешно загружены на форму данных');
            });
        }
    }

    public async deleteTemplate(template) {
        this.selectedTemplate = template;
        this.deleteTemplateModal.showModal();
    }

    public async processDeleteTemplate(event) {
        this.deleteTemplateModal.hideModal();
        if (event === 'yes') {
            const findIndex = this.templates.findIndex(item => item.guid === this.selectedTemplate.guid);
            this.templates.splice(findIndex, 1);
            await this.templatesService.deleteTemplate(this.selectedTemplate);
            this.selectedTemplate = null;
        }
    }

}
