import { Injectable } from '@angular/core';
import { AccessService, HttpService, StorageService } from '@evolenta/core';
import { Config } from '../../../common/services/config';
import { AppealData } from './appeal.data';
import { Permission } from '../../../common/services/permission';
import * as moment from 'moment';

@Injectable()
export class AppealStatusService {
    public appeal; // Формируемое дело
    public subservice; // Услуга, по которым формируется дело
    public complexSubservice; // Комплексная услуга

    public activeAction; // Активное действие для дела

    public statuses = AppealData.statuses; // возможные статусы

    public defaultStatuses = this.storage.getItem('defaultStatuses'); // Набор статусов по умолчанию для дела
    public currentOrganization = this.storage.getItem('currentOrganization');

    // Настройки действий, которые требуют предварительной обработки (выбора значения из списка, заполнения данных и т.д.)
    public actionsWithAdditionalOperations = {
        rejectedReceiptDocs: {
            code: 'selectReasonOfReject',
            reference: 'rejectReasons',
            type: 'rejectReceiptDocs',
            title: 'Выбор причины отказа в приеме документов',
        },
        selectReasonOfReject: {
            code: 'selectReasonOfReject',
            reference: 'rejectReasons',
            type: 'rejectService',
            title: 'Выбор причины отказа в предоставлении услуги',
            field: 'rejectReason',
        },
        annulled: {
            code: 'selectReasonOfAnnul',
            reference: 'annulReasons',
            title: 'Выбор причины аннулирования',
        },
        consultation: {
            fields: [
                { code: 'consultationReason', name: 'Предмет консультации', type: 'input' },
                { code: 'consultationResult', name: 'Результат консультирования', type: 'textarea' },
            ],
            title: 'Параметры консультации',
        },
        selectIssuedOther: {
            fields: [{ code: 'selectIssuedOther', type: 'input', onlyValueReturn: true }],
            title: 'Особый вариант выдачи',
            field: 'issuedOther',
        },
        beforeIssued: {
            code: 'selectResultType',
            reference: 'resultTypes',
            title: 'Результат оказания услуги',
        },
        issued: {
            code: 'selectIssueType',
            reference: 'issueTypes',
            title: 'Выбор варианта выдачи результата дела',
        },
        unclaimed: {
            code: 'selectUnclaimedType',
            reference: 'unclaimedTypes',
            title: 'Действие при невостребованности',
        },
    };

    public permissions = Permission; // Описание всех полномочий системы

    // Соответствие кодов действий с полномочиями системы
    public actionsPermissions = {
        process: this.permissions.Appeal_Allow_Action_Process,
        rejectedReceiptDocs: this.permissions.Appeal_Allow_Action_RejectedReceiptDocs,
        consultation: this.permissions.Appeal_Allow_Action_Consultation,
        annulled: this.permissions.Appeal_Allow_Action_Annuled,
        beforeIssued: this.permissions.Appeal_Allow_Action_BeforeIssued,
        issued: this.permissions.Appeal_Allow_Action_Issued,
        archive: this.permissions.Appeal_Allow_Action_Archive,
        forwarded: this.permissions.Appeal_Allow_Action_Forwarded,
        returned: this.permissions.Appeal_Allow_Action_Returned,
        forwardedForIssue: this.permissions.Appeal_Allow_Action_ForwardedForIssue,
        unclaimed: this.permissions.Appeal_Allow_Action_Unclaimed,
    };

    // Статусы являющиеся конечными для дела / услуги
    public finishStatuses = [
        'consultation',
        'rejectedReceiptDocs',
        'issued',
        'annulled',
        'archive',
        'beforeIssued',
        'forwardedForIssue',
        'forwarded',
        'unclaimed',
        'serviceReject',
        'receiveDocsReject',
        'complete',
    ];

    // Статусы выдачи дела (услуги)
    public issuedStatuses = ['issued', 'forwardedForIssue'];

    public allowEdit = true; // Признак доступности редактирования дела

    public currentExecuteAction = null; // Действие статуса, которое выполняется в данный момент (для межкомпонентного определения процесса перехода на новый статус в деле (услуге))

    public constructor(
        private storage: StorageService,
        private httpService: HttpService,
        private accessService: AccessService,
    ) {}

    /**
     * Очистка данных сервиса
     */
    public clearData() {
        this.appeal = null;
        this.subservice = null;
        this.complexSubservice = null;
    }

    /**
     * Инициализация данных сервиса
     * @param appeal - формируемое дело
     * @param subservice - услуга, на основе которых формируется дело
     * @param complexSubservice - комплексная услуга, на основе которой формируется дело
     */
    public initData(appeal, subservice, complexSubservice = null) {
        this.activeAction = null;
        this.allowEdit = true;
        this.appeal = appeal;
        this.subservice = subservice;
        this.complexSubservice = complexSubservice;
    }

    /**
     * Инициализация массива действий по делу
     */
    public initAppealActions() {
        this.activeAction = null;

        if (!this.appeal._id || !this.accessService.hasAccess([this.permissions.Appeal_Execute_Action])) {
            this.allowEdit = true;

            return [];
        }

        if (
            this.finishStatuses.indexOf(this.appeal.status.code) !== -1 &&
            !this.accessService.existPermission(Permission.No_Edit_Limits)
        ) {
            this.allowEdit = false;
        }

        const denyActions = ['beforeIssued', 'forwarded', 'returned', 'forwardedForIssue'];
        this.defaultStatuses = this.storage.getItem('defaultStatuses'); // Набор статусов по умолчанию для дела
        const currentStatus = this.defaultStatuses.find(item => item.code === this.appeal.status.code);
        const actions = currentStatus.actions.filter(
            item =>
                denyActions.indexOf(item.code) === -1 &&
                this.accessService.hasAccess([this.actionsPermissions[item.code]]),
        );
        // КНМ возможности архивирования дела
        if (actions.length > 0) {
            this.activeAction = actions[0];
        }

        return actions;
    }

    /**
     * Инициализация массива действий для услуги дела
     * @param appealSubservice
     * @returns {Array}
     */
    public initAppealSubserviceActions(appealSubservice) {
        let actions = [];
        // Набор кодов статусов дела, при которых у дочерних услуг кнопки действий отображаться не будут
        const blockAppealStatuses = [
            'draft',
            'consultation',
            'rejectedReceiptDocs',
            'issued',
            'annulled',
            'archive',
            'unclaimed',
        ];
        // Если дело еще не зарегистрировано, действия услуг не отображаются

        if (
            blockAppealStatuses.indexOf(this.appeal.status.code) !== -1 ||
            !this.accessService.hasAccess([this.permissions.Appeal_Execute_Action])
        ) {
            return actions;
        }

        const statuses = this.subservice.statusModel.status;
        let currentStatus;
        // Если услуга находится в подстатусе
        if (appealSubservice.status.isSubstatus) {
            const mainStatus = statuses.find(item => item.code === appealSubservice.status.mainStatusCode);
            // Действия основного статуса без того, который инициализирует переход в подстатусы
            const mainStatusActions = mainStatus.actions.filter(item => !item.isStartSubstatus);
            currentStatus = mainStatus.subStatuses.find(item => item.guid === appealSubservice.status.guid);
            actions = currentStatus.actions.concat(mainStatusActions);
            actions = this.filterActionsBySpecialParamsAndPermissions(appealSubservice, actions);
        } else {
            currentStatus = statuses.find(item => item.code === appealSubservice.status.code);
            actions = this.filterActionsBySpecialParamsAndPermissions(appealSubservice, currentStatus.actions);
        }

        return actions;
    }

    /**
     * Фильтрация действий по специальным параметрам и полномочиям (вариант выдачи результата услуги, действие "Архивирование"
     * @param appealSubservice - услуга дела
     * @param actions - массив действий
     */
    public filterActionsBySpecialParamsAndPermissions(appealSubservice, actions) {
        const result = [];

        if (actions && actions.length > 0) {
            actions.forEach(action => {
                let allowAction = true;
                // Фильтр по полномочиям
                if (
                    (!this.actionsPermissions[action.code] &&
                        !this.accessService.hasAccess([this.permissions.Appeal_Allow_Action_Substatus])) ||
                    (this.actionsPermissions[action.code] &&
                        !this.accessService.hasAccess([this.actionsPermissions[action.code]]))
                ) {
                    allowAction = false;
                }
                // Действие архивирования возможно только для дела
                if (action.code === 'archive') {
                    allowAction = false;
                }
                // Если выдача результата оказания услуги в МФЦ и дело не в статусе "Направлено на обработку в ОГВ", то убираем action "Передача на обработку и выдачу в ОГВ"
                if (
                    appealSubservice.status.code !== 'forwarded' &&
                    appealSubservice.issueResultForm &&
                    appealSubservice.issueResultForm === 'currentUnit' &&
                    action.code === 'forwardedForIssue'
                ) {
                    allowAction = false;
                }
                // Если выдача результата оказания услуги в ОГВ, то убираем action "Передача на обработку в ОГВ" и "Возврат из ОГВ" и "Завершить рассмотрение и передать на выдачу"
                if (
                    appealSubservice.issueResultForm &&
                    appealSubservice.issueResultForm === 'otherUnit' &&
                    (action.code === 'forwarded' || action.code === 'returned' || action.code === 'beforeIssued')
                ) {
                    allowAction = false;
                }
                const find = result.find(item => item.guid === action.guid);
                if (allowAction && !find) {
                    result.push(action);
                }
            });
        }

        return result;
    }

    /**
     * Получение параметра статуса
     * @param status - объект статуса
     * @param property - возвращаемое свойство
     * @param rejectedProperty - свойство для отказных дел
     * @returns {any}
     */
    public getStatusProperty(status, property, rejectedProperty = null) {
        if (status) {
            const statusCode = status.code ? status.code : status.mainStatusCode;
            const statusInfo = this.statuses.find(item => item.code === statusCode);
            if (statusInfo) {
                return rejectedProperty && statusInfo[rejectedProperty]
                    ? statusInfo[rejectedProperty]
                    : statusInfo[property];
            }
        }

        return '';
    }

    /**
     * Инициализация статуса дела
     * @param status - статус
     * @param subStatus - подстатус
     * @returns {any}
     */
    public initStatusData(status, subStatus = null) {
        const result: any = {
            guid: status.guid,
            code: status.code,
            name: status.name,
            description: status.description,
            dateStart: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        };

        if (subStatus) {
            result.subStatusGuid = subStatus.guid;
            result.subStatusName = subStatus.name;
        } else if (status.subStatuses && status.subStatuses.length > 0) {
            result.subStatusGuid = null;
            result.subStatusName = null;
            // Проверяем на наличие подстатуса с автостартом
            const autoStart = status.subStatuses.find(item => item.isAutoStart);
            if (autoStart) {
                result.subStatusGuid = autoStart.guid;
                result.subStatusName = autoStart.name;
            }
        }

        return result;
    }

    /**
     * Формирование уникального массива возможных вариантов (причин) для доп.действий смены статуса (отказ, аннулирование)
     * @param elements - список доступных для выбора элементов в поле references в описательных услугах
     * @param type - тип данных
     * @param processingSubservice - услуга дела (если переход на новый статус осуществляется для одной услуги, а не глобально для дела
     * @returns {Array}
     */
    public prepareReasonsForAction(elements, type, processingSubservice) {
        if (
            !(
                !processingSubservice ||
                (processingSubservice && this.appeal.subservice.guid === processingSubservice.guid)
            )
        ) {
            return [];
        }

        if (
            !(
                this.subservice._id === this.appeal.subservice.id &&
                this.subservice.references &&
                this.subservice.references[elements]
            )
        ) {
            return [];
        }

        const items = [];
        this.subservice.references[elements].forEach(reference => {
            if (!type || (type && reference.type === type)) {
                const referenceIsNew = !items.some(item => item.code === reference.code);
                if (referenceIsNew) {
                    items.push(reference);
                }
            }
        });

        return items;
    }

    /**
     * Выполнение действия (переход на другой статус)
     * @param action - выполняемое действие
     * @param properties - объект содержащий настроечные параметры для действия (выбранные причины отказа, аннулирования и др.)
     * @param appealSubservice - услуга дела (в случае, если осуществляется выполнение действия в услуге, а не глобальное в деле)
     * @param ticket - данные талона ЭО
     */
    public executeAction(action, properties, appealSubservice = null, ticket = null) {
        const params: any = {
            mainId: this.appeal._id,
            collectionName: 'appeals',
            unitId: this.currentOrganization['_id'],
            action: {
                nextStatusGuid: action ? action.nextStatusGuid : null,
                guidSubserviceInAppeal: appealSubservice ? appealSubservice.guid : null,
                additionActions: properties,
            },
        };
        if (this.appeal.status.code === 'draft' && !appealSubservice && this.appeal.simplifyMode) {
            params.simplifiedExecution = true;
        }
        if (ticket) {
            params.ticket = ticket;
        }
        action.additionActions.forEach(item => {
            if (item !== 'notValidate') {
                if (
                    ~item.indexOf('setDateField') ||
                    ~item.indexOf('moveToArchive') ||
                    ~item.indexOf('calculateIssueTerm')
                ) {
                    params.action.additionActions[item] = null;
                } else if (~item.indexOf('calculateTerm')) {
                    params.action.additionActions[item] = {};
                } else if (~item.indexOf('assignNumber')) {
                    const arr = item.split('#');
                    params.action.additionActions[arr[0]] = this.assignNumber(item);
                } else {
                    if (!properties[item]) {
                        params.action.additionActions[item] = this[item]();
                    }
                }
            }
        });

        return this.httpService.post(Config.server + Config.app + Config.api + Config.mfc + 'executeAction', params);
    }

    /**
     * Присвоедние номера делу / консультации
     * @param item - additionalAction в action
     * @returns {any}
     */
    private assignNumber(item) {
        const params = {
            numbersMeta: {},
        };
        if (~item.indexOf('#')) {
            // Генерация номера отличного от номера дела
            const arr = item.split('#');
            const numberField = arr[1];
            params.numbersMeta[numberField] = {
                algorithm: 'basic',
                length: 8,
                tag: 'consultationNumber',
            };
        } else {
            // Генерация номера дела
            params.numbersMeta = {
                number: {
                    algorithm: 'RRYYOOOOONNNNNNNN',
                },
                shortNumber: {
                    algorithm: 'basic',
                    length: 8,
                },
            };
        }

        return params;
    }

    /**
     * Формирование объекта для блока additionalActions.calculateTerm при смене статуса
     */
    private calculateTerm() {
        const params = {
            unitId: this.currentOrganization._id,
            fieldName: 'datePlaneFinish',
            term: null,
        };
        let condition = null; // Условие при котором действуют настройки сроков
        // КНМ условий в зависимости от выбранного варианта
        this.subservice.terms.condition.forEach(item => {
            if (item.variantGuid && ~item.variantGuid.indexOf(this.appeal.subservice.variant.guid)) {
                condition = item;
            }
        });

        // Если условия для варианта не заданы, берем первое из списка
        if (!condition) {
            condition = this.subservice.terms.condition[0];
        }

        params.term = this.subservice.terms.term.find(item => item.guid === condition.termGuid);

        return params;
    }

    /**
     *   CIT-1302 После перевода дела в статус "Завершено" должна появляться кнопка "В архив".
     *   частично скопировано с refreshAppealStatusData
     */

    public refreshActions() {
        let mainAction = null;
        let actions;
        let substatus;

        const statuses = this.storage.getItem('defaultStatuses');
        const currentStatus = statuses.find(item => item.code === this.appeal.status.code);

        if (currentStatus && currentStatus.isSubstatus) {
            // Объект подстатуса дела
            substatus = currentStatus.subStatuses.find(item => item.guid === currentStatus.guid);
            actions = substatus.actions;
            mainAction = actions.find(item => item.isMain);

            if (!mainAction && actions.length > 0) {
                mainAction = actions[0];
            }

            if (substatus.canFinish) {
                actions = actions.concat(currentStatus.actions.filter(item => !item.isStartSubstatus));
            }
        } else {
            actions = currentStatus.actions;
        }

        if (!mainAction) {
            mainAction = actions.find(item => item.isMain);
        }

        if (!mainAction && actions.length > 0) {
            mainAction = actions[0];
        }

        if (mainAction) {
            return { mainAction: mainAction, actions: actions };
        }
    }
}
