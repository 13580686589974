<div class="panel panel-shadow m-20" *ngIf="taskData" [ngClass]="{ 'no-border': mode == 'edit' }">
    <div
        class="panel-heading"
        [ngClass]="{
            'bg-success-50': !isExpired(),
            'bg-danger-50': isExpired()
        }"
    >
        <h4 class="panel-title ellipsis" style="width: 60%">
            <div class="mb-5 line-height-10">
                <!-- Статус задачи -->
                <label class="badge text-uppercase mr-10 no-margin-bottom bg-success-600">
                    {{
                        task.status === 'ACTIVE'
                            ? localizations['appeals.tasks'].active_user
                            : localizations['appeals.tasks'].closed_user
                    }}
                </label>
                <label class="badge text-uppercase mr-10 no-margin-bottom bg-danger-600 ml-10" *ngIf="isExpired()">
                    {{ localizations['appeals.tasks'].expired }}
                </label>
            </div>
            <!-- Наименование задачи -->
            {{ task.name }}
        </h4>

        <!-- Управляющие кнопки -->
        <div class="heading-elements" *ngIf="!editedProperty">
            <button
                class="btn btn-success-overline heading-btn btn-labeled btn-labeled-right btn-xs no-margin-top"
                style="padding: 19px 50px 18px 14px"
                *ngIf="task.status === 'ACTIVE' && taskData && taskData.allowApply"
                [disabled]="isProcessCompleteTask || isDisabledByRso || isDisabledByEmptyLicense"
                (click)="checkForTaskExpiring()"
            >
                <b style="margin-top: 9px">
                    <i
                        [ngClass]="{
                            'icon-spinner2 spinner': isProcessCompleteTask,
                            'icon-checkmark3': !isProcessCompleteTask
                        }"
                    >
                    </i>
                </b>
                {{ localizations.common.complete }}
            </button>

            <button
                class="btn btn-primary-overline heading-btn btn-labeled btn-labeled-right btn-xs no-margin-top hide"
                style="padding: 14px 14px 14px 14px"
                [title]="task.status === 'ACTIVE' ? localizations.common.edit : localizations.common.view"
                [disabled]="isProcessCompleteTask"
                *ngIf="mode == 'view' && !checkTaskNotHaveParams()"
                (click)="edit()"
            >
                {{ task.status === 'ACTIVE' ? localizations.common.execution : localizations.common.viewing }}
            </button>

            <ng-container *ngFor="let event of events">
                <button
                    class="btn heading-btn btn-primary-overline btn-labeled"
                    (click)="startProcessEvent(event)"
                    *ngIf="mode == 'edit' && task.status === 'ACTIVE'"
                >
                    {{ event.name }}
                </button>
            </ng-container>

            <button
                class="btn heading-btn btn-success-overline btn-labeled hide"
                (click)="apply()"
                *ngIf="mode == 'edit' && task.status === 'ACTIVE'"
            >
                {{ localizations.common.apply }}
            </button>

            <button
                class="btn heading-btn btn-danger-overline btn-labeled hide"
                (click)="cancel()"
                *ngIf="mode == 'edit' && task.status !== 'ACTIVE'"
            >
                {{ task.status === 'ACTIVE' ? localizations.common.cancel : localizations.common.close }}
            </button>
        </div>
    </div>

    <div class="panel-body no-padding">
        <!-- Режим редактирования -->
        <div *ngIf="mode == 'edit'">
            <!-- Сведения -->
            <fieldset
                *ngIf="
                    taskData &&
                    taskData.params &&
                    taskData.params.entities &&
                    taskData.params.entities.length > 0 &&
                    (!editedProperty || editedProperty == 'entities')
                "
            >
                <legend
                    class="text-bold pl-20 no-margin-bottom cursor-pointer text-slate pt-10"
                    [ngClass]="{ shadowed: tabs.entities }"
                    (click)="tabs.entities = !tabs.entities"
                    *ngIf="!editedProperty"
                >
                    <i class="icon-grid3 mr-5"></i>
                    {{ localizations.common.info }}
                    <a class="control-arrow text-slate mr-20">
                        <i
                            [ngClass]="{
                                'icon-circle-down2': tabs.entities,
                                'icon-circle-left2': !tabs.entities
                            }"
                        >
                        </i>
                    </a>
                </legend>
                <div class="collapse" [ngClass]="{ in: tabs.entities }">
                    <appeal-entities
                        [appeal]="appealService.appeal"
                        [subservice]="appealService.subservice"
                        [task]="taskData"
                        [allowEdit]="allowEdit"
                        (onStartEdit)="startEditProperty('entities')"
                        (afterEndEdit)="endEditProperty()"
                    >
                    </appeal-entities>
                </div>
            </fieldset>

            <!-- Документы -->
            <fieldset
                *ngIf="
                    taskData &&
                    taskData.params &&
                    taskData.params.documentGroups &&
                    taskData.params.documentGroups.length > 0 &&
                    (!editedProperty || editedProperty == 'documentGroups')
                "
            >
                <legend
                    class="text-bold pl-20 cursor-pointer text-slate pt-10 no-margin-bottom"
                    [ngClass]="{ shadowed: tabs.documents }"
                    (click)="tabs.documents = !tabs.documents"
                    *ngIf="!editedProperty"
                >
                    <i class="icon-grid3 mr-5"></i>
                    {{ localizations.common.documents }}
                    <a class="control-arrow text-slate mr-20">
                        <i
                            [ngClass]="{
                                'icon-circle-down2': tabs.documents,
                                'icon-circle-left2': !tabs.documents
                            }"
                        >
                        </i>
                    </a>
                </legend>
                <div class="collapse" [ngClass]="{ in: tabs.documents }">
                    <documents
                        [appeal]="appealService.appeal"
                        [subservice]="appealService.subservice"
                        [task]="task"
                        [onlyGroups]="taskData.params.documentGroupsCodes"
                        [feedbackMessageGuid]="feedbackMessageGuid"
                    >
                    </documents>
                </div>
            </fieldset>

            <!-- Проверочные листы  -->
            <fieldset *ngIf="checkLinkCheckListEntity() && !editedProperty">
                <legend
                    class="text-bold pl-20 no-margin-bottom cursor-pointer text-slate pt-10"
                    [ngClass]="{ shadowed: tabs.checkLists }"
                    (click)="tabs.checkLists = !tabs.checkLists"
                >
                    <i class="icon-grid3 mr-5"></i>
                    {{ localizations['appeals.tasks'].checkup_sheets }}
                    <a class="control-arrow text-slate mr-20">
                        <i
                            [ngClass]="{
                                'icon-circle-down2': tabs.checkLists,
                                'icon-circle-left2': !tabs.checkLists
                            }"
                        >
                        </i>
                    </a>
                </legend>
                <div class="collapse" [ngClass]="{ in: tabs.checkLists }">
                    <appeal-requirements [appeal]="appeal" [task]="task"> </appeal-requirements>
                </div>
            </fieldset>

            <!-- Дополнительные поля -->
            <fieldset *ngIf="taskData?.params?.xsdTabs?.length > 0 && !editedProperty">
                <legend
                    class="text-bold pl-20 cursor-pointer text-slate no-margin-bottom pt-10"
                    [ngClass]="{ shadowed: tabs.xsdTabs }"
                    (click)="toggleAdditionalInfoOpened()"
                >
                    <i class="icon-grid3 mr-5"></i>
                    {{ localizations['appeals.tasks'].additional_info }}
                    <span
                        class="badge bg-danger-300 badge-xs ml-10"
                        *ngIf="isProcessValidate && !validateAdditionalData()"
                    >
                        {{ localizations.common.errors }}
                    </span>
                    <a class="control-arrow text-slate mr-20">
                        <i
                            [ngClass]="{
                                'icon-circle-down2': tabs.xsdTabs,
                                'icon-circle-left2': !tabs.xsdTabs
                            }"
                        >
                        </i>
                    </a>
                </legend>
                <div class="collapse" [ngClass]="{ in: tabs.xsdTabs }">
                    <ng-container *ngFor="let tab of taskData.params.xsdTabs; let fst = first">
                        <div *ngIf="tab.xsdField && tab.xsdField == 'xsdContent'">
                            <div class="panel-body no-padding-bottom" [ngClass]="{ 'no-padding-top': !fst }">
                                <div
                                    *ngIf="tab.tabCode == 'selectRegistryLicense' && appeal.subservice.mainElement"
                                    class="pb-20"
                                >
                                    <div class="panel panel-shadow panel-small no-margin-bottom">
                                        <div class="panel-body">
                                            <common-appeal-licenses
                                                [appeal]="appeal"
                                                [subservice]="subservice"
                                                [savedPlace]="getXsdDataSavedPlace()"
                                                [selectLicense]="true"
                                                [isNotCommon]="true"
                                            >
                                            </common-appeal-licenses>
                                        </div>
                                    </div>
                                </div>
                                <task-additional-data-tab
                                    [tab]="tab"
                                    [disabled]="
                                        (task.status !== 'ACTIVE' || !taskData.allowApply) &&
                                        !taskData.isApproveSignOptionsDisabled
                                    "
                                    [savedPlace]="getXsdDataSavedPlace()"
                                    [appeal]="appeal"
                                    [customParams]="getCustomParams()"
                                    #additionalDataTabComponent
                                >
                                </task-additional-data-tab>
                            </div>
                        </div>
                        <div class="p-20" *ngIf="!tab.xsdField">
                            <appeal-process-task-xsd-data
                                #xsdData
                                [params]="tab"
                                (onApply)="applyAdditionalData($event)"
                            >
                            </appeal-process-task-xsd-data>
                        </div>
                    </ng-container>
                </div>
            </fieldset>
        </div>
    </div>
    <div class="panel-footer no-padding">
        <div class="date-elements" *ngIf="!isEditPlanDate">
            <div class="p-20" *ngIf="task.created">
                <div>
                    <h5 class="date-text no-margin-top">
                        {{ localizations.common.creation_date }}
                    </h5>
                    <div class="date-value">
                        <i inlineSVG="assets/icon-clock.svg"></i>
                        {{ task.created | date : 'medium' }}
                    </div>
                </div>
            </div>

            <div class="p-20">
                <div>
                    <h5 class="date-text no-margin-top">
                        {{ localizations['appeals.tasks'].assignee }}
                    </h5>
                    <div class="date-value">
                        <i class="icon-user-tie"></i>
                        <span
                            [ngClass]="{ 'anchor text-info cursor-pointer': allowEdit }"
                            *ngIf="task.assignee"
                            (click)="changeTaskParam('assignee')"
                        >
                            {{ assigneeUser?.name }}
                        </span>
                        <span
                            class="text-muted"
                            [ngClass]="{ 'anchor cursor-pointer': allowEdit }"
                            *ngIf="!task.assignee"
                            (click)="changeTaskParam('assignee')"
                        >
                            {{ localizations['appeals.tasks'].not_assigned }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="p-20">
                <div>
                    <h5 class="date-text no-margin-top">
                        {{ localizations.tasks.start_date }}
                    </h5>
                    <div class="date-value">
                        <i inlineSVG="assets/icon-clock.svg"></i>
                        <span
                            *ngIf="task.followUp"
                            [ngClass]="{ 'anchor text-info cursor-pointer': allowStartTimeEdit }"
                            (click)="allowStartTimeEdit && changeTaskParam('followUp')"
                        >
                            {{ task.followUp | date : 'mediumDate' }}
                        </span>
                        <span
                            *ngIf="!task.followUp"
                            [ngClass]="{ 'anchor cursor-pointer': allowStartTimeEdit }"
                            (click)="allowStartTimeEdit && changeTaskParam('followUp')"
                        >
                            {{ localizations['appeals.tasks'].not_selected_fem }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="p-20">
                <div>
                    <h5 class="date-text no-margin-top">
                        {{ localizations['appeals.tasks'].planned_completion_date }}
                        <i
                            class="icon-pencil3 ml-5 cursor-pointer"
                            *ngIf="task.status === 'ACTIVE'"
                            [title]="localizations.common.change"
                            (click)="editPlanFinishDate()"
                        >
                        </i>
                    </h5>
                    <div class="date-value">
                        <i inlineSVG="assets/icon-clock.svg"></i>
                        <span *ngIf="task.due">
                            {{ task.due | date : 'mediumDate' }}
                        </span>
                        <span *ngIf="!task.due">
                            {{ localizations['appeals.tasks'].not_selected_fem }}
                        </span>
                        <span
                            class="anchor text-info text-size-small ml-5"
                            *ngIf="task.datePlaneFinishChangeHistory && task.datePlaneFinishChangeHistory.length > 0"
                            (click)="showDatePlanFinishChangeHistory()"
                        >
                            {{ localizations.common.history }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="p-20" *ngIf="task.dateCompleted">
                <div>
                    <h5 class="date-text">
                        {{ localizations.tasks.completion_date }}
                    </h5>
                    <div class="date-value">
                        <i inlineSVG="assets/icon-clock.svg"></i>
                        {{ task.dateCompleted | date : 'medium' }}
                    </div>
                </div>
            </div>
            <div
                class="p-20"
                *ngIf="
                    !task.assignee &&
                    task.candidateGroups &&
                    task.candidateGroups.length > 0 &&
                    roles &&
                    roles.length > 0
                "
            >
                <div>
                    <h5 class="date-text">
                        {{
                            task.candidateGroups.length > 1 ? localizations.common.groups : localizations.common.groups
                        }}
                        {{ localizations['appeals.tasks'].of_assignees }}
                    </h5>
                    <div class="date-value">
                        <i class="icon-users2"></i>
                        <ng-container *ngFor="let group of task.candidateGroups; let lst = last">
                            {{ getRoleName(group) }}
                            <ng-container *ngIf="!lst">, </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

        <!-- Редактирование даты завершения задачи с комментарием -->
        <div class="p-20" *ngIf="isEditPlanDate">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group form-group-lg">
                        <label>
                            {{ localizations.appeals.planned_completion_date }}
                            <span class="text-danger">*</span>
                        </label>
                        <evolenta-datepicker
                            [(ngModel)]="newDatePlaneFinish"
                            format="YYYY-MM-DD"
                            name="birthday"
                            [large]="true"
                            [valueAsString]="true"
                            (ngModelChange)="setNewAppealDatePlaneFinish()"
                        >
                        </evolenta-datepicker>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="form-group form-group-lg">
                        <label>
                            {{ localizations.common.comment }}
                            <span class="text-danger">*</span>
                        </label>
                        <input class="form-control" type="text" [(ngModel)]="commentTaskDatePlaneFinish" />
                    </div>
                </div>
            </div>

            <div class="pb-10" *ngIf="checkAllowChangeAppealDatePlaneFinish()">
                <div class="form-group">
                    <input
                        id="willChangeFullTerm"
                        class="checkbox"
                        type="checkbox"
                        name="willChangeFullTerm"
                        [(ngModel)]="willChangeFullTerm"
                        (ngModelChange)="setNewAppealDatePlaneFinish()"
                    />
                    <label class="control-label text-bold" for="willChangeFullTerm">
                        {{ localizations.tasks.also_change_appeal_date }}
                    </label>
                </div>
                <div *ngIf="willChangeFullTerm">
                    <div>
                        {{ localizations.tasks.current_date }}:
                        <span class="text-semibold">{{ appeal.datePlaneFinish | date : 'shortDate' }}</span>
                    </div>
                    <div>
                        {{ localizations.tasks.date_after_change }}:
                        <span class="text-semibold">
                            {{
                                (newAppealDatePlaneFinish === null ? appeal.datePlaneFinish : newAppealDatePlaneFinish)
                                    | date : 'shortDate'
                            }}
                        </span>
                    </div>
                </div>
            </div>

            <button class="btn btn-primary btn-xs pt-10 pb-10 mr-15" type="button" (click)="updatePlaneFinishDate()">
                {{ this.localizations.common.save }}
            </button>
            <button class="btn btn-xs btn-default pt-10 pb-10" type="button" (click)="cancelEditFinishDate()">
                {{ this.localizations.common.close }}
            </button>
        </div>
    </div>
</div>

<ng-container *ngIf="attachedTasks && attachedTasks.length">
    <div class="alert alert-info alert-styled-left alert-bordered no-margin-bottom m-20">
        <button class="close" type="button" data-dismiss="alert">
            <span>×</span>
        </button>
        <div class="text-semibold">{{ localizations.tasks.other_tasks_in_stage }}</div>
        <ul>
            <li *ngFor="let attachedTask of attachedTasks">{{ attachedTask.taskName }}</li>
        </ul>
    </div>
</ng-container>

<ng-template #setupTaskParamsModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ modalTitle }}
        </h4>
        <button class="close pull-right" type="button" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body no-padding-top">
        <ng-container *ngIf="processingParam === 'assignee'">
            <div class="checkbox-block" *ngFor="let user of usersForSelect">
                <input
                    id="user{{ user.login }}"
                    class="radio"
                    type="radio"
                    name="assigneeUser"
                    [checked]="currentAssignee && currentAssignee == user.login"
                    (change)="currentAssignee = user.login"
                />
                <label for="user{{ user.login }}">
                    {{ user.name }}
                    <span
                        class="badge bg-info ml-10 no-padding-top no-padding-bottom pl-5 pr-5"
                        [title]="localizations.tasks.number_of_active"
                    >
                        {{ user.tasksCount }}
                    </span>
                </label>
            </div>
        </ng-container>
        <ng-container *ngIf="processingParam !== 'startTime'">
            <evolenta-datepicker [large]="true" [(ngModel)]="currentDate" [valueAsString]="true"> </evolenta-datepicker>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button class="btn btn-default" type="button" (click)="applyTaskParam()">
            {{ localizations.common.apply }}
        </button>
        <button class="btn btn-default" type="button" (click)="modalRef.hide()">
            {{ localizations.common.close }}
        </button>
    </div>
</ng-template>

<!-- История изменений даты завершения -->
<ng-template #datePlanFinishHistoryModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations.appeals.planned_date_change_history }}
        </h4>
        <button class="close pull-right" type="button" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <ul class="list-feed">
            <li *ngFor="let item of task.datePlaneFinishChangeHistory">
                <div>
                    <span class="text-muted">
                        {{ item.date | date : 'shortTime' }}, {{ item.date | date : 'shortDate' }}
                    </span>
                    <span class="text-info ml-5">
                        {{ item.user?.name }}
                    </span>
                </div>
                <div>
                    {{ item.comment }}
                </div>
            </li>
        </ul>
    </div>
    <div class="modal-footer">
        <button class="btn btn-default" type="button" (click)="modalRef.hide()">
            {{ localizations.common.close }}
        </button>
    </div>
</ng-template>

<ng-template #changeDateCompleteModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations.tasks.change_appeal_date }}
        </h4>
        <button class="close pull-right" type="button" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <div class="mb-10">{{ getDaysLeftText() }}.</div>
        <div>{{ localizations.tasks.ask_change_appeal_date }}</div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-primary btn-lg" (click)="complete('expired')">
            {{ localizations.common.yes }}
        </button>
        <button class="btn btn-primary-overline btn-lg" (click)="complete('common')">
            {{ localizations.common.no }}
        </button>
    </div>
</ng-template>
