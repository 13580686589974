import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PrintUtilities } from '@evolenta/utilities';
import { AppealValidateService } from '../../../../appeal-validate.service';
import { AppealSubservicesService } from '../../../../appeal-subservices.service';
import { AppealService } from '../../../../appeal.service';

@Component({
    selector: 'appeal-subservice-preview',
    templateUrl: './appeal-subservice-preview.component.html',
})
export class AppealSubservicePreviewComponent implements OnInit {
    @Input() public appealSubservice; // Обрабатываемая услуга дела
    @Input() public activeTab; // Активная вкладка
    @Input() public mode = 'view'; // Режим отображения: view - предпросмотр карточки в списке; edit - редактированные данных услуги
    @Input() public subjects = []; // Массив субъектов дела

    @Output() public onActivateTab = new EventEmitter<string>();
    @Output() public onGoToLicense = new EventEmitter<string>();

    public subservice; // Описательная услуга
    public data; // описательный объект настройки объектов внутри текущей услуги

    public isProcessEditIssuerResultForm = false; // режим выбора варианта выдачи результата оказания услуги заявителю
    public isExistAdditionalData = false;
    public tabWithAdditionalData;

    public constructor(
        public appealSubservicesService: AppealSubservicesService,
        public validateService: AppealValidateService,
        public appealService: AppealService,
    ) {}

    public ngOnInit() {
        this.data = this.appealSubservicesService.data[this.appealSubservice.guid];
        this.subservice = this.appealSubservicesService.data[this.appealSubservice.guid].subservice;
        if (
            this.subservice.issueResultForm &&
            !this.appealSubservice.issueResultForm &&
            this.data.allowEdit &&
            this.mode === 'edit'
        ) {
            this.isProcessEditIssuerResultForm = true;
        }
        if (this.subservice.xsdTabs && this.subservice.xsdTabs.length > 0) {
            const globalForm = this.subservice.xsdTabs.find(item => item.isGlobal && item.xsd);
            if (globalForm) {
                this.isExistAdditionalData = true;
                this.tabWithAdditionalData = globalForm.tabCode;
            }
        }
    }

    public activateTab(tab) {
        this.onActivateTab.emit(tab);
    }

    /**
     * КНМ наличия ошибок в услуге
     */
    public checkErrorsExist() {
        return !this.validateService.checkElementValid(this.appealSubservice.guid, 'subservice');
    }

    public getErrorsList() {
        return this.validateService.getElementErrorsList(this.appealSubservice.guid, 'subservice');
    }

    /**
     * КНМ корректности заполнения определенного параметра услуги
     * @param tab - вкладка, на которой обрабатывается параметр услуги
     * @param property - проверяемое свойство
     * @returns {boolean}
     */
    public checkElementHasError(tab, property) {
        let hasError = false;
        if (
            this.validateService.processValidate &&
            this.validateService.validateElements.subservice.tabs[tab].errors &&
            this.validateService.validateElements.subservice.tabs[tab].errors[property]
        ) {
            hasError = true;
        }

        return hasError;
    }

    /**
     * Возвращает число активных объектов в услуге
     * @returns {number}
     */
    public getSubjectsCount() {
        let countSubjects = 0;
        Object.keys(this.data.subjects).forEach(subjectGuid => {
            if (this.data.subjects[subjectGuid].active) {
                countSubjects++;
            }
        });

        return countSubjects;
    }

    /**
     * Возврат адреса в виде строки
     * @param addressItems
     * @returns {string}
     */
    public getAddress(addressItems) {
        return PrintUtilities.PrintAddressAsLine(addressItems, true);
    }

    public get hasLicense() {
        return this.appealSubservice.mainElement && this.appealSubservice.mainElement.licenseId;
    }

    public goToLicense(license) {
        this.onGoToLicense.emit(license.licenseId);
    }

    public get licenseTitle() {
        return (
            (this.subservice.registersModel.titles && this.subservice.registersModel.titles.single) ||
            (this.subservice.registersModel.licenseRecordType &&
                this.subservice.registersModel.licenseRecordType.name) ||
            'Лицензия'
        );
    }
}
