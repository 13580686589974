<!-- Настройка параметров услуг (последовательность в списке + зависимость от других услуг) -->
<div class="p-10" *ngIf="isProcessSetupSubservicesInAppeal">
    <div
        class="border-default drop-zone"
        [ngClass]="{ over: subservice.isOver }"
        (drop)="drop($event, subservice)"
        (dragover)="dragOver($event, subservice)"
        (dragleave)="dragLeave($event, subservice)"
        *ngFor="let subservice of subservicesStructure | orderBy : ['displayOrder']; let idx = index"
    >
        <div
            class="panel panel-default border-left-lg border-left-primary-600 no-margin-bottom"
            draggable="true"
            [ngStyle]="{ opacity: subservice.isDrag ? '.5' : '1' }"
            (dragstart)="dragStart($event, subservice)"
        >
            <div class="panel-heading">
                <div style="width: 90%">
                    <span class="badge bg-primary mr-10 number">
                        {{ subservice.displayOrder }}
                    </span>
                    <span>
                        {{ subservice.title }}
                    </span>
                </div>

                <div class="heading-elements">
                    <button
                        class="btn btn-danger heading-btn action-btn btn-xs btn-labeled btn-labeled-right"
                        (click)="setupDeleteSubservice(subservice)"
                    >
                        <b>
                            <i class="icon-cross2"></i>
                        </b>
                        {{ localizations.common.delete }}
                    </button>
                </div>
            </div>

            <div class="panel-body no-padding">
                <!-- Настройка зависимостей от услуг -->
                <div class="pt-5 pb-5 pl-20 pr-20" *ngIf="!subservice.isProcessSelectParent">
                    <span class="text-muted text-size-small" *ngIf="subservice.afterSubservices.length == 0">
                        {{ localizations['appeals.subservices'].does_not_depend }}
                    </span>
                    <span class="text-semibold text-size-small" *ngIf="subservice.afterSubservices.length > 0">
                        {{ localizations['appeals.subservices'].depends_on }}:
                    </span>
                    <span
                        class="anchor text-info text-size-small ml-10"
                        *ngIf="subservice.parentSubservicesForSelect.length > 0"
                        (click)="changeParentSubservices(subservice)"
                    >
                        {{ localizations.common.setup }}
                    </span>
                    <ul
                        class="text-size-small no-margin-bottom list list-unstyled mt-5"
                        *ngIf="subservice.afterSubservices.length > 0"
                    >
                        <li *ngFor="let item of subservice.afterSubservices; let lst = last">
                            <span class="badge badge-info badge-xs mr-5">
                                {{ item.displayOrder }}
                            </span>
                            {{ item.title }}
                        </li>
                    </ul>
                </div>
                <div class="p-20 bg-primary-50" *ngIf="subservice.isProcessSelectParent">
                    <p class="text-semibold">{{ localizations['appeals.subservices'].select_processes }}:</p>
                    <div
                        class="checkbox-block"
                        [ngClass]="{ 'no-margin-bottom': lst }"
                        *ngFor="let parentSubservice of subservice.parentSubservicesForSelect; let lst = last"
                    >
                        <input
                            type="checkbox"
                            class="checkbox"
                            id="parent{{ subservice.guid }}{{ parentSubservice.guid }}"
                            [checked]="parentSubservice.selected"
                            (change)="parentSubservice.selected = !parentSubservice.selected"
                        />
                        <label for="parent{{ subservice.guid }}{{ parentSubservice.guid }}">
                            <span class="badge badge-info badge-xs mr-5">
                                {{ parentSubservice.displayOrder }}
                            </span>
                            {{ parentSubservice.title }}
                        </label>
                    </div>
                    <button
                        type="button"
                        class="btn btn-info btn-xs mt-20"
                        (click)="applyParentSubservices(subservice)"
                    >
                        {{ localizations.common.apply }}
                    </button>
                    <button
                        type="button"
                        class="btn btn-default btn-xs mt-20 ml-10"
                        (click)="cancelEditing(subservice)"
                    >
                        {{ localizations.common.cancel }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <button type="button" class="btn btn-default ml-10 mt-20" (click)="applySubserviceStructure()">
        {{ localizations.common.apply }}
    </button>
</div>

<!-- Список услуг дела / Редактирование услуги -->
<ng-container *ngIf="!isProcessSetupSubservicesInAppeal">
    <!-- Редактирование данных выбранной услуги в деле -->
    <ng-container *ngIf="editAppealSubservice">
        <appeal-subservice-card
            [appealSubservice]="editAppealSubservice"
            [appeal]="appeal"
            [subjects]="appeal.subjects"
            [activeTab]="activeTab"
            mode="edit"
            (onAddNewSubjectToAppealSubserviceGroup)="addNewSubjectToAppealSubserviceGroup($event)"
            (onApply)="onApplyAppealSubservice($event)"
            #editCard
        >
        </appeal-subservice-card>
    </ng-container>

    <!-- Список услуг дела -->
    <div class="pt-20 pr-20 pl-20" *ngIf="!editAppealSubservice && !isEditAppealSettings">
        <appeal-subservice-card
            [appealSubservice]="appeal.subservice"
            [subjects]="appeal.subjects"
            mode="view"
            (onEdit)="onEditAppealSubservice($event)"
            (onDelete)="deleteSubservice($event)"
            (initEdit)="initEditAppealSubservice($event)"
            (onGoToLicense)="gotToLicense($event)"
        >
        </appeal-subservice-card>
    </div>

    <!--Общая информация по делу-->
    <appeal-common
        [appeal]="appeal"
        *ngIf="!editAppealSubservice && appeal._id"
        (onEdit)="editAppealSettings()"
        (afterEdit)="afterEditAppealSettings()"
    >
    </appeal-common>
</ng-container>

<modal-dialog #modalDialog></modal-dialog>
