import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AccessService, FiltersService, RestService, SelectionService, StorageService } from '@evolenta/core';
import { AppealService } from './appeal.service';
import {
    DEFAULT_APPEALS_PROJECTION,
    DEFAULT_SORT_FIELD,
    SUBSERVICES_ARCHIVE_COLLECTION,
    SUBSERVICES_COLLECTION,
} from '../../../common/constants';
// import { CustomFiltersService } from '../../../common/services/custom-filters.service';
import { SettingsService } from '../../../common/services/settings.service';
import { settings } from './appeals-settings';
import { Config } from '../../../common/services/config';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppealsResolve implements Resolve<any> {
    // Возвращаемый при редактировании вариант

    public constructor(
        private http: HttpClient,
        private rest: RestService,
        private storage: StorageService,
        private appealService: AppealService,
        private accessService: AccessService,
        private filtersService: FiltersService,
        private _settingsService: SettingsService,
        private selectionService: SelectionService,
    ) {}

    public async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const defaultStatuses = this.storage.getItem('defaultStatuses');

        if (defaultStatuses) {
            await this._checkAndSetSettings(route);

            return this._getData(route);
        }

        // Получение базовых данных, нужных для работы с делом
        const promises = [];
        promises.push(this.rest.search('catalogueResultTypes'));
        promises.push(this.rest.search('catalogueIssueTypes'));
        promises.push(
            this.rest.search('catalogueDocuments', {
                search: {
                    search: [
                        {
                            field: 'identity',
                            operator: 'eq',
                            value: true,
                        },
                    ],
                },
            }),
        );
        promises.push(
            this.rest.search('settings', {
                search: {
                    search: [
                        {
                            field: 'name',
                            operator: 'eq',
                            value: 'archive.notcleanup.deal',
                        },
                    ],
                },
            }),
        );

        const signApplicationParam = this.storage.getItem('signApplicationParam');
        if (!signApplicationParam) {
            promises.push(this.http.post(Config.server + 'getSignApplicationParam', {}).toPromise());
        }

        if (this.storage.getItem('userEnvelopesMode') === null) {
            promises.push(
                this.rest.search('settings', {
                    search: {
                        search: [
                            {
                                field: 'name',
                                operator: 'eq',
                                value: 'userEnvelopesMode',
                            },
                        ],
                    },
                }),
            );
        } else {
            promises.push(null);
        }

        // Получение фильтров
        // const user = this.storage.getItem('user');

        // TODO раскомментирвоать, когда будет выкачен КПП
        /*if (!user.filters || !user.filters.some(filter => !filter.allowedInCollections || filter.allowedInCollections.includes('appeals'))) {
            console.log('user do not have filters for appeal. fetching');
            const fetchFilters = this.customFiltersService.getFiltersList();
            console.log('fetchFilters', fetchFilters);
            promises.push(fetchFilters);
        }*/

        const results = await Promise.all(promises);
        // this.storage.setItem('defaultStatuses', results[0][0].value);
        this.storage.setItem('resultTypes', results[0]);
        this.storage.setItem('issueTypes', results[1]);
        this.storage.setItem('catalogueDocuments', results[2]);

        if (!signApplicationParam) {
            if (results[3] && results[3].value) {
                this.storage.setItem('signApplicationParam', {
                    url: results[3].value.url,
                    systemUid: results[3].value.systemUid,
                });
            } else {
                this.storage.setItem('signApplicationParam', {});
            }
        }

        return this._settingsService.load(settings, () => this._getData(route));
    }

    private async _checkAndSetSettings(route) {
        await this._getWaitingPeriodSendToArchive(route);
        await this._getMkguSettings(route);
        await this._getAppealComplexMode(route);
        await this._getQuickRequestsSetup(route);
    }

    /*private _processNoFilterFilter(user) {
        const externalAuth = this.storage.getItem('isExternalAuth');
        // Добавление возможности отключения фильтра по текущему МФЦ при наличии соответствующего полномочия
        if (!externalAuth && this.accessService.existPermission(Permission.No_Limit_By_Current_Unit)
            || externalAuth && user.isSuperAdmin) {
            const noFilterFilter = this.appealService.noFilterFilter;
            if (user.filters.findIndex(filter => filter.code === noFilterFilter.code) < 0) {
                user.filters.unshift(noFilterFilter);
            }
        }

        return user;
    }*/

    /*public getKnoSubjectsSearchParams(route): Promise<any> {
        return this.filtersService.getCollectionSearchParams('subjectsKno').then(() => {
            return this._getKnoObjectsSearchParams(route);
        });
    }*/

    /*private _getKnoObjectsSearchParams(route) {
        return this.filtersService.getCollectionSearchParams('objectsKno').then(() => {
            return this._checkDemoStend(route);
        });
    }*/

    /*private _checkDemoStend(route) {
        const demoStend = this.storage.getItem('demoStend');

        if (!demoStend) {
            // tslint:disable-next-line:no-shadowed-variable
            return this.rest.search('settings', {search: {search: [{field: 'name', operator: 'eq', value: 'isDemoStend'}]}}).then(settings => {
                if (settings.length > 0) {
                    this.storage.setItem('demoStend', {'isDemo': settings[0].value});
                } else {
                    this.storage.setItem('demoStend', {'isDemo': false});
                }

                return this._getWaitingPeriodSendToArchive(route);
            });
        } else {
            return this._getWaitingPeriodSendToArchive(route);
        }
    }*/

    /**
     * Получение параметра - итервала времени, через который возможен перевод дела в архив
     * @param route
     */
    private async _getWaitingPeriodSendToArchive(route) {
        const waitingPeriodSendToArchive = this.storage.getItem('waitingPeriodSendToArchive');

        if (!waitingPeriodSendToArchive) {
            // tslint:disable-next-line:no-shadowed-variable
            const settings = await this.rest.search('settings', {
                search: { search: [{ field: 'name', operator: 'eq', value: 'period-waiting-send-to-archive-appeal' }] },
            });
            if (settings.length > 0) {
                this.storage.setItem('waitingPeriodSendToArchive', settings[0].value);
            }
        }
    }

    /**
     * Получение настроек опроса МКГУ
     * @param route
     */
    private async _getMkguSettings(route) {
        if (this.storage.getItem('mkguInterviewApplication')) {
            return;
        }

        const settingsResponse = await this.rest.search('settings', {
            search: { search: [{ field: 'name', operator: 'eq', value: 'integration.mkgu.application' }] },
        });
        const mkguInterviewApplicationSettings =
            settingsResponse && settingsResponse.length ? settingsResponse[0].value : 'none';

        this.storage.setItem('mkguInterviewApplication', mkguInterviewApplicationSettings);
    }

    /**
     * Определение режима работы с комплексным делом при приеме документов по умолчанию
     * @param route
     */
    private async _getAppealComplexMode(route) {
        const complexAppealSimplifiedMode = this.storage.getItem('complexAppealSimplifiedMode');

        if (complexAppealSimplifiedMode) {
            return;
        }

        const sett = await this.rest.search('settings', {
            search: { search: [{ field: 'name', operator: 'eq', value: 'complex.appeal.simplified.mode' }] },
        });
        if (sett.length) {
            this.storage.setItem('complexAppealSimplifiedMode', settings[0].value);
        }
    }

    /**
     * Получение настроек быстрых запросов
     * @param route
     */
    private async _getQuickRequestsSetup(route) {
        if (this.storage.getItem('quickRequestsSettings')) {
            return;
        }

        const sett = await this.rest.search('settings', {
            search: { search: [{ field: 'name', operator: 'eq', value: 'integration.quickrequests.settings' }] },
            prj: 'quickRequestsSetup',
        });
        if (sett.length) {
            this.storage.setItem('quickRequestsSettings', sett[0]);
        }
    }

    private async _getData(route) {
        if (this.selectionService.isProcessSelect) {
            return {};
        }

        if (this.appealService.isProcessSetupAppealSubservices) {
            return {};
        }

        if (route.params.appealId) {
            const { appeal, collection } = await this._getAppeal(route.params.appealId);
            // TODO нужно ли?
            const complexSubservice = await this._getComplexSubservice(appeal);
            const subservice = await this._getSubservices(appeal);

            return { appeal, collection, complexSubservice, subservice };
        }

        if (route.params.subserviceId) {
            // Создание дела на основе услуги
            return this.rest.find(SUBSERVICES_COLLECTION, route.params.subserviceId).then(subservice => {
                return this._getMetaReglament(subservice.version).then(metaReglament => {
                    subservice.metaReglament = metaReglament;
                    return subservice;
                });
            });
        }

        if (route.params.complexSubserviceId) {
            // Создание дела на основе комплексной услуги
            return this.rest.find('subservicesComplex', route.params.complexSubserviceId);
        }

        if (route.data.version) {
            const metaReglament = await this._getMetaReglament(route.data.version);
            if (metaReglament) {
                return await this._getAppeals(route, metaReglament);
            }
        }

        return await this._getAppeals(route);
    }

    private async _getAppeals(route, metaReglament = null) {
        const currentUnit: any = this.storage.getItem('currentOrganization');
        // Получение списка дел
        const search: any = [
            {
                orSubConditions: [
                    {
                        field: 'unit.id',
                        operator: 'eq',
                        value: currentUnit._id,
                    },
                    {
                        field: 'organizationExecutor.id',
                        operator: 'eq',
                        value: currentUnit._id,
                    },
                ],
            },
            // В панели фильтров "актуальность меню" по-умолчанию выставляем - "активные"
            {
                orSubConditions: [
                    // Отключили по PPGMUD-342 , потому что фильтруем по datePlaneFinish, которая в черновиках null
                    /*{
                        field: 'status.code',
                        operator: 'eq',
                        value: 'draft'
                    },*/
                    {
                        field: 'status.code',
                        operator: 'eq',
                        value: 'process',
                    },
                ],
            },
        ];

        if (route.data.version) {
            search.push({ field: 'subservices.version', operator: 'eq', value: route.data.version });
        }

        this.appealService.prepareSearchBySubservices(search);

        const sort = DEFAULT_SORT_FIELD;
        const sortFieldNotNullClause = {
            field: DEFAULT_SORT_FIELD,
            operator: 'neq',
            value: null,
        };

        search.push(sortFieldNotNullClause);

        const appeals = await this.rest.search('appeals', {
            search: { search },
            sort,
            prj: DEFAULT_APPEALS_PROJECTION,
        });

        return {
            appeals,
            metaReglament,
        };
    }

    /**
     * Получение дела
     * @param appealId - ID дела
     * @returns {Promise<any>}
     */
    private async _getAppeal(appealId) {
        let appeal = await this.rest.find('appeals', appealId);

        if (appeal) {
            return { appeal };
        }
        let collection;

        // Дело находится в архивной коллекции
        const archiveAppeal = await this.rest.find('appealsArchive', appealId);
        // Если нет дела в архивной коллекции, то оно в преархивной коллекции
        if (!archiveAppeal) {
            const preArchiveAppeal = await this.rest.find('appealsPreArchive', appealId);

            // Если нет дела в архивной коллекции, то оно в преархивной коллекции
            appeal = preArchiveAppeal;
            collection = 'appealsPreArchive';
        } else {
            appeal = archiveAppeal;
            collection = 'appealsArchive';
        }

        return { appeal, collection };
    }

    /**
     * Получение комплексной услуги
     * @returns {any}
     */
    private async _getComplexSubservice(appeal) {
        if (appeal.complexSubservice) {
            return await this.rest.find('subservicesComplex', appeal.complexSubservice.id);
        }
    }

    /**
     * Получение услуг дела
     * @returns {Promise<any>}
     */
    private async _getSubservices(appeal) {
        if (!appeal.subservice) {
            return;
        }

        const subservice = await this.rest.find(SUBSERVICES_COLLECTION, appeal.subservice.id);
        if (subservice) {
            return subservice;
        }

        return await this.rest.find(SUBSERVICES_ARCHIVE_COLLECTION, appeal.subservice.id);
    }

    private async _getMetaReglament(version) {
        return this.rest
            .search('metaReglaments', { search: { search: [{ field: 'code', operator: 'eq', value: version }] } })
            .then(response => response[0]);
    }
}
