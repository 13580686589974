<!-- Режим отображения в "Задаче" -->
<ng-container *ngIf="task">
    <table class="table">
        <tbody>
        <ng-container *ngFor="let group of groups; let fst = first; let lst = last">
            <tr>
                <td class="text-uppercase text-bold"
                    [ngClass]="{
                        'no-border-top': fst,
                        'border-bottom border-bottom-default': !lst
                    }">
                    {{group.name}}
                    <span class="badge bg-primary-300 text-uppercase ml-10">
                        {{countAllDocuments(group)}}
                    </span>
                </td>
                <td class="text-right"
                    [ngClass]="{
                        'no-border-top': fst,
                        'border-bottom border-bottom-default': !lst
                    }">
                    <button class="btn btn-primary-overline"
                            (click)="addDocument(group)"
                            *ngIf="allowEdit">
                        {{ localizations.common.add }}
                    </button>
                </td>
            </tr>
            <tr>
                <td class="no-padding"
                    colspan="2">
                    <ng-container *ngFor="let document of appeal.documents">
                        <appeal-document-card [document]="document"
                                              [appeal]="appeal"
                                              [group]="group"
                                              [task]="task"
                                              [allowEdit]="allowEdit"
                                              mode="view"
                                              *ngIf="(document.groupGuid && document.groupGuid === group.guid && document.subserviceGuid == appealSubservice.guid)
                                              || (!document.groupGuid && !group.guid && !group.code && !document.groupCode)
                                              || (group.code == 'complex' && document.groupCode == 'complex')"
                                              (onEdit)="editDocument($event)"
                                              (onDelete)="deleteDocument($event)"
                                              (onPrint)="printDocument($event)">
                        </appeal-document-card>
                    </ng-container>
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>
</ng-container>

<!-- Режим отображения в общем списке -->
<ng-container *ngIf="!task">
    <ng-container *ngFor="let group of groups; let lst = last">
        <div [ngClass]="{'no-margin-bottom': lst, 'panel panel-shadow': !task, 'mt-20': group.name == localizations['appeals.documents'].other}">
            <div [ngClass]="{'panel-heading': !task}">
                <h4 class="panel-title"
                    [ngStyle]="{'max-width': allowAddDocument ? '80%' : '100%'}">
                    {{ group.name }}
                    <!-- Кнопка "Проверка ЭП" -->
                    <button class="btn btn-sxs ml-5" style="background-color: #fcfcfc"
                    *ngIf="!task && allowEdit"
                    (click)="verifySignature(group)"
                    type="button"
                    title="Проверка ЭП">
                        <b>
                            <i [inlineSVG]="'assets/sign-icons/key.svg'"></i>
                        </b>
                     </button>
                    <!-- Индикатор числа добавленных и необходимых документов внутри группы -->
                    <div>
                        <span class="badge bg-primary-300 text-uppercase">
                            {{ localizations['appeals.documents'].of_documents }}: {{countAllDocuments(group)}}&nbsp;/&nbsp;{{group.min}}
                        </span>
                    </div>
                </h4>
                <div class="heading-elements"
                     *ngIf="checkAllowAddDocument(group) && !isDisabledByRso">
                    <button class="btn btn-success-overline heading-btn btn-labeled pt-10 pb-10"
                            type="button"
                            [disabled]="!accessService.hasAccess([permissions.Appeal_Document_Create], true, appeal.status)"
                            (click)="addDocument(group)"
                            [title]="localizations['appeals.documents'].add">
                        <i class="icon-plus3"></i>
                    </button>
                </div>
            </div>
            <div class="panel-body no-padding">
                <ng-container *ngFor="let document of appeal.documents">
                    <appeal-document-card [document]="document"
                                          [appeal]="appeal"
                                          [group]="group"
                                          [task]="task"
                                          mode="view"
                                          *ngIf="(document.groupGuid && document.groupGuid === group.guid && document.subserviceGuid == appealSubservice.guid)
                                              || (!document.groupGuid && !group.guid && !group.code && !document.groupCode)
                                              || (group.code == 'complex' && document.groupCode == 'complex')"
                                          (onEdit)="editDocument($event)"
                                          (onDelete)="deleteDocument($event)"
                                          (onPrint)="printDocument($event)">
                    </appeal-document-card>
                </ng-container>
            </div>
        </div>
    </ng-container>
</ng-container>

