<div class="panel panel-shadow" *ngIf="mode === 'view'">
    <div class="panel-heading">
        <div class="panel-title">
            <h4 class="panel-title">
                {{ appealSubservice.shortTitle }}
            </h4>
        </div>
        <div class="heading-elements">
            <!-- Смена статуса -->
            <div class="btn-group hide" *ngIf="!isDisabledByRso && data.actions && data.actions.length > 0">
                <button
                    class="btn btn-primary heading-btn action-btn btn-xs dropdown-toggle btn-labeled btn-labeled-right"
                    data-toggle="dropdown"
                    [disabled]="
                        !accessService.hasAccess([permissions.Appeal_Execute_Action], true, appealSubservice.status) ||
                        appealStatusService.currentExecuteAction
                    "
                >
                    <b>
                        <i
                            [ngClass]="{
                                'icon-spinner2 spinner': data.isProcessExecuteAction,
                                'icon-arrow-down32': !data.isProcessExecuteAction
                            }"
                        ></i>
                    </b>
                    {{ localizations['appeals.subservices'].change_status }}
                </button>
                <ul class="dropdown-menu dropdown-menu-right">
                    <li *ngFor="let action of data.actions">
                        <a (click)="executeAction(action)">
                            {{ action.name }}
                        </a>
                    </li>
                </ul>
            </div>
            <button
                *ngIf="!isDisabledByRso"
                class="btn btn-default"
                (click)="edit()"
                [title]="localizations.common.edit"
            >
                <b>
                    <i inlineSVG="assets/icon-edit.svg"></i>
                </b>
            </button>

            <button
                class="btn btn-default"
                (click)="deleteSubservice()"
                *ngIf="!isDisabledByRso && data.allowDelete"
                [title]="localizations.common.delete"
            >
                <b>
                    <i inlineSVG="assets/icon-trash.svg"></i>
                </b>
            </button>
        </div>
    </div>
    <div class="panel-body">
        <ng-container *ngTemplateOutlet="appealSubservicePreview"></ng-container>
    </div>
    <div class="panel-footer">
        <ng-container *ngTemplateOutlet="footerElements"></ng-container>
    </div>
</div>

<div
    class="panel panel-shadow m-20"
    *ngIf="mode === 'edit'"
    [ngClass]="{ 'no-border-top no-border-left no-border-right no-border-radius': mode == 'edit' }"
>
    <div class="panel-heading">
        <div class="panel-title width-70">
            <h4>{{ appealSubservice.shortTitle }}</h4>
            <ng-container *ngTemplateOutlet="statusTemplate"></ng-container>

            <!-- Код регламента в СПЭР -->
            <span class="text-muted mr-5 hide" *ngIf="subserviceCode">
                {{ subserviceCode }}
            </span>
        </div>
        <!-- Управляющие кнопки -->
        <div *ngIf="!isDisabledByRso" class="heading-elements">
            <button class="btn heading-btn btn-success-overline btn-labeled" (click)="apply()">
                {{ localizations.common.apply }}
            </button>
            <button class="btn heading-btn btn-danger-overline btn-labeled" (click)="cancel()">
                <span *ngIf="data.allowEdit">
                    {{ localizations.common.do_cancel }}
                </span>
                <span *ngIf="!data.allowEdit">
                    {{ localizations.common.close }}
                </span>
            </button>
        </div>
    </div>
    <div class="panel-body no-padding">
        <ng-container *ngTemplateOutlet="appealSubservicePreview"></ng-container>

        <!-- Элементы редактирования: табы с блоками настройки услуг: варианты, организации, объекты, статусная модель -->
        <!-- Вкладки доступа к блокам редактирования информации -->
        <div class="mt-20">
            <element-edit-tabs
                [tabs]="editTabs"
                [active]="activeTab"
                [elementId]="appealSubservice.guid"
                [type]="'subservices'"
                (onActivateTab)="activateTab($event)"
            >
            </element-edit-tabs>
        </div>

        <!-- Выбор варианта услуги -->
        <appeal-subservice-common
            [appealSubservice]="appealSubservice"
            [subservice]="subservice"
            *ngIf="activeTab == 'common'"
            (onAfterChangeVariant)="afterChangeVariant()"
        >
        </appeal-subservice-common>

        <!-- Выбор варианта услуги -->
        <appeal-subservice-variant
            [appealSubservice]="appealSubservice"
            [subservice]="subservice"
            *ngIf="activeTab == 'variant'"
            (onAfterChangeVariant)="afterChangeVariant()"
        >
        </appeal-subservice-variant>

        <!-- Настройка объектов (привязка к группам) -->
        <appeal-subservice-subjects
            [appealSubservice]="appealSubservice"
            [subservice]="subservice"
            [subjects]="subjects"
            (onAddNewSubjectToAppealSubserviceGroup)="addNewSubjectToAppealSubserviceGroup($event)"
            *ngIf="activeTab == 'subjects'"
        >
        </appeal-subservice-subjects>

        <!-- Дополнительные данные по услуге и варианту услуги -->
        <appeal-subservice-additional-data
            [hidden]="activeTab !== 'additionalData'"
            [appealSubservice]="appealSubservice"
            [subservice]="subservice"
            [appeal]="appeal"
            [tab]="activeTabInfo"
            (onAfterSave)="afterSaveAdditionalData($event)"
            [disabled]="isDisabledByRso"
            #additionalData
        >
        </appeal-subservice-additional-data>
    </div>
    <div class="panel-footer no-padding">
        <ng-container *ngTemplateOutlet="footerElements"></ng-container>
    </div>
</div>

<modal-dialog #applyModal (onSelect)="processingBack($event)"></modal-dialog>

<!-- модальное окно для задания номера окна и выбора оценивающего участника -->
<ng-template #setWindowNumberOrInterviewUserModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
            {{ localizations['appeals.subservices'].evaluation_params }}
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group" *ngIf="!eqService.window || !eqService.window.number">
            <label>
                {{ localizations['appeals.subservices'].enter_window_number }}
            </label>
            <input type="number" class="form-control" [(ngModel)]="windowNumber" />
        </div>
        <ng-container *ngIf="!interviewObject">
            <p class="text-semibold mb-10">{{ localizations['appeals.subservices'].select_participant }}:</p>
            <div class="radio-block" *ngFor="let object of data.notAgreeMkguObjects">
                <input
                    type="radio"
                    class="radio"
                    id="intobj{{ object.guid }}"
                    (click)="selectedInterviewObject = object"
                />
                <label for="intobj{{ object.guid }}">
                    {{ object.header }}
                </label>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="setWindowNumber()">
            {{ localizations.common.apply }}
        </button>
        <button type="button" class="btn btn-default" (click)="modalRef.hide()">
            {{ localizations.common.cancel }}
        </button>
    </div>
</ng-template>

<!-- модальное окно с интерфейсом оценки -->
<ng-template #interviewModal>
    <div class="modal-body no-padding interview">
        <iframe [src]="interviewApplicationAddress" width="1024" height="768" class="no-border"> </iframe>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default" (click)="modalRef.hide()">
            {{ localizations.common.close }}
        </button>
    </div>
</ng-template>

<!-- Карточка предпросмотра -->
<ng-template #appealSubservicePreview>
    <appeal-subservice-preview
        [appealSubservice]="appealSubservice"
        *ngIf="mode == 'view'"
        [mode]="mode"
        [activeTab]="activeTab"
        [subjects]="subjects"
        (onActivateTab)="activateTab($event)"
        (onGoToLicense)="goToLicense($event)"
    >
    </appeal-subservice-preview>
</ng-template>

<ng-template #footerElements>
    <div class="date-elements">
        <!-- Плановый срок оказания услуги -->
        <div class="p-20" *ngIf="appealSubservice.status.code == 'draft' && getSubservicePlaneDate()">
            <div>
                <h5 class="date-text no-margin-top">
                    {{ localizations['appeals.subservices'].planned_completion_term }}
                </h5>
                <div class="date-value">
                    <i inlineSVG="assets/icon-clock.svg"></i>
                    {{ getSubservicePlaneDate() }}
                </div>
            </div>
        </div>

        <!-- Дата регистрации -->
        <div class="p-20" *ngIf="appealSubservice.dateRegister">
            <div>
                <h5 class="date-text">
                    {{ localizations['appeals.subservices'].registration_date }}
                </h5>
                <div class="date-value">
                    <i inlineSVG="assets/icon-clock.svg"></i>
                    {{ appealSubservice.dateRegister | date }}
                </div>
            </div>
        </div>

        <!-- Дата планового завершения услуги -->
        <div
            class="p-20"
            *ngIf="
                appealSubservice.datePlaneFinish &&
                ((appealSubservice.status.isSubstatus && appealSubservice.status.mainStatusCode === 'process') ||
                    appealSubservice.status.code == 'process')
            "
        >
            <div>
                <h5 class="date-text">
                    {{ localizations['appeals.subservices'].planned_completion_date }}
                </h5>
                <div class="date-value">
                    <i inlineSVG="assets/icon-clock.svg"></i>
                    {{ appealSubservice.datePlaneFinish | date }}
                </div>
            </div>
        </div>

        <!-- Дата завершения -->
        <div class="p-20" *ngIf="appealSubservice.dateFinish">
            <div>
                <h5 class="date-text">
                    {{ localizations['appeals.subservices'].completion_date }}
                </h5>
                <div class="date-value">
                    <i inlineSVG="assets/icon-clock.svg"></i>
                    {{ appealSubservice.dateFinish | date }}
                </div>
            </div>
        </div>

        <!-- Текстовая информация -->
        <div
            class="date-info"
            *ngIf="
                appealSubservice.issuedOther ||
                (appealSubservice.reasonOfAnnul && appealSubservice.reasonOfAnnul.title) ||
                (appealSubservice.reasonOfReject && appealSubservice.reasonOfReject.title)
            "
        >
            <i class="icon-file-text2 text-slate mr-10"></i>

            <!-- Иной способ выдачи -->
            <div class="text-size-small" *ngIf="appealSubservice.issuedOther">
                <span class="text-muted ml-5"> {{ localizations['appeals.subservices'].issue_method }}: </span>
                {{ appealSubservice.issuedOther }}
            </div>

            <!-- Причина аннулирования -->
            <div class="text-size-small" *ngIf="appealSubservice.reasonOfAnnul && appealSubservice.reasonOfAnnul.title">
                <span class="text-muted ml-5"> {{ localizations['appeals.subservices'].reason.cancel }}: </span>
                {{ appealSubservice.reasonOfAnnul.title }}
            </div>

            <!-- Причина отказа -->
            <div
                class="text-size-small"
                *ngIf="appealSubservice.reasonOfReject && appealSubservice.reasonOfReject.title"
            >
                <span class="text-muted ml-5"> {{ localizations['appeals.subservices'].reason.decline }}: </span>
                {{ appealSubservice.reasonOfReject.title }}
            </div>
        </div>
    </div>
</ng-template>

<ng-template #statusTemplate>
    <!-- Порядковый номер услуги -->
    <!--<span class="badge bg-primary mr-10 number" *ngIf="mode === 'edit' && data.number">{{data.number}}</span>-->

    <!--<label class="badge text-uppercase"
           [ngClass]="
               appealSubservice.resultType && appealSubservice.resultType.type === 'negative' ?
               getStatusProperty('background', 'rejectedBackground') :
               getStatusProperty('background')">
        {{
            appealSubservice.resultType && appealSubservice.resultType.type === 'negative' ?
            'Отказ' :
            getStatusProperty('shortName')
        }}
    </label>-->
</ng-template>
