import { Injectable } from '@angular/core';
import { AccessService, HttpService, RestService, StorageService, ToasterService } from '@evolenta/core';
import { CommonUtilities } from '@evolenta/utilities';
import { AppealStatusService } from '../../appeal-status.service';
import { AppealSubservicesService } from '../../appeal-subservices.service';
import { Permission } from '../../../../../common/services/permission';
import cloneDeep from 'lodash-es/cloneDeep';
import orderBy from 'lodash-es/orderBy';
import * as moment from 'moment';
import * as _ from 'lodash-es';
import {
    SED_PRAKTIKA_DOCUMENT_NAME,
    NEXT_SED_PRAKTIKA_DOCUMENT_VERSION_NAME,
    ENVELOPES_COLLECTION,
    REQUESTS_COLLECTION,
} from '../../../../../common/constants';
import { Config } from '../../../../../common/services/config';

@Injectable()
export class DocumentService {
    public appeal; // формируемое дело
    public subservice; // услуги на основе которых формируется дело
    public appealSubservicesData = {};

    public data = {}; // описательный объект документов
    public tempData = null; // временный объект описания документов

    public objectTypeGroup = {};

    public allowPartiallyEditTabs = ['files', 'properties'];
    public printForms = []; // Массив доступных печатных форм

    public permissions = Permission; // Набор прав системы
    public currentOrganization = this.storage.getItem('currentOrganization');
    public sedPraktikaNotifications = {
        fail: '',
    };

    public constructor(
        private rest: RestService,
        private appealStatusService: AppealStatusService,
        private appealSubservicesService: AppealSubservicesService,
        private accessService: AccessService,
        private storage: StorageService,
        private toaster: ToasterService,
        private httpService: HttpService,
    ) {}

    /**
     * Очистка данных сервиса
     */
    public clearData() {
        this.appeal = null;
        this.subservice = null;
        this.appealSubservicesData = {};
        this.data = {};
        this.tempData = null;
        this.objectTypeGroup = {};
    }

    /**
     * Инициализация описательного массива в соответствии с информацией, сохраненной в деле
     * @param appeal
     * @param subservice
     */
    public initData(appeal, subservice) {
        this.appeal = appeal;
        this.subservice = subservice;

        // Инициализация услуг дела
        this.reInitSubserviceData();

        // Корректировка набора групп документов для услуг
        this.correctSubserviceDocGroups();

        // Определение наличия ПФ для видов документов в первичных услугах
        this._defineDocumentsPrintForms(this.subservice.documentGroups);

        // Услуга и группа для документа
        if (this.appeal.documents) {
            const docs = [];
            this.appeal.documents.forEach(async (document) => {
                await this.initDocumentDataFromAppeal(document);
                const documentData = this.data[document.guid];
                docs.push(documentData);
            });
            this.appeal.tmpDocs = docs;
        }
    }

    // Переинициализация данных услуг для данного сервиса
    public reInitSubserviceData() {
        this.appealSubservicesData[this.appeal.subservice.guid] = cloneDeep(this.appeal.subservice);
        this.appealSubservicesData[this.appeal.subservice.guid].subservice = this.subservice;
    }

    /**
     * Корректировка набора групп документов для каждой услуги дела
     * @param appealActionPriority - при выполнении действия перехода на другой статус инициализирован глобальный action для всего дела
     */
    public correctSubserviceDocGroups(appealActionPriority = false) {
        let hasUnusedDocuments = false; // флаг наличия неиспользуемых документов (тех, группы для которых были отфильтрованы в результате удаления услуги / объекта)
        Object.keys(this.appealSubservicesData).forEach((subserviceGuid) => {
            const temp = this._filterDocGroups(this.appealSubservicesData[subserviceGuid], appealActionPriority);
            hasUnusedDocuments = temp ? true : hasUnusedDocuments;
        });

        return hasUnusedDocuments;
    }

    /**
     * Инициализация данных для временного объекта из данных дела
     * @param document
     */
    public async initDocumentDataFromAppeal(document) {
        delete this.data[document.guid]; // Удаляем описание документа, для последующего его заполнения
        this.data[document.guid] = cloneDeep(document);
        const documentParams = this.data[document.guid];
        if (this.appeal.subservice && document.subserviceGuid === this.appeal.subservice.guid) {
            documentParams.appealSubservice = this.appeal.subservice;
        }
        let documentGroup;
        if (this.subservice && this.subservice._id === this.appeal.subservice.id) {
            documentGroup = this.subservice.documentGroups.find((item) => item.guid === document.groupGuid);
        }

        // Инициализация группы документов
        if (documentGroup) {
            documentParams.group = documentGroup;
            documentParams.subservice = { id: this.subservice._id, title: this.subservice.titles.shortTitle };
            // Инициализация вида документа
            if (document.docGuid) {
                documentParams.doc = documentGroup.docs.find((item) => item.guid === document.docGuid);
                if (documentParams.doc && documentParams.doc.printForms) {
                    documentParams.printForms = documentParams.doc.printForms;
                }
            }
        }

        // Определение в массиве печатных форм той, по которой уже ранее осуществлялось формирование (в случае если к виду документа не привязаны ПФ)
        if (documentParams.printForm && !documentParams.printForms) {
            documentParams.printForms = [documentParams.printForm];
        }

        // Инициализация участников
        documentParams.useSubjects = [];
        if (document.subjects && document.subjects.length > 0) {
            document.subjects.forEach((subjectGuid: string) => {
                const foundSubject = this.appeal.subjects.find((item) => item.guid === subjectGuid);

                if (foundSubject) {
                    documentParams.useSubjects.push(foundSubject);
                }
            });
        }

        // Инициализация объектов
        documentParams.useObjects = [];
        if (document.objects && document.objects.length > 0) {
            document.objects.forEach((objectGuid: string) => {
                const foundObject = this.appeal.objects.find((item) => item.guid === objectGuid);

                if (foundObject) {
                    documentParams.useObjects.push(foundObject);
                }
            });
        }

        // Инициализация доверителя
        if (document.principalGuid) {
            documentParams.principal = this.appeal.subjects.find((item) => item.guid === document.principalGuid);
        }

        if (this.appeal.subservice && document.resultSubserviceLink) {
            documentParams.resultSubserviceLink = {
                guid: this.appeal.subservice.guid,
                title: this.appeal.subservice.shortTitle,
            };
        }

        // Файлы документа
        documentParams.queue = document.files ? cloneDeep(document.files) : [];

        // Запрос для документа запроса
        if (document.envelopeId && !document.envelope) {
            const envelope: any = await this.rest.find('envelopes', document.envelopeId);
            if (envelope) {
                documentParams.envelope = envelope;
                documentParams.baseEnvelope = cloneDeep(envelope); // сохранение базовой версии для последующего удобства необходимости сохранения данных

                if (envelope.object) {
                    documentParams.envelopeSubject = this.appeal.subjects.find(
                        (item) => item.guid === envelope.object.guid
                    );
                }
            }
        }

        // Возможность редактирования / удаления документа в соответствии со статусом дела (услуги)
        this.calculateDocumentPermissions(document.guid);
    }

    /**
     * Расчет прав доступа для управления документом
     */
    public calculateDocumentsPermissions() {
        Object.keys(this.data).forEach((documentGuid) => {
            this.calculateDocumentPermissions(documentGuid);
        });
    }

    /**
     * Определение параметров доступа для отдельного документа
     * @param documentGuid - guid - Обрабатываемого документа
     */
    public calculateDocumentPermissions(documentGuid) {
        const documentParams = this.data[documentGuid];
        let checkedCode = this.appeal.status.code; // проверяемый кода статуса (инициализируется кодом статуса дела);
        if (documentParams.appealSubservice) {
            checkedCode = this.appeal.subservice.status.isSubstatus
                ? this.appeal.subservice.status.mainStatusCode
                : this.appeal.subservice.status.code;
        }
        const isFinishStatus = this.appealStatusService.finishStatuses.includes(checkedCode); // Дело (услуга) находится в финальном статусе
        // Если есть полномочие на полное редактирование в любом статусе
        if (this.accessService.existPermission(this.permissions.No_Edit_Limits)) {
            documentParams.allowEdit = true;
            documentParams.allowDelete = true;
            documentParams.allowPartiallyEdit = true;

            return;
        }

        // возможность полного редактирования данных документа
        // Полное редактирование возможно, если не Финальный статус дела (услуги) и если статус 'process' и документ создан уже после регистраци
        // или документ - результат оказания услуги при статусе услуги "На выдачу"
        documentParams.allowEdit =
            // дело/услуга не в финальном статусе
            (!isFinishStatus ||
                // или если статус "На выдачу"
                (checkedCode === 'beforeIssued' &&
                    // и есть права доступа для печати расписки в выдаче документов
                    ((documentParams.printForm &&
                        documentParams.printForm.formType.indexOf('issueRegister') !== -1 &&
                        ((documentParams.mainId &&
                            this.accessService.hasAccess(
                                [this.permissions.Appeal_Document_Update],
                                false,
                                this.appeal.status,
                                [
                                    {
                                        code: 'process',
                                        permission: this.permissions.Allow_Print_Registers_Appeal_Before_Issued,
                                    },
                                ]
                            )) ||
                            (!documentParams.mainId &&
                                this.accessService.hasAccess(
                                    [this.permissions.Appeal_Document_Create],
                                    false,
                                    this.appeal.status,
                                    [
                                        {
                                            code: 'process',
                                            permission: this.permissions.Allow_Print_Registers_Appeal_Before_Issued,
                                        },
                                    ]
                                )))) ||
                        // или добавляется / редактируется документ запрос, необходимый для отправки в статусе "На выдачу"
                        (documentParams.requestId &&
                            ((documentParams.mainId &&
                                this.accessService.hasAccess([this.permissions.Envelope_Update])) ||
                                (!documentParams.mainId &&
                                    this.accessService.hasAccess([this.permissions.Envelope_Create]))))))) &&
            // не документ-запрос
            (!documentParams.requestId ||
                // Bли документ запрос, и конверт еще ни отправлен, либо с ошибкой, т.е. с актуальным статусом для отправки
                (documentParams.requestId &&
                    documentParams.envelope &&
                    (documentParams.envelope.status === 'created' || documentParams.envelope.status === 'error'))) &&
            // создан после регистрации
            (documentParams.afterRegister ||
                // или без признака "создан после регистрации", но это заявление (расписка) и есть права на внесение изменений в статусе после регистрации
                (!documentParams.afterRegister &&
                    (checkedCode === 'draft' ||
                        (checkedCode === 'process' &&
                            // && documentData.printForm && documentData.printForm.formTypeCode === 'appeal'
                            documentParams.printForms &&
                            documentParams.printForms.filter((item) => item.formTypeCode === 'appeal').length > 0 &&
                            ((documentParams.mainId &&
                                this.accessService.hasAccess(
                                    [this.permissions.Appeal_Document_Update],
                                    false,
                                    this.appeal.status,
                                    [
                                        {
                                            code: 'process',
                                            permission:
                                                this.permissions.Allow_Print_Registers_After_Appeal_Registration,
                                        },
                                    ]
                                )) ||
                                (!documentParams.mainId &&
                                    this.accessService.hasAccess(
                                        [this.permissions.Appeal_Document_Create],
                                        false,
                                        this.appeal.status,
                                        [
                                            {
                                                code: 'process',
                                                permission:
                                                    this.permissions.Allow_Print_Registers_After_Appeal_Registration,
                                            },
                                        ]
                                    )))))) ||
                // или (несмотря на время создания) это документ-запрос с актуальным для отправки статусом (новый, ошибка)
                (documentParams.requestId &&
                    documentParams.envelope &&
                    (documentParams.envelope.status === 'created' ||
                        documentParams.envelope.status === 'error' ||
                        documentParams.envelope.resultXml))) &&
            // не находится в составе документов отправленного запроса
            !this._checkDocumentUseInSendedEnvelope(documentGuid);

        // возможность удаления документа из дела
        documentParams.allowDelete =
            (documentParams.allowEdit &&
                this.accessService.hasAccess([this.permissions.Appeal_Document_Delete], true, this.appeal.status, [
                    { code: 'process', permission: this.permissions.Allow_Print_Registers_After_Appeal_Registration },
                ])) ||
            (!isFinishStatus && documentParams.requestId && !documentParams.allowEdit); // CIT-1214 возможность удалить документы из "Создания проекта в СЕД" без созданного запроса.

        // возможность частичного редактирования документа (файлы, реквизиты)
        documentParams.allowPartiallyEdit =
            !isFinishStatus &&
            this.accessService.hasAccess([this.permissions.Appeal_Document_Update], true, this.appeal.status) &&
            (!documentParams.requestId ||
                (documentParams.requestId &&
                    documentParams.envelope &&
                    (documentParams.envelope.status === 'created' ||
                        documentParams.envelope.status === 'error' ||
                        (documentParams.envelope.status === 'closed' && documentParams.envelope.resultXml))));
    }

    public async getSedPraktikaModel(appeal, documentKind) {
        if (!appeal.subservice) {
            throw new Error('Отсутсвуют услуги в деле');
        }

        if (!documentKind || !documentKind.name) {
            throw new Error('Некорректный тип документа или отсутсвует название');
        }

        let model;
        if (documentKind.name === SED_PRAKTIKA_DOCUMENT_NAME) {
            model = await this._getModelForCurrentVersion(appeal);
        } else if (documentKind.name === NEXT_SED_PRAKTIKA_DOCUMENT_VERSION_NAME) {
            model = await this._getModelForNextVersion(appeal);
        } else {
            throw new Error('Неизвестный тип документа СЭД-практика:' + documentKind.name);
        }

        return model;
    }

    private async _getModelForCurrentVersion(appeal) {
        const subserviceId = appeal.subservice.id;

        let documentData: any = await this._getDocumentDataForService(subserviceId);

        let documentWithXml = documentData.find((document) => !_.isEmpty(document.xml));

        if (!documentWithXml) {
            documentData = await this._getDocumentData();
            documentWithXml = documentData.find((document) => !_.isEmpty(document.xml));
            if (!documentWithXml) {
                this.toaster.error(this.sedPraktikaNotifications.fail);

                return;
            }
        }

        return documentWithXml.xml;
    }

    private async _getModelForNextVersion(appeal) {
        const documentData: any = await this._getDocumentDataForNextVersion(appeal);

        const documentWithXml = documentData.find((document) => !_.isEmpty(document.xml));

        if (!documentWithXml) {
            this.toaster.error(this.sedPraktikaNotifications.fail);

            return;
        }

        return documentWithXml.xml;
    }

    private async _getDocumentDataForService(subserviceId) {
        const subserviceCondition = {
            field: 'subserviceId',
            operator: 'eq',
            value: subserviceId,
        };

        return this._getDocumentData(subserviceCondition);
    }

    private async _getDocumentData(additionalCondition?) {
        const requestId = await this._getIdForSedPraktikaRequest();
        if (!requestId) {
            throw new Error('Запрос для создания документов в СЭД практика не найден');
        }
        const requestCondition = {
            field: 'request._id',
            operator: 'eq',
            value: requestId,
        };
        const user = this.storage.getItem('user');
        const userCondition = {
            field: 'userCreation.login',
            operator: 'eq',
            value: user.login,
        };

        const criteria = [
            {
                andSubConditions: [requestCondition, userCondition],
            },
        ];

        if (additionalCondition) {
            criteria[0].andSubConditions.push(additionalCondition);
        }

        return await this.rest.search(ENVELOPES_COLLECTION, {
            search: { search: criteria },
            prj: 'envelopesSedDocument',
            sort: 'dateCreation,DESC',
        });
    }

    private async _getDocumentDataForNextVersion(appeal) {
        const requestId = await this._getIdForSedPraktikaRequest();
        if (!requestId) {
            throw new Error('Запрос для создания проекта документа в СЭД практика не найден');
        }
        const nextRequestId = await this._getIdForNextSedPraktikaDocumentVersionRequest();
        if (!nextRequestId) {
            throw new Error('Запрос для создания следующей версии проекта документа в СЭД Практика не найден');
        }

        const appealCondition = {
            field: 'appealId',
            operator: 'eq',
            value: appeal._id,
        };
        const requestCondition = {
            orSubConditions: [
                {
                    field: 'request._id',
                    operator: 'eq',
                    value: requestId,
                },
                {
                    field: 'request._id',
                    operator: 'eq',
                    value: nextRequestId,
                },
            ],
        };

        const criteria = [
            {
                andSubConditions: [appealCondition, requestCondition],
            },
        ];

        return await this.rest.search(ENVELOPES_COLLECTION, {
            search: { search: criteria },
            prj: 'envelopesSedDocument',
            sort: 'dateCreation,DESC',
        });
    }

    private async _getIdForSedPraktikaRequest() {
        return this._getRequestId(SED_PRAKTIKA_DOCUMENT_NAME);
    }

    private async _getIdForNextSedPraktikaDocumentVersionRequest() {
        return this._getRequestId(NEXT_SED_PRAKTIKA_DOCUMENT_VERSION_NAME);
    }

    private async _getRequestId(name) {
        const criteria = [
            {
                andSubConditions: [
                    {
                        field: 'name',
                        operator: 'eq',
                        value: name,
                    },
                ],
            },
        ];

        const requestData: any = await this.rest.search(REQUESTS_COLLECTION, { search: { search: criteria } });

        return requestData.length ? requestData[0]._id : null;
    }

    /**
     * КНМ, что документ находится в составе документов, включенных в отправленный запрос
     * @param documentGuid - guid проверяемого документа дела
     */
    private _checkDocumentUseInSendedEnvelope(documentGuid) {
        let isUseInSendedEnvelope = false;
        Object.keys(this.data).forEach((guid) => {
            if (
                this.data[guid].envelope &&
                this.data[guid].envelope.status !== 'created' &&
                this.data[guid].envelope.status !== 'error'
            ) {
                if (
                    this.data[guid].envelope.appealDocuments &&
                    this.data[guid].envelope.appealDocuments.indexOf(documentGuid) !== -1
                ) {
                    isUseInSendedEnvelope = true;
                }
            }
        });

        return isUseInSendedEnvelope;
    }

    /**
     * Генерация для документа данных из временного объекта для сохранения в деле
     * @param documentGuid - guid - документа
     */
    public generateDocumentDataToAppeal(documentGuid) {
        const data = this.data[documentGuid];
        const documentData: any = {
            guid: documentGuid, // guid документа
            parentEntries: 'appeals.documents',
            entityType: 'documents',
        };

        // Guid услуги дела
        if (data.subserviceGuid) {
            documentData.subserviceGuid = data.subserviceGuid;
        }

        // Параметры группы документа
        if (data.group) {
            documentData.groupGuid = data.group.guid;
            documentData.groupName = data.group.name;
        } else {
            documentData.groupName = data.groupName ? data.groupName : 'Прочие документы';
        }

        // Вид документа / наименование документа
        if (data.doc) {
            documentData.docGuid = data.doc.guid;
            documentData.docCode = data.doc.code;
            documentData.name = data.name;
        } else {
            documentData.name = data.name;
            if (data.docCode) {
                documentData.docCode = data.docCode;
            }
        }
        if (data.afterRegister) {
            documentData.afterRegister = true;
        }

        if (data.xsd) {
            documentData.xsd = data.xsd;
        }
        if (data.additionalData) {
            documentData.additionalData = data.additionalData;
        }

        if (data.sendToSed) {
            documentData.sendToSed = true;
            if (data.typeForFinishTask) {
                documentData.typeForFinishTask = data.typeForFinishTask;
            }
        }

        if (data.groupCode) {
            documentData.groupCode = data.groupCode;
        }

        // Автоинкрементный ID ранее сохраненного на сервере документа
        if (data.auid) {
            documentData.auid = data.auid;
            documentData.mainId = data.mainId;
        }

        documentData.isInput = !!data.isInput;
        documentData.isOutput = !!data.isOutput;
        documentData.isResult = !!data.isResult;
        documentData.files = [];
        // Если есть ссылки на документ или на результирующую услугу
        if (data.link || data.resultSubserviceLink) {
            if (data.link) {
                documentData.link = data.link;
            } else {
                documentData.link = null;
            }
            if (data.resultSubserviceLink) {
                documentData.resultSubserviceLink = {
                    id: data.resultSubserviceLink.id,
                    guid: data.resultSubserviceLink.guid,
                };
            } else {
                documentData.resultSubserviceLink = null;
            }
        } else {
            documentData.copies = data.copies; // число копий
            documentData.copiesSheets = data.copiesSheets; // число листов копий
            documentData.originals = data.originals; // число оригиналов
            documentData.originalsSheets = data.originalsSheets; // число листов оригиналов

            // Признак электронного документа
            documentData.isElectronicDocument = !!data.isElectronicDocument;

            // Серия документа
            if (data.seria) {
                documentData.seria = data.seria;
            }
            // Номер документа
            if (data.number) {
                documentData.number = data.number;
            }
            // Орган выдавший документ
            if (data.issuer) {
                documentData.issuer = data.issuer;
            }
            // Дата выдачи документа
            if (data.dateIssue) {
                documentData.dateIssue = data.dateIssue;
            }

            // Информация о печатной форме
            if (data.printForm) {
                documentData.printForm = {
                    id: data.printForm.id,
                    formType: data.printForm.formType,
                    formTypeCode: data.printForm.formTypeCode,
                };
            }

            // Добавление информации об объектах дела, которым принадлежит документ
            documentData.subjects = [];
            if (data.useSubjects && data.useSubjects.length > 0) {
                data.useSubjects.forEach((subject) => {
                    documentData.subjects.push(subject.guid);
                });
            }

            documentData.objects = [];
            if (data.useObjects && data.useObjects.length > 0) {
                data.useObjects.forEach((object) => {
                    documentData.objects.push(object.guid);
                });
            }

            // Добавление информации о доверителе
            if (data.principal) {
                documentData.principalGuid = data.principal.guid;
            }

            if (data.requestId) {
                documentData.requestId = data.requestId;
            }

            // Признак запроса, осуществляющего перевод дела в новый статус
            if (data.isForwardingRequest) {
                documentData.isForwardingRequest = data.isForwardingRequest;
            }

            if (data.envelope && data.envelope._id) {
                documentData.envelopeId = data.envelope._id;
            }

            if (data.xsd) {
                documentData.xsd = data.xsd;
                documentData.xsdRequired = data.xsdRequired;
                if (data.xsdData) {
                    documentData.xsdData = cloneDeep(data.xsdData);
                    documentData.xsdDataValid = !!data.xsdDataValid;
                }
            }
        }

        // Файлы (добавляем только те, что уже были ранее сохранены на сервере)
        if (data.queue.length > 0) {
            const files = [];
            data.queue.forEach((item) => {
                if (item._id) {
                    files.push(item);
                }
            });
            documentData.files = files;
        }

        return documentData;
    }

    // ------------------------------------------------------------------------------------------ //
    // -------------------- МЕТОДЫ ФИЛЬТРАЦИИ ГРУПП ДОКУМЕНТОВ (START)--------------------------- //
    // ------------------------------------------------------------------------------------------ //
    /**
     * Получение списка доступных групп документов для услуги с учетом выбранного варианта и параметров объектов
     * @param appealSubserviceData - объект настройки услуги в деле
     * @param appealActionPriority - приоритет активного действия для услуги при переходе дела на новый статус
     * @returns {Array}
     */
    private _filterDocGroups(appealSubserviceData, appealActionPriority) {
        const resultGroups = [];
        this.objectTypeGroup = [];
        if (appealSubserviceData.subservice.documentGroups) {
            appealSubserviceData.subservice.documentGroups.forEach((group) => {
                if (group.name === 'Межвед-запросы этапа') {
                    // todo: Временная заплатка
                    return;
                }

                if (group.code === 'documentsRequests') {
                    this._processRequestGroup(group, appealSubserviceData, resultGroups);

                    return;
                }

                if (!group.requirements.length) {
                    this._addGroupToArray(appealSubserviceData, group, resultGroups);

                    return;
                }

                group.requirements.forEach((requirement) =>
                    this._filterByRequirement(requirement, group, appealSubserviceData, resultGroups)
                );
            });
        }

        // Добавление информации о печатных формах в виды документов групп
        this._defineDocumentsPrintForms(resultGroups);
        // Корректировка настроек групп в соответствии с обязательностью и активными действиями
        this._correctGroupsByRequirements(appealSubserviceData, resultGroups, appealActionPriority);
        // Сохранение используемых групп в составе дела
        this.appealSubservicesData[appealSubserviceData.guid].appealDocumentGroups = resultGroups;

        // Удаление документов для тех групп, которые не подходят под данные условия
        return this.deleteDocumentFromUnusedGroups(this.appealSubservicesData[appealSubserviceData.guid]);
    }

    private _filterByRequirement(requirement, group, appealSubserviceData, resultGroups) {
        {
            if (requirement.variantGuids) {
                // Документ на услугу (с настройкой вариантов)
                this._filterDocGroupsByVariant(group, requirement, appealSubserviceData, resultGroups);

                return;
            }

            if (requirement.byCategory) {
                // Документ на категории
                this._filterDocGroupsByCategory(group, requirement.byCategory, appealSubserviceData, resultGroups);

                return;
            }

            if (requirement.byService) {
                // Документ на услугу (без настройки вариантов)
                this._filterDocGroupsByService(group, requirement.byService, appealSubserviceData, resultGroups);

                return;
            }

            if (requirement.byAgent) {
                // Документ на представителя
                this._filterDocGroupsByAgent(group, requirement.byAgent, appealSubserviceData, resultGroups);

                return;
            }

            if (requirement.byObjectCategory) {
                // Документ на объект недвижимости
                this._filterDocGroupsByObjectCategory(
                    group,
                    requirement.byObjectCategory,
                    appealSubserviceData,
                    resultGroups
                );

                return;
            }

            this._addGroupToArray(appealSubserviceData, group, resultGroups);
        }
    }

    public filterDocGroupByTasks(group, appealSubserviceData, resultGroups) {
        // Если группа глобальная
        if (group.isGlobal) {
            this._addGroupToArray(appealSubserviceData, group, resultGroups);
        } else if (group.tasks && group.tasks.length > 0 && appealSubserviceData.camunda) {
            let isAddGroup = false;
            Object.keys(appealSubserviceData.camunda).forEach((taskGuid) => {
                if (appealSubserviceData.camunda[taskGuid].status === 'ACTIVE') {
                    const findActiveTaskInGroupTasks = group.tasks.find(
                        (item) => item.code === appealSubserviceData.camunda[taskGuid].taskDefinitionKey
                    );
                    if (findActiveTaskInGroupTasks) {
                        isAddGroup = true;
                        if (findActiveTaskInGroupTasks.isRequired) {
                            group.isRequired = true;
                        }
                    }
                }
            });

            if (isAddGroup) {
                this._addGroupToArray(appealSubserviceData, group, resultGroups);
            }
        }
    }

    /**
     * КНМ группы "Межвед-запросы" на соответствие выбранным категориям участников
     * @param group
     * @param appealSubserviceData
     * @param resultGroups
     */
    private _processRequestGroup(group, appealSubserviceData, resultGroups) {
        const usedDocs = [];
        group.docs.forEach((doc) => {
            if (doc.participantCategories) {
                let isUseParticipantCategory = false;
                appealSubserviceData.subjects.forEach((subject) => {
                    doc.participantCategories.forEach((participantCategoryGuid) => {
                        const findCategory = subject.category.selectedCategories.find(
                            (item) => item.guid === participantCategoryGuid
                        );
                        if (findCategory) {
                            isUseParticipantCategory = true;
                        }
                    });
                });
                if (isUseParticipantCategory) {
                    usedDocs.push(cloneDeep(doc));
                }
            } else {
                usedDocs.push(doc);
            }
        });

        if (usedDocs.length > 0) {
            group = cloneDeep(group);
            group.docs = usedDocs;
            resultGroups.push(group);
        }
    }

    /**
     * Удаление документов для групп, которые не используются в данной услуге
     * @param appealSubserviceData
     */
    public deleteDocumentFromUnusedGroups(appealSubserviceData) {
        const unusedDocuments = [];
        if (this.appeal.documents) {
            this.appeal.documents.forEach((document) => {
                // Выбираем только те документы, которые принадлежат обрабатываемой услуге
                if (
                    document.subserviceGuid &&
                    document.subserviceGuid === appealSubserviceData.guid &&
                    document.groupGuid
                ) {
                    // Сброс флага "Удален"
                    if (document.deleted) {
                        document.deleted = false;
                    }

                    // Поиск группы документов в составе актуальных для набора объектов групп услуги
                    const find = appealSubserviceData.appealDocumentGroups.find(
                        (item) => item.guid === document.groupGuid
                    );
                    if (!find) {
                        document.deleted = true; // Помечаем документ, как удаленный
                        unusedDocuments.push(document);
                    }
                }
            });
        }
        // возврат флага наличия неиспользуемых документов для передачи управления в метод сохранения дела (сохранение флага "Удален")

        return unusedDocuments.length > 0;
    }

    /**
     * Настройка привязки печатных форм к документам группы
     * @param groups - обрабатываемый массив групп
     */
    private _defineDocumentsPrintForms(groups) {
        if (groups) {
            const printFormsForDocuments = this.printForms.filter((item) => item.docs && item.docs.length > 0);
            if (printFormsForDocuments.length > 0) {
                printFormsForDocuments.forEach((printForm) => {
                    printForm.docs.forEach((docData) => {
                        const groupCode = docData.docGroupId;
                        const documentCode = docData.docId;
                        const currentGroup = groups.find((item) => item.code === groupCode);
                        if (currentGroup) {
                            const currentDocument = currentGroup.docs.find((item) => item.code === documentCode);
                            if (currentDocument) {
                                // Возможно наличие нескольких ПФ для одного вида документа
                                if (!currentDocument.printForms) {
                                    currentDocument.printForms = [];
                                }
                                const find = currentDocument.printForms.find((item) => item.id === printForm._id);
                                if (!find) {
                                    currentDocument.printForms.push({
                                        id: printForm._id,
                                        formType: printForm.formType,
                                        formTypeCode: printForm.formTypeCode,
                                        name: printForm.title,
                                    });
                                }
                            }
                        }
                    });
                });
            }
        }
    }

    /**
     * КНМ применимости группы в соответствии с участием представителей в деле
     * @param group - проверяемая группа
     * @param requirements - настройки доступа
     * @param appealSubserviceData - настройки услуги в деле
     * @param resultGroups - массив групп документов
     */
    private _filterDocGroupsByAgent(group, requirements, appealSubserviceData, resultGroups) {
        // Если есть условия предоставления "На представителя"
        if (requirements && appealSubserviceData.subjects) {
            const subserviceAgents = []; // набор представителей по обрабатываемой услуге
            // Определяем наличие представителей
            appealSubserviceData.subjects.forEach((subject) => {
                // Если у объекта есть представитель
                if (subject.representative) {
                    const findIndex = subserviceAgents.findIndex((item) => item.guid === subject.representative.guid);
                    if (findIndex !== -1) {
                        // Если в массиве представителей есть уже найденный представитель, добавляем информацию о доверителе: guid, type
                        subserviceAgents[findIndex].principals.push({
                            subjectGuid: subject.guid, // guid доверителя
                            type: subject.representative.type, // тип представительства (опекун, законный представитель и т.д)
                        });
                    } else {
                        // Если в массив представителей еще не добавлен найденный
                        subserviceAgents.push({
                            guid: subject.representative.guid, // guid представителя
                            principals: [
                                {
                                    subjectGuid: subject.guid, // guid доверителя
                                    type: subject.representative.type, // тип учстия
                                },
                            ],
                        });
                    }
                }
            });
            // Если в услуге есть представители
            if (subserviceAgents.length) {
                // Описание объекта типа "Участник" в услуге (для определения описания видов представителей)
                const participantKind =
                    this.subservice._id === appealSubserviceData.id
                        ? this.subservice.objects.objectKinds.find((item) => item.type === 'participant')
                        : null;
                // Определяем, какого вида представители (ФЛ, ЮЛ, ИП)
                subserviceAgents.forEach((agent) => {
                    const agentSubject = this.appeal.subjects.find((item) => item.guid === agent.guid); // объект-описание представителя в деле
                    if (agentSubject) {
                        agent.specialTypeId = agentSubject.specialTypeId; // тип представителя ФЛ, ЮЛ, ИП
                    }

                    // Определяем guid вида представителя из описательной услуги (ФЛ, ЮЛ, ИП)
                    const agentSubKind = participantKind.subKinds.find(
                        (item) => item.type.indexOf('agent') !== -1 && item.specialTypeId === agent.specialTypeId
                    );
                    if (agentSubKind) {
                        agent.subKindGuid = agentSubKind.guid; // guid вида представителя из описательной услуги
                    }
                });
            }
            // КНМ набора полномочий
            subserviceAgents.forEach((agent) => {
                // Поиск полномочия в соответствии с guid вида представителя из описательной услуги
                const requirement = requirements.find((item) => item.kindGuids.indexOf(agent.subKindGuid) !== -1);
                // Если есть описание условий предоставления для нужного вида представителя
                if (requirement) {
                    if (requirement.forEachPrincipal) {
                        // Если документ предоставляется для каждого доверителя
                        agent.principals.forEach((principal) => {
                            // Если тип участия (доверенное лицо, законный представитель и т.д.) есть в массиве
                            if (principal.type && requirement.categoryIds.indexOf(principal.type.id) !== -1) {
                                group.isRequired = requirement.isRequired;
                                group.copy = requirement.copy;
                                group.origin = requirement.origin;
                                this._addGroupToArray(
                                    appealSubserviceData,
                                    group,
                                    resultGroups,
                                    { guid: agent.guid },
                                    null,
                                    { guid: principal.subjectGuid }
                                );
                            }
                        });
                    } else {
                        // Если документ предоставляется только для представителя (напримен, ДУЛ) - добавляем его только один раз
                        let useTypes = false;
                        agent.principals.forEach((principal) => {
                            // Если тип участия (доверенное лицо, законный представитель и т.д.) есть в массиве
                            if (requirement.categoryIds.indexOf(principal.type.id) !== -1) {
                                useTypes = true;
                            }
                        });
                        if (useTypes) {
                            group.isRequired = requirement.isRequired;
                            group.copy = requirement.copy;
                            group.origin = requirement.origin;
                            this._addGroupToArray(appealSubserviceData, group, resultGroups, { guid: agent.guid });
                        }
                    }
                }
            });
        }
    }

    /**
     * КНМ применимости группы в соответствии с выбранным вариантом услуги (в случае если в СПЭР для группы есть настройки на вариант)
     * @param group - проверяемая группа
     * @param requirement - настройки доступа
     * @param appealSubserviceData - настройки услуги в деле
     * @param resultGroups - массив групп документов
     */
    private _filterDocGroupsByVariant(group, requirement, appealSubserviceData, resultGroups) {
        // Если у услуги выбран вариант
        if (appealSubserviceData.variant) {
            let usedVariantConditions;
            // Выбор условий для соответствующего варианта
            appealSubserviceData.variant.selectedVariants.forEach((appealSubserviceVariant) => {
                // Условия предоставления на вариант данные последнего найденного уровня считаем более актуальными
                let variantConditions = null;
                if (typeof requirement.variantGuids[0] === 'string') {
                    // Случай, когда в массиве вариантов не объекты варантов (с условиями предоставления, а только guid-ы вариантов (старый вариант для совместимости)
                    const findVariant = requirement.variantGuids.find((item) => item === appealSubserviceVariant.guid);
                    if (findVariant) {
                        // Ищем соответствие вида объекта в настройках услуги
                        if (appealSubserviceData.subjects && appealSubserviceData.subjects.length > 0) {
                            appealSubserviceData.subjects.forEach((appealSubject) => {
                                if (appealSubject.subKind) {
                                    const findRequirement = requirement.byService.find(
                                        (item) => item.kindGuids.indexOf(appealSubject.subKind.guid) !== -1
                                    );
                                    if (findRequirement) {
                                        variantConditions = {
                                            required: findRequirement.isRequired,
                                            copy: findRequirement.copy,
                                            origin: findRequirement.origin,
                                        };
                                    }
                                }
                            });
                        }
                    }
                } else {
                    variantConditions = requirement.variantGuids.find(
                        (item) => item.guid === appealSubserviceVariant.guid
                    );
                }

                if (variantConditions) {
                    usedVariantConditions = variantConditions;
                }
            });
            // Если в условиях предоставления есть значения для выбранных вариантов, то группа используется
            if (usedVariantConditions) {
                group.isRequired = usedVariantConditions.isRequired;
                group.copy = usedVariantConditions.copy;
                group.origin = usedVariantConditions.origin;
                group.variantConditions = usedVariantConditions;

                this._addGroupToArray(appealSubserviceData, group, resultGroups);
            }
        }
    }

    /**
     * КНМ применимости группы в соответствии с настройками видов объектов в услуге (subKind)
     * @param group - обрабатываемая группа
     * @param requirements - условия обязательности из услуги
     * @param appealSubserviceData - описательный объект услуги в деле
     * @param resultGroups - массив групп документов
     */
    private _filterDocGroupsByService(group, requirements, appealSubserviceData, resultGroups) {
        if (!requirements || !appealSubserviceData.subjects) {
            return;
        }

        requirements.forEach((requirement) => {
            group.isRequired = requirement.isRequired;
            group.copy = requirement.copy;
            group.origin = requirement.origin;
            this._addGroupToArray(appealSubserviceData, group, resultGroups);

            // TODO выпилено по PPGMUD-1414 . Надо определить, нужно ли это
            /*if (!requirement.kindGuids || !requirement.kindGuids.length) {
                group.isRequired = requirement.isRequired;
                group.copy = requirement.copy;
                group.origin = requirement.origin;
                this._addGroupToArray(appealSubserviceData, group, resultGroups);

                return;
            }*/

            /*Object.keys(appealSubserviceData.subjects).forEach(subjectGuid => {
                const subject = appealSubserviceData.subjects[subjectGuid];

                if (!subject.subKind || requirement.kindGuids.indexOf(subject.subKind.guid) === -1) {
                    return;
                }

                group.isRequired = requirement.isRequired;
                group.copy = requirement.copy;
                group.origin = requirement.origin;
                this._addGroupToArray(appealSubserviceData, group, resultGroups);
            });*/
        });
    }

    /**
     * Определение применимости группы в соответсвии с настройками категорий участников
     * @param group - обрабатываемая группа
     * @param requirements - условия обязательности из услуги
     * @param appealSubserviceData - настройки услуги в деле
     * @param resultGroups - массив групп документов
     */
    private _filterDocGroupsByCategory(group, requirements, appealSubserviceData, resultGroups) {
        if (requirements && appealSubserviceData.subjects && appealSubserviceData.subjects.length > 0) {
            requirements.forEach((requirement) => {
                if (requirement.kindGuids) {
                    appealSubserviceData.subjects.forEach((subject) => {
                        if (subject.subKind && requirement.kindGuids.indexOf(subject.subKind.guid) !== -1) {
                            if (requirement.categoryGuids && requirement.categoryGuids.length > 0) {
                                let useDocument = false;
                                if (requirement.categoryGuids[0] === 'forAllCategories') {
                                    useDocument = true;
                                } else if (subject.category) {
                                    subject.category.selectedCategories.forEach((category) => {
                                        if (requirement.categoryGuids.indexOf(category.guid) !== -1) {
                                            useDocument = true;
                                        }
                                    });
                                }
                                if (useDocument) {
                                    group.isRequired = requirement.isRequired;
                                    group.copy = requirement.copy;
                                    group.origin = requirement.origin;
                                    this._addGroupToArray(appealSubserviceData, group, resultGroups, subject);
                                }
                            } else {
                                group.isRequired = requirement.isRequired;
                                group.copy = requirement.copy;
                                group.origin = requirement.origin;
                                this._addGroupToArray(appealSubserviceData, group, resultGroups, subject);
                            }
                        }
                    });
                }
            });
        }
    }

    /**
     * Определение применимости группы в соответсвии с настройками категорий объектов
     * @param group - обрабатываемая группа
     * @param requirements - условия обязательности из услуги
     * @param appealSubserviceData - настройки услуги в деле
     * @param resultGroups - массив групп документов
     */
    private _filterDocGroupsByObjectCategory(group, requirements, appealSubserviceData, resultGroups) {
        if (requirements && appealSubserviceData.subjects && appealSubserviceData.objects.length > 0) {
            requirements.forEach((requirement) => {
                if (requirement.kindGuids) {
                    appealSubserviceData.objects.forEach((object) => {
                        if (object.subKind && requirement.kindGuids.indexOf(object.subKind.guid) !== -1) {
                            if (requirement.categoryGuids && requirement.categoryGuids.length > 0) {
                                let useDocument = false;
                                if (object.category) {
                                    object.category.selectedCategories.forEach((category) => {
                                        if (requirement.categoryGuids.indexOf(category.guid) !== -1) {
                                            useDocument = true;
                                        }
                                    });
                                }
                                if (useDocument) {
                                    group.isRequired = requirement.isRequired;
                                    group.copy = requirement.copy;
                                    group.origin = requirement.origin;
                                    this._addGroupToArray(appealSubserviceData, group, resultGroups, null, object);
                                }
                            } else {
                                group.isRequired = requirement.isRequired;
                                group.copy = requirement.copy;
                                group.origin = requirement.origin;
                                this._addGroupToArray(appealSubserviceData, group, resultGroups, null, object);
                            }
                        }
                    });
                }
            });
        }
    }

    /**
     * Определение воможности добавления группы документов в состав групп для работы в деле
     * @param appealSubserviceData - данные услуги
     * @param group - обрабатываемая группа
     * @returns {boolean}
     */
    private checkAddGroup(appealSubserviceData, group) {
        if (!group.isOutput) {
            // Если документ входящий
            return true;
        } else if (
            appealSubserviceData.status &&
            appealSubserviceData.status.code !== 'draft' &&
            appealSubserviceData.status.code !== 'complete'
        ) {
            // Услуга не находится в статусе "Черновик" и "Завершено"
            return true;
        } else {
            const groupDocuments = this.appeal.documents.filter((item) => item.groupGuid === group.guid);
            if (groupDocuments.length > 0) {
                // Добавлены документы в дело
                return true;
            }
        }

        return false;
    }

    /**
     * Добавление группы в массив используемых групп документов
     * @param appealSubserviceData - информация о услуге внутри дела
     * @param group - обрабатываемая группа документов
     * @param resultGroups - результирующий массив документов
     * @param subject - субъект дела
     * @param object - субъект дела
     * @param principal - доверитель
     */
    private _addGroupToArray(
        appealSubserviceData,
        group,
        resultGroups,
        subject = null,
        object = null,
        principal = null
    ) {
        if (!this.checkAddGroup(appealSubserviceData, group)) {
            return;
        }

        // для подсчета участников и для обязательности документов для участников
        if (!this.objectTypeGroup[appealSubserviceData.guid]) {
            this.objectTypeGroup[appealSubserviceData.guid] = {};
        }

        if (!this.objectTypeGroup[appealSubserviceData.guid][group.guid]) {
            this.objectTypeGroup[appealSubserviceData.guid][group.guid] = [];
        }

        this.objectTypeGroup[appealSubserviceData.guid][group.guid].push({
            isRequired: group.isRequired,
            originals: group.origin,
            copies: group.copy,
            subjectGuid: subject ? subject.guid : null,
            objectGuid: object ? object.guid : null,
            principalGuid: principal ? principal.guid : null,
        });

        const find = resultGroups.find((item) => item.guid === group.guid);
        if (!find) {
            resultGroups.push(cloneDeep(group));
        }
    }

    /**
     * Корректировка параметров группы документов в зависимости от requirements c учетом activeAction
     * @param appealSubserviceData - настройки услуги дела
     * @param groups - массив групп услуги
     * @param appealActionPriority - приоритет действия дела при инициализации глобальной смены статуса из дела, а не услуги
     */
    private _correctGroupsByRequirements(appealSubserviceData, groups, appealActionPriority) {
        const additionalAppealSubserviceData = this.appealSubservicesService.data[appealSubserviceData.guid]; // описательные данные по услуге из сервиса
        const appealAction = this.appealStatusService.activeAction; // активное действие для дела
        const appealSubserviceAction = additionalAppealSubserviceData.activeAction; // активное действие для услуги дела
        const subservice = appealSubserviceData.subservice; // описательная услуга (из СПЭР)
        let activeAction = appealSubserviceAction; // Инициализация активного действия данными активного действия для услуги дела

        // Если у услуги нет активного действия, либо инициализировано глобальное действие из дела, ищем соответствующий action в статусной модели услуги
        if (appealAction && (!appealSubserviceAction || appealActionPriority)) {
            const currentStatus = this.appealStatusService.appeal.status;
            const currentStatusInSubserviceModel = subservice.statusModel.status.find(
                (item) => item.code === currentStatus.code
            );
            activeAction = currentStatusInSubserviceModel.actions.find((item) => item.code === appealAction.code);
        }

        // Определение параметров каждой группы из состава групп документов услуги

        groups.forEach((group) => {
            group.min = 0;
            group.appealRequirements = {};
            if (
                this.objectTypeGroup[appealSubserviceData.guid] &&
                this.objectTypeGroup[appealSubserviceData.guid][group.guid]
            ) {
                this.objectTypeGroup[appealSubserviceData.guid][group.guid].forEach((item) => {
                    if (!item.subjectGuid && !item.objectGuid) {
                        // Если группа не на участника (категорию), а на услугу
                        group.min = item.isRequired ? 1 : group.min;
                        group.appealRequirements.byService = {
                            required: item.isRequired
                                ? true
                                : group.appealRequirements.byService
                                  ? group.appealRequirements.byService.required
                                  : false,
                            originals:
                                item.originals > 0
                                    ? item.originals
                                    : group.appealRequirements.byService
                                      ? group.appealRequirements.byService.originals
                                      : 0,
                            copies:
                                item.copies > 0
                                    ? item.copies
                                    : group.appealRequirements.byService
                                      ? group.appealRequirements.byService.copies
                                      : 0,
                        };
                        if (activeAction && activeAction.documents) {
                            const find = activeAction.documents.find(
                                (actionDoc) => actionDoc.documentGroupGuid === group.guid
                            );
                            // Если документ не содержится в составе документов текущего этапа, то обязательность с группы снимается
                            if (!find) {
                                group.appealRequirements.byService.required = false;
                                group.isRequired = false;
                            }
                        }
                    } else {
                        // Если группа документов предоставляется на участника (на категорию), на представителя
                        const property = item.subjectGuid ? 'bySubjects' : 'byObjects';
                        const guidField = item.subjectGuid ? 'subjectGuid' : 'objectGuid';
                        if (!group.appealRequirements[property]) {
                            group.appealRequirements[property] = [];
                        }
                        group.min = item.isRequired ? group.min + 1 : group.min; // Если для объекта обязательно добавление, увеличивается на 1 минимальное значение документов в группе
                        // Формировании базовых параметров группы с разбивкой по объектам
                        if (!group.appealRequirements[property][item[guidField]]) {
                            group.appealRequirements[property][item[guidField]] = {};
                        }
                        group.appealRequirements[property][item[guidField]].required = item.isRequired; // обязательность
                        group.appealRequirements[property][item[guidField]].originals = item.originals; // число оригиналов
                        group.appealRequirements[property][item[guidField]].copies = item.copies; // число копий

                        // Добавление информации по доверителям для групп "на представителя"
                        if (item.principalGuid) {
                            if (!group.appealRequirements[property][item[guidField]].principals) {
                                group.appealRequirements[property][item[guidField]].principals = [];
                            }
                            group.appealRequirements[property][item[guidField]].principals.push({
                                guid: item.principalGuid,
                                required: item.isRequired,
                            }); // массив GUID-ов доверителей c указанием обязательности
                        }
                        // Если есть активное действие
                        if (activeAction) {
                            // Наличие в составе групп документов активного действия обрабатываемой группы
                            const find = activeAction.documents.find(
                                (actionDoc) => actionDoc.documentGroupGuid === group.guid
                            );
                            // Если документ не содержится в составе документов текущего этапа, то обязательность с документа снимается
                            if (!find) {
                                group.appealRequirements[property][item[guidField]].required = false;
                                group.isRequired = false;
                            }
                        }
                    }
                });
            }
        });
    }

    private _checkPrintFormDocsInAppealSubservices(docs) {
        let use = false;
        if (!(this.subservice._id === this.appeal.subservice.id && this.subservice.documentGroups)) {
            return use;
        }
        if (this.subservice._id !== this.appeal.subservice.id) {
            throw new Error('Сабсервис не найден');
        }

        this.subservice.documentGroups.forEach((group) => {
            docs.forEach((doc) => {
                if (doc.docGroupId === group.code) {
                    group.docs.forEach((groupDoc) => {
                        if (groupDoc.code === doc.docId) {
                            use = true;
                        }
                    });
                }
            });
        });

        return use;
    }

    /**
     * Фильтрация списка печатных форм, удаление из общего списка печатн
     * @param pF
     * @returns {Array}
     */
    public filterPrintForms(pF) {
        const printForms = [];
        const printFormsWithoutDocuments = [];
        pF.forEach((form) => {
            if (this.appeal.subservice) {
                if (form.forComplex || (form.docs && form.docs.length > 0)) {
                    printForms.push(form);
                }
                if (
                    (!form.docs ||
                        form.docs.length === 0 ||
                        (form.docs.length > 0 && !this._checkPrintFormDocsInAppealSubservices(form.docs))) &&
                    form.forComplex
                ) {
                    printFormsWithoutDocuments.push(form);
                }
            } else if (!form.forComplex) {
                printForms.push(form);
                if (
                    !form.docs ||
                    form.docs.length === 0 ||
                    (form.docs.length > 0 && !this._checkPrintFormDocsInAppealSubservices(form.docs))
                ) {
                    printFormsWithoutDocuments.push(form);
                }
            }
        });
        this.printForms = printForms;

        return {
            printForms,
            printFormsWithoutDocuments,
        };
    }

    /**
     * Подготовка документов для выбора при формировании печатной формы типа bringRegister, beforeIssued
     */
    public prepareDocumentsForSelectInModal(createdDocument) {
        const printFormTypeArr = createdDocument.printForm.formType.split('.');
        const documentsData = {};
        this.appeal.documents.forEach((document) => {
            if (document.guid !== createdDocument.guid && !document.deleted) {
                const subserviceGuid = document.subserviceGuid ? document.subserviceGuid : 'other';
                const groupGuid = document.groupGuid ? document.groupGuid : 'other';
                if (!documentsData[subserviceGuid]) {
                    documentsData[subserviceGuid] = {};
                }
                if (!documentsData[subserviceGuid][groupGuid]) {
                    documentsData[subserviceGuid][groupGuid] = {};
                }
                // Если печатная форма доноса, то автоматически подсвечиваем документ, если он добавлен в течении последнего часа
                let isDocumentSelected = false;
                if (
                    printFormTypeArr[0] === 'bringRegister' &&
                    (!document.dateLastModification ||
                        (document.dateLastModification &&
                            moment(document.dateLastModification) > moment().add(-1, 'h')))
                ) {
                    isDocumentSelected = true;
                }
                documentsData[subserviceGuid][groupGuid][document.guid] = {
                    guid: document.guid,
                    auid: document.auid,
                    name: document.name ? document.name : document.doc ? document.doc.name : '',
                    copies: document.copies ? document.copies : 0,
                    copiesSheets: document.copiesSheets ? document.copiesSheets : 0,
                    originalsSheets: document.originalsSheets ? document.originalsSheets : 0,
                    originals: document.originals ? document.originals : 0,
                    selected: isDocumentSelected,
                };
            }
        });
        let result = [];
        Object.keys(documentsData).forEach((subserviceGuid) => {
            const subserviceItem = {
                guid: subserviceGuid,
                title: this.appealSubservicesData[subserviceGuid]
                    ? this.appealSubservicesData[subserviceGuid].shortTitle
                    : '',
                hide: false,
                groups: [],
            };
            Object.keys(documentsData[subserviceGuid]).forEach((groupGuid) => {
                let groupName = 'Прочие документы';
                if (this.appealSubservicesData[subserviceGuid]) {
                    const findGroup = this.appealSubservicesData[subserviceGuid].appealDocumentGroups.find(
                        (item) => item.guid === groupGuid
                    );
                    if (findGroup) {
                        groupName = findGroup.name;
                    }
                }
                const group = {
                    guid: groupGuid,
                    name: groupName,
                    hide: false,
                    documents: [],
                };
                Object.keys(documentsData[subserviceGuid][groupGuid]).forEach((documentGuid) => {
                    group.documents.push(documentsData[subserviceGuid][groupGuid][documentGuid]);
                });
                subserviceItem.groups.push(group);
            });
            result.push(subserviceItem);
        });
        result = orderBy(result, ['guid']);

        return result;
    }

    /**
     * Получение выбранных документов для модального окна
     * @param groupsForModal
     * @returns {Array}
     */
    public getSelectedDocumentsForModal(groupsForModal) {
        const selectedDocuments = [];
        groupsForModal.forEach((subservice) => {
            subservice.groups.forEach((group) => {
                group.documents.forEach((document) => {
                    if (document.selected) {
                        selectedDocuments.push({
                            id: document.auid,
                            copies: document.copies ? document.copies : 0,
                            copiesSheets: document.copiesSheets ? document.copiesSheets : 0,
                            originalsSheets: document.originalsSheets ? document.originalsSheets : 0,
                            originals: document.originals ? document.originals : 0,
                        });
                    }
                });
            });
        });

        return selectedDocuments;
    }

    public checkFileIsRequestXml(document, file) {
        if (document.requestId) {
            const fileName = file.name;
            const arr = fileName.split('.');
            const fileExtension = arr[arr.length - 1].toLowerCase();

            return fileExtension === 'xml';
        }

        return false;
    }

    public calculateFileCount(document) {
        if (document.queue && document.queue.length > 0) {
            const filteredFiles = document.queue.filter((item) => {
                const isXml = this.checkFileIsRequestXml(document, item);

                return !isXml;
            });

            return filteredFiles.length;
        }

        return 0;
    }

    public selectDocumentKind(document, data, doc) {
        document.docGuid = doc.guid;
        data.doc = doc;
        if (doc.guid !== 'other') {
            document.docGuid = doc.guid;
            document.name = doc.name;
            document.code = doc.code;
            document.description = doc.description;
            data.doc = doc;
            data.name = doc.name;
            data.sendToSed = doc.sendToSed;
            if (doc.typeForFinishTask) {
                data.typeForFinishTask = doc.typeForFinishTask;
            }
            if (doc.printForms) {
                data.printForms = doc.printForms;
            }
            if (doc.xsd) {
                data.xsd = doc.xsd;
            }
            if (doc.requestId) {
                document.requestId = doc.requestId;
                data.requestId = doc.requestId;
                if (doc.isForwardingRequest) {
                    document.isForwardingRequest = doc.isForwardingRequest;
                    data.isForwardingRequest = doc.isForwardingRequest;
                }

                data.envelope = {
                    guid: CommonUtilities.GenerateGuid(),
                    documentGuid: document.guid,
                    subserviceId: document.subserviceId,
                    subserviceGuid: document.subserviceGuid,
                    object: null,
                    status: 'created',
                    unit: {
                        id: this.currentOrganization._id,
                        name: this.currentOrganization.name,
                    },
                    appealDocuments: [],
                };
                if (this.appeal._id) {
                    data.appealId = this.appeal._id;
                }
                if (this.appeal.shortNumber) {
                    data.envelope.appealShortNumber = this.appeal.shortNumber;
                    data.envelope.toIndex = this.appeal.toIndex;
                }
                if (this.appeal.toIndex) {
                    data.envelope.toIndex = this.appeal.toIndex;
                }
                this.calculateDocumentPermissions(document.guid);
            }
            if (doc.xsdLink) {
                data.xsd = doc.xsdLink.xsd;
                data.xsdRequired = doc.xsdLink.required;
            }
        }
    }

    public checkTemplatesEnabled(documentKind) {
        return documentKind === SED_PRAKTIKA_DOCUMENT_NAME || documentKind === NEXT_SED_PRAKTIKA_DOCUMENT_VERSION_NAME;
    }

    public verifySignatures(appealId: string, groupGuid: string) {
        this.toaster.success('Запустился процесс проверки приложенных ЭП');
        return this.httpService.get(Config.server + Config.api + 'smev-client/signature/verify?appealId=' + appealId + '&groupGuid=' + groupGuid).then(
            (result: any) => {
                this.toaster.info('Процесс проверки приложенных ЭП завершен');
            },
            error => {
                this.toaster.info('Процесс проверки приложенных ЭП завершен');
            },
        );
    }

    /**
     * Переинициализация дела, после проверки приложенных ЭП
     */
    public async initDataAfterSignVerification() {
        this.appeal = await this.rest.find('appeals', this.appeal._id);

        // Инициализация услуг дела
        this.reInitSubserviceData();

        // Корректировка набора групп документов для услуг
        this.correctSubserviceDocGroups();

        // Определение наличия ПФ для видов документов в первичных услугах
        this._defineDocumentsPrintForms(this.subservice.documentGroups);

        // Услуга и группа для документа
        if (this.appeal.documents) {
            const docs = [];
            this.appeal.documents.forEach(async document => {
                await this.initDocumentDataFromAppeal(document);
                const documentData = this.data[document.guid];
                docs.push(documentData);
            });
            this.appeal.tmpDocs = docs;
        }
    }
}
