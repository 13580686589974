import { Injectable } from '@angular/core';
import { StorageService } from '@evolenta/core';
import { PrintUtilities } from '@evolenta/utilities';
import { PrintUtilityService } from './print-utility.service';
import isEmpty from 'lodash-es/isEmpty';
import get from 'lodash-es/get';

@Injectable()
export class PrintObjectsService {
    public constructor(private printUtilityService: PrintUtilityService, private storage: StorageService) {}

    /**
     * Формирование полей ФИО Участника
     * @param object - обрабатываемый объект дела
     * @returns - объект с параметрами ФИО
     */
    public preparePersonIdentity(object) {
        const fio = [];
        if (object.data.person.lastName) {
            fio.push(object.data.person.lastName);
        }
        if (object.data.person.firstName) {
            fio.push(object.data.person.firstName);
        }
        if (object.data.person.middleName) {
            fio.push(object.data.person.middleName);
        }
        return {
            FullName: fio.join(' '),
            FirstName: object.data.person.firstName,
            LastName: object.data.person.lastName,
            Patronymic: object.data.person.middleName,
            RodFam: object.data.person.genitiveLastName,
            RodName: object.data.person.genitiveFirstName,
            RodPat: object.data.person.genitiveMiddleName,
            DatFam: object.data.person.dativeLastName,
            DatName: object.data.person.dativeFirstName,
            DatPat: object.data.person.dativeMiddleName,
            VinFam: object.data.person.accusativeLastName,
            VinName: object.data.person.accusativeFirstName,
            VinPat: object.data.person.accusativeMiddleName,
            TvorFam: object.data.person.instrumentalLastName,
            TvorName: object.data.person.instrumentalFirstName,
            TvorPat: object.data.person.instrumentalMiddleName,
            PredFam: object.data.person.prepositionalLastName,
            PredName: object.data.person.prepositionalFirstName,
            PredPat: object.data.person.prepositionalMiddleName,
        };
    }

    /**
     * Формирует блок с контактными данными: email + телефоны
     * @param object - обрабатываемый объект
     * @param forUl - для юридического лица
     * @returns {any} - объект с контактными данными
     */
    public generatePersonContacts(object, forUl = false) {
        const obj: any = {};
        const phones = [];
        if (object.data) {
            const source = forUl ? object.data.organization : object.data.person;
            if (source) {
                if (source.email) {
                    obj.Email = object.data.person.email;
                }
                if (source.mobile) {
                    phones.push({ _type: 'm', __text: source.mobile });
                }
                if (source.phone) {
                    phones.push({ _type: 'h', __text: source.phone });
                }
                if (source.workPhone) {
                    phones.push({ _type: 'w', __text: source.workPhone });
                }
                if (phones.length > 0) {
                    obj.Phone = phones;
                }
                return obj;
            }
        }
        return '';
    }

    /**
     * Формирует параметры документа удостоверяющего личность
     * @param object - обрабатываемый объект
     * @returns - параметры документа
     */
    public preparePersonIdentityDocument(object) {
        const catalogueDocuments = this.storage.getItem('catalogueDocuments');
        let mnemonic = null;
        if (object.data.person && object.data.person.documentType && object.data.person.documentType[0]) {
            const document = catalogueDocuments.find(item => item.code === object.data.person.documentType[0].id);
            mnemonic = document && document.mnemonic ? document.mnemonic : null;
        }
        return {
            DocName: object.data.person.documentType ? object.data.person.documentType[0].text : null,
            Mnemonic: mnemonic,
            DocSeries: object.data.person.documentSeries,
            DocNumber: object.data.person.documentNumber,
            DocIssuer: object.data.person.documentIssuer ? object.data.person.documentIssuer.name : null,
            DocIssueDate: object.data.person.documentIssueDate
                ? this.printUtilityService.formatDateForPrintForm(object.data.person.documentIssueDate)
                : null,
            DocType: object.data.person.documentType ? object.data.person.documentType[0].id : null,
            CodeBranch: object.data.person.documentIssuer ? object.data.person.documentIssuer.code : null, // код подразделения, выдавшего документ
            Citizenship: object.data.person.citizenship ? object.data.person.citizenship.name : null, // гражданство Россия
            CitizenshipCode: object.data.person.citizenship ? object.data.person.citizenship.code : null, // гражданство, код RUS
        };
    }

    /**
     * Формирует информацию по объектам (участникам) дела
     * @param appeal - обрабатываемое дело
     * @param service - услуга по которой создано дело (в случае отсутствия параметра - комплексная услуга)
     * @param appealSubservice - информация по услуге в деле
     * @returns {Array}
     */
    public prepareAppealPersons(appeal, service = null, appealSubservice = null) {
        const persons = [];
        appeal.subjects.forEach(subject => {
            const person: any = {
                _id: subject.guid,
                Identity: subject.data.person ? this.preparePersonIdentity(subject) : '',
                IdentityDocument: subject.data.person ? this.preparePersonIdentityDocument(subject) : '',
                RegistrationAddress: subject.data.person
                    ? PrintUtilities.PrintAddressAsLine(subject.data.person.registrationAddress)
                    : '',
                RegAddress: subject.data.person
                    ? PrintUtilities.PrintAddressAsObject(subject.data.person.registrationAddress)
                    : '',
                RegAddr: subject.data.person
                    ? PrintUtilities.PrintAddressAsStructure(subject.data.person.registrationAddress)
                    : '',
                TempRegistrationAddress: subject.data.person
                    ? PrintUtilities.PrintAddressAsLine(subject.data.person.tempRegistrationAddress)
                    : '',
                TempRegAddress: subject.data.person
                    ? PrintUtilities.PrintAddressAsObject(subject.data.person.tempRegistrationAddress)
                    : '',
                TempRegAddr: subject.data.person
                    ? PrintUtilities.PrintAddressAsStructure(subject.data.person.tempRegistrationAddress)
                    : '',
                ActualAddress: subject.data.person
                    ? PrintUtilities.PrintAddressAsLine(subject.data.person.factAddress)
                    : '',
                ActAddress: subject.data.person
                    ? PrintUtilities.PrintAddressAsObject(subject.data.person.factAddress)
                    : '',
                ActAddr: subject.data.person
                    ? PrintUtilities.PrintAddressAsStructure(subject.data.person.factAddress)
                    : '',
                BirthPlace: subject.data.person
                    ? PrintUtilities.PrintAddressAsLine(subject.data.person.birthPlace)
                    : '',
                RegAddressIsDifferFactAddress: !!(
                    subject.data &&
                    subject.data.person &&
                    subject.data.person.isDifferFactAddress
                ),
                RegAddressIsDifferTempRegAddress: !!(
                    subject.data &&
                    subject.data.person &&
                    subject.data.person.isDifferTempRegistrationAddress
                ),
                BirthCountryName:
                    subject.data.person && subject.data.person.birthPlace && subject.data.person.birthPlace.country
                        ? subject.data.person.birthPlace.country
                        : '',
                BirthCountryCode: null,
                BirthAddress: subject.data.person
                    ? PrintUtilities.PrintAddressAsObject(subject.data.person.birthPlace)
                    : '',
                BirthAddr: subject.data.person
                    ? PrintUtilities.PrintAddressAsStructure(subject.data.person.birthPlace)
                    : '',
                Contacts: this.generatePersonContacts(subject, subject.specialTypeId === 'ulApplicant'),
                ActsForHimself: false, // действует за себя
                IsApplicant: true, // является заявителем
                Sex:
                    subject.data.person && subject.data.person.sex === 'male'
                        ? 'Мужской'
                        : subject.data.person && subject.data.person.sex === 'female'
                        ? 'Женский'
                        : '',
                ParticipatesInPhoneSurveys: !!subject.agreeMkguInterview,
                IsIndividualEntrepreneur: false, // флаг. является ИП
                KindParticipation: null, // вид участия
                AgentType: {}, // тип представителя?
                RepresentativeID: null, // id участника - являющегося представителем.
                EIN: null, // ?
                Authority: null, // документ удостоверяющий право представителя, текстовое поле в АИС МФЦ
                PersonalAccount: null, // ?
                INN: subject.data.person && subject.data.person.inn ? subject.data.person.inn : null,
                SNILS: subject.data.person && subject.data.person.snils ? subject.data.person.snils : null,
                KFH: false,
                BirthDate: subject.data.person
                    ? this.printUtilityService.formatDateForPrintForm(subject.data.person.birthday)
                    : '',
                CategoryName: '',
                CategoryId: '',
                ParentableReceiverCategory: null,
                DeliveryResultId: 1, // что это? //способ получния результата 1 - лично, 2 - по почте, 3 -  ?
                DeliveryResultName: 'лично',
                RegistrationDate: this.printUtilityService.formatDateForPrintForm(appeal.dateRegister),
                Objects: null,
            };

            if (subject.messaging) {
                const messaging = [];
                subject.messaging.forEach(item => {
                    messaging.push({
                        Code: item.code,
                        Name: item.name,
                    });
                });
                person.Messaging = { MessagingItem: messaging };
            }

            person.Data = subject.data;

            if (subject.xsdData) {
                person.XsdData = subject.xsdData;
            }

            // Если дело создается по одной услуге
            if (service) {
                const subjectDataInAppealSubservice = appealSubservice.subjects.find(
                    item => item.guid === subject.guid,
                );
                if (subjectDataInAppealSubservice) {
                    if (
                        subjectDataInAppealSubservice.subKind &&
                        subjectDataInAppealSubservice.subKind.type.indexOf('Ip') !== -1
                    ) {
                        person.IsIndividualEntrepreneur = true;
                    }
                    if (
                        subjectDataInAppealSubservice.representative &&
                        subjectDataInAppealSubservice.representative.guid
                    ) {
                        person.AgentType = subjectDataInAppealSubservice.representative.type
                            ? {
                                  _code: subjectDataInAppealSubservice.representative.type.id,
                                  __text: subjectDataInAppealSubservice.representative.type.name,
                              }
                            : {};
                        person.Authority = subjectDataInAppealSubservice.representative.authority;
                        person.IsComplexAuthority = !!subjectDataInAppealSubservice.representative.isComplexAuthority;
                        if (
                            subjectDataInAppealSubservice.representative.isComplexAuthority &&
                            subjectDataInAppealSubservice.representative.complexAuthority
                        ) {
                            person.ComplexAuthority = {
                                Name: subjectDataInAppealSubservice.representative.complexAuthority.name,
                                Series: subjectDataInAppealSubservice.representative.complexAuthority.series,
                                Number: subjectDataInAppealSubservice.representative.complexAuthority.number,
                                Issuer: subjectDataInAppealSubservice.representative.complexAuthority.issuer,
                                IssueDate: subjectDataInAppealSubservice.representative.complexAuthority.issueDate
                                    ? this.printUtilityService.formatDateForPrintForm(
                                          subjectDataInAppealSubservice.representative.complexAuthority.issueDate,
                                      )
                                    : '',
                                ActualDate: subjectDataInAppealSubservice.representative.complexAuthority.beforeDate
                                    ? this.printUtilityService.formatDateForPrintForm(
                                          subjectDataInAppealSubservice.representative.complexAuthority.beforeDate,
                                      )
                                    : '',
                            };
                        }
                        person.RepresentativeID = subjectDataInAppealSubservice.representative.guid;
                    }
                    if (
                        subjectDataInAppealSubservice.subKind &&
                        subjectDataInAppealSubservice.subKind.type.indexOf('applicant') !== -1
                    ) {
                        person.IsApplicant = true;
                        person.ActsForHimself = true;
                        person.KindParticipation = 1;
                    } else if (
                        subjectDataInAppealSubservice.subKind &&
                        subjectDataInAppealSubservice.subKind.type.indexOf('principal') !== -1
                    ) {
                        person.IsApplicant = false;
                        person.ActsForHimself = false;
                        person.KindParticipation = 3;
                    } else if (
                        subjectDataInAppealSubservice.subKind &&
                        subjectDataInAppealSubservice.subKind.type.indexOf('third') !== -1
                    ) {
                        person.ActsForHimself = false;
                        person.IsApplicant = false;
                        person.KindParticipation = 4;
                    }

                    // Категория участника
                    if (subjectDataInAppealSubservice.category) {
                        person.CategoryName = subjectDataInAppealSubservice.category.name;
                        person.CategoryId = this.calculateCategorySperCode(subjectDataInAppealSubservice, service);
                        person.ParentableReceiverCategory = this.generateObjectCategoriesStructure(
                            subjectDataInAppealSubservice,
                            service,
                        );
                    }

                    if (subjectDataInAppealSubservice.xsdData) {
                        person.XmlData = {};
                        if (subjectDataInAppealSubservice.xsdRootTag) {
                            person.XmlData[subjectDataInAppealSubservice.xsdRootTag] =
                                subjectDataInAppealSubservice.xsdData;
                        } else {
                            person.XmlData = subjectDataInAppealSubservice.xsdData;
                        }
                    }
                } else {
                    // Если объект не найден как основной участник, ищем его в качестве представителя
                    const objectWithRepresentative = appeal.subservice.objects.find(
                        item => item.representative && item.representative.guid === subject.guid,
                    );
                    if (objectWithRepresentative) {
                        person.IsApplicant = true;
                        person.KindParticipation = 2;
                        person.ActsForHimself = false;
                    }
                }
            }

            if (subject.specialTypeId === 'ulApplicant' && subject.data.organization) {
                person.Organization = {
                    Name: subject.data.organization.name,
                    Authority: subject.data.personInOrg ? subject.data.personInOrg.authority : '',
                    ShortName: subject.data.organization.shortName,
                    OGRN: subject.data.organization.ogrn,
                    OKOPF: subject.data.organization.opf ? subject.data.organization.opf.name : '',
                    INN: subject.data.organization.inn,
                    KPP: subject.data.organization.kpp,
                    OKPO: subject.data.organization.okpo ? subject.data.organization.okpo : '',
                    ActualAddress: PrintUtilities.PrintAddressAsLine(subject.data.organization.registrationAddress),
                    ActAddress: PrintUtilities.PrintAddressAsObject(subject.data.organization.registrationAddress),
                    ActAddr: PrintUtilities.PrintAddressAsStructure(subject.data.organization.registrationAddress),
                    RegistrationAddress: PrintUtilities.PrintAddressAsLine(
                        subject.data.organization.registrationAddress,
                    ),
                    RegAddress: PrintUtilities.PrintAddressAsObject(subject.data.organization.registrationAddress),
                    RegAddr: PrintUtilities.PrintAddressAsStructure(subject.data.organization.registrationAddress),
                    Position: subject.data.personInOrg ? subject.data.personInOrg.position : '',
                };
            }
            if (subject.specialTypeId === 'ipApplicant') {
                person.IsIndividualEntrepreneur = true;
                person.Organization = {
                    OGRN: subject.data.person && subject.data.person.ogrn ? subject.data.person.ogrn : '',
                };
            }
            if (subject.specialTypeId === 'foreignUlApplicant') {
                person.Organization = {
                    Name: subject.data.organization.name,
                    ShortName: subject.data.organization.shortName,
                    INN: subject.data.organization.inn,
                    AccreditationNumber: subject.data.organization.accreditationNumber,
                    AccreditationDate: this.printUtilityService.formatDateForPrintForm(
                        subject.data.organization.accreditationDate,
                    ),
                    RegistrationAddress: PrintUtilities.PrintAddressAsLine(
                        subject.data.organization.registrationAddress,
                    ),
                    RegAddress: PrintUtilities.PrintAddressAsObject(subject.data.organization.registrationAddress),
                    RegAddr: PrintUtilities.PrintAddressAsStructure(subject.data.organization.registrationAddress),
                };
                person.IsForeignOrganization = true;
            }
            persons.push(person);
        });

        return persons;
    }

    /**
     *  Формирует информацию по объекту (участнику) дела
     *
     * @param subject обрабатываем объект (участник)
     */
    public preparePerson(subject: any): object {
        let person: any = {
            _id: get(subject, '_id', 0),
        };

        if (!isEmpty(get(subject, 'data.person', null))) {
            // Это надо переделать, обернуть Person : {...}, как Organization, после того как сделают нормальные шаблоны
            person = {
                ...person,
                ...{
                    Identity: subject.data.person ? this.preparePersonIdentity(subject) : '',
                    IdentityDocument: subject.data.person ? this.preparePersonIdentityDocument(subject) : '',
                    RegistrationAddress: PrintUtilities.PrintAddressAsLine(
                        get(subject, 'data.person.registrationAddress', ''),
                    ),
                    RegAddress: PrintUtilities.PrintAddressAsObject(
                        get(subject, 'data.person.registrationAddress', ''),
                    ),
                    RegAddr: PrintUtilities.PrintAddressAsStructure(get(subject, 'data.person.registrationAddress')),
                    TempRegistrationAddress: PrintUtilities.PrintAddressAsLine(
                        get(subject, 'data.person.tempRegistrationAddress', ''),
                    ),
                    TempRegAddress: PrintUtilities.PrintAddressAsObject(
                        get(subject, 'data.person.tempRegistrationAddress', ''),
                    ),
                    TempRegAddr: PrintUtilities.PrintAddressAsStructure(
                        get(subject, 'data.person.tempRegistrationAddress', ''),
                    ),
                    ActualAddress: PrintUtilities.PrintAddressAsLine(get(subject, 'data.person.factAddress', '')),
                    ActAddress: PrintUtilities.PrintAddressAsObject(get(subject, 'data.person.factAddress', '')),
                    ActAddr: PrintUtilities.PrintAddressAsStructure(get(subject, 'data.person.factAddress', '')),
                    BirthPlace: PrintUtilities.PrintAddressAsLine(get(subject, 'data.person.birthPlace', '')),
                    RegAddressIsDifferFactAddress: !!get(subject, 'data.person.isDifferFactAddress', ''),
                    RegAddressIsDifferTempRegAddress: !!get(subject, 'data.person.isDifferTempRegistrationAddress', ''),
                    BirthCountryName: get(subject, 'data.person.birthPlace.country', ''),
                    BirthAddress: PrintUtilities.PrintAddressAsObject(get(subject, 'data.person.birthPlace', '')),
                    BirthAddr: PrintUtilities.PrintAddressAsStructure(get(subject, 'data.person.birthPlace', '')),
                    Contacts: subject.data.person ? this.generatePersonContacts(subject) : '',
                    ActsForHimself: false, // действует за себя
                    IsApplicant: true, // является заявителем
                    Sex:
                        subject.data.person && subject.data.person.sex === 'male'
                            ? 'Мужской'
                            : subject.data.person && subject.data.person.sex === 'female'
                            ? 'Женский'
                            : '',
                    ParticipatesInPhoneSurveys: !!subject.agreeMkguInterview,
                    IsIndividualEntrepreneur: get(subject, 'specialTypeId') === 'ipApplicant', // флаг. является ИП
                    INN: get(subject, 'data.person.inn', ''),
                    SNILS: get(subject, 'data.person.snils', ''),
                    KFH: false,
                    BirthDate: get(subject, 'data.person.birthday.formatted', ''),
                    RegistrationDate: this.printUtilityService.formatDateForPrintForm(get(subject, 'dateRegister', '')),
                },
            };

            if (get(subject, 'specialTypeId') === 'ipApplicant') {
                person.OGRN = get(subject, 'data.person.ogrn', '');
            }
        }

        if (!isEmpty(get(subject, 'data.organization', null))) {
            person.Organization = {
                Name: get(subject, 'data.organization.name', ''),
                Authority: get(subject, 'data.personInOrg.authority', ''),
                ShortName: get(subject, 'data.organization.shortName', ''),
                OGRN: get(subject, 'data.organization.ogrn', ''),
                OKOPF: get(subject, 'data.organization.opf.name', ''),
                INN: get(subject, 'data.organization.inn', ''),
                KPP: get(subject, 'data.organization.kpp', ''),
                OKPO: get(subject, 'data.organization.okpo', ''),
                ActualAddress: PrintUtilities.PrintAddressAsLine(
                    get(subject, 'data.organization.registrationAddress', ''),
                ),
                ActAddress: PrintUtilities.PrintAddressAsObject(
                    get(subject, 'data.organization.registrationAddress', ''),
                ),
                ActAddr: PrintUtilities.PrintAddressAsStructure(
                    get(subject, 'data.organization.registrationAddress', ''),
                ),
                RegistrationAddress: PrintUtilities.PrintAddressAsLine(
                    get(subject, 'data.organization.registrationAddress', ''),
                ),
                RegAddress: PrintUtilities.PrintAddressAsObject(
                    get(subject, 'data.organization.registrationAddress', ''),
                ),
                RegAddr: PrintUtilities.PrintAddressAsStructure(
                    get(subject, 'data.organization.registrationAddress', ''),
                ),
                Position: get(subject, 'data.personInOrg.position', ''),
                OrganizationalForm: get(subject, 'data.organization.organizationalForm', ''),
            };
        }

        return person;
    }

    /**
     * Формирует блок выбранных категорий для объектов
     * @param objectData - данные объекта в услуге дела
     * @param service - услуга
     * @returns {{ParentableReceiverCategory: any}}
     */
    public generateObjectCategoriesStructure(objectData, service) {
        let categories;
        service.objects.objectKinds.forEach(kind => {
            if (kind.subKinds) {
                kind.subKinds.forEach(subKind => {
                    if (objectData.subKind && subKind.guid === objectData.subKind.guid) {
                        categories = this.generateObjectCategoryNode(
                            JSON.parse(JSON.stringify(objectData.category.selectedCategories)),
                            subKind.category,
                        );
                    }
                });
            }
        });
        return categories;
    }

    /**
     * Циклически формирует структуру выбранных категорий для участника
     * @param objectCategories - выбранные категории
     * @param serviceCategories - массив категорий в услуге
     * @returns {any}
     */
    public generateObjectCategoryNode(objectCategories, serviceCategories) {
        const data: any = {};
        serviceCategories.forEach(category => {
            if (objectCategories[0] && category.guid === objectCategories[0].guid) {
                data.CategoryId = category.code;
                data.CategoryName = category.name;
                data.Mnemonic = category.mnemonic ? category.mnemonic : null;
                if (category.subCategory.length > 0) {
                    data.ParentableReceiverCategory = this.generateObjectCategoryNode(
                        objectCategories.slice(1),
                        category.subCategory,
                    );
                }
            }
        });
        return data;
    }

    /**
     * Определение Кода СПЭР для объекта дела
     * @param objectData - данные объекта в услуге
     * @param service
     * @returns {any}
     */
    public calculateCategorySperCode(objectData, service) {
        let code = null;
        service.objects.objectKinds.forEach(kind => {
            if (kind.subKinds) {
                kind.subKinds.forEach(subKind => {
                    if (subKind.guid === objectData.subKind.guid) {
                        code = this.getCategorySperCode(objectData.category, subKind.category);
                    }
                });
            }
        });
        return code;
    }

    /**
     * Получение кода категории из СПЭР
     * @param category - выбранная категория
     * @param categories - массив категорий
     * @returns {any}
     */
    public getCategorySperCode(category, categories) {
        let code = null;
        categories.forEach(item => {
            if (item.guid === category.guid) {
                code = item.code;
            } else if (item.subCategory && item.subCategory.length > 0) {
                const codeValue = this.getCategorySperCode(category, item.subCategory);
                code = codeValue ? codeValue : code;
            }
        });
        return code;
    }

    /**
     * Формирование информации по настройке объектов в услуге
     * @param appealServiceData - блок настройки услуги в деле
     * @param service - услуга
     */
    public prepareObjectsInServiceData(appealServiceData, service) {
        const objects = [];
        if (appealServiceData.objects && appealServiceData.objects.length > 0) {
            appealServiceData.objects.forEach(objectData => {
                objects.push(this.prepareObjectInServiceData(objectData, service));
            });
        }
        return objects;
    }

    /**
     * Формирование информации по настройке отдельного объекта в услуге
     * @param objectData - блок настройки услуги в деле
     * @param service - услуга
     */
    public prepareObjectInServiceData(objectData, service) {
        const data: any = {
            ID: objectData.guid,
            CategoryName: objectData.category ? objectData.category.name : '',
            CategoryId: objectData.category ? this.calculateCategorySperCode(objectData, service) : '', // id категории участника в спер
            ParentableReceiverCategory: service ? this.generateObjectCategoriesStructure(objectData, service) : null,
            IsIndividualEntrepreneur: false,
            IsApplicant: true,
            ActsForHimself: true,
            KindParticipation: 1,
        };
        if (objectData.subKind && objectData.subKind.type.indexOf('Ip') !== -1) {
            data.IsIndividualEntrepreneur = true;
        }
        if (objectData.representative) {
            data.AgentType = {
                _code: objectData.representative.type.id,
                __text: objectData.representative.type.name,
            };
            data.Authority = objectData.representative.authority;
            data.RepresentativeID = objectData.representative.guid;
        }
        if (objectData.subKind && objectData.subKind.type.indexOf('principal') !== -1) {
            data.IsApplicant = false;
            data.ActsForHimself = false;
            data.KindParticipation = 3;
        } else if (objectData.subKind && objectData.subKind.type.indexOf('third') !== -1) {
            data.ActsForHimself = false;
            data.IsApplicant = false;
            data.KindParticipation = 4;
        } else {
            data.IsApplicant = true;
            data.ActsForHimself = true;
            data.KindParticipation = 1;
        }

        return data;
    }

    public prepareAppealObjects(appeal, service = null, appealSubservice = null) {
        const objects = [];
        appeal.objects.forEach(object => {
            const data: any = {
                _id: object.guid,
                Name: object.name,
                CadastralNumber: object.cadastralNumber,
                Address: object.address ? PrintUtilities.PrintAddressAsLine(object.address) : '',
                AddressObject: object.address ? PrintUtilities.PrintAddressAsObject(object.address) : '',
                AddressStructure: object.address ? PrintUtilities.PrintAddressAsStructure(object.address) : '',
            };
            objects.push(data);
        });
        return objects;
    }
}
