<div class="p-20">
    <ul class="info-row mb-15 no-margin-left no-margin-right">
        <ng-container *ngFor="let tab of tabs">
            <li (click)="activeTab = tab.code" [ngClass]="{ active: activeTab == tab.code }">
                {{ tab.name }}
            </li>
        </ng-container>
    </ul>
    <div class="panel panel-shadow">
        <div class="panel-body">
            <ng-container *ngIf="activeTab === 'common'">
                <div class="form-group form-group-lg">
                    <label class="text-uppercase text-bold">
                        {{ localizations.standards.licence_activity_kind }}
                    </label>
                    <ng-select
                        [allowClear]="!isRegistryTypeRequired"
                        [items]="registryTypes"
                        [active]="getActiveRegistryType()"
                        [multiple]="false"
                        [placeholder]="localizations.common.select_value"
                        (selected)="selectRegistryType($event)"
                        (removed)="selectRegistryType(null)"
                        *ngIf="isAllowEditMainElement"
                    >
                    </ng-select>
                    <div *ngIf="!isAllowEditMainElement">
                        <em class="text-muted" *ngIf="!savedPlace.registryType">{{
                            localizations.common.not_selected_small
                        }}</em>
                        <span class="text-slate text-bold" *ngIf="savedPlace.registryType">{{
                            savedPlace.registryType.name
                        }}</span>
                    </div>
                </div>

                <div *ngIf="isLicenseTypeEnabled" class="form-group form-group-lg">
                    <label class="text-uppercase text-bold">
                        {{ localizations.standards.licence_record_type }}
                    </label>
                    <ng-select
                        [items]="licenseRecordTypes"
                        idField="code"
                        textField="name"
                        [active]="getActiveLicenseRecordType()"
                        [multiple]="false"
                        [placeholder]="localizations.common.select_value"
                        (selected)="selectLicenseRecordType($event)"
                        (removed)="selectLicenseRecordType(null)"
                        *ngIf="isAllowEditMainElement"
                    >
                    </ng-select>
                    <div *ngIf="!isAllowEditMainElement">
                        <em class="text-muted" *ngIf="!savedPlace.licenseRecordType">{{
                            localizations.common.not_selected_small
                        }}</em>
                        <span class="text-slate text-bold" *ngIf="savedPlace.licenseRecordType">{{
                            savedPlace.licenseRecordType.name
                        }}</span>
                    </div>
                </div>

                <ng-container *ngIf="metaData.operationTypes">
                    <fieldset>
                        <legend class="text-bold no-margin">
                            {{ localizations.standards.operation_kind }}
                        </legend>
                        <div class="radio-block" *ngFor="let operation of metaData.operationTypes">
                            <input
                                id="operationType{{ operation.code }}"
                                class="radio"
                                type="radio"
                                name="operationType"
                                [checked]="
                                    savedPlace &&
                                    savedPlace.operationType &&
                                    savedPlace.operationType.code === operation.code
                                "
                                (change)="savedPlace.operationType = operation"
                            />
                            <label for="operationType{{ operation.code }}">
                                {{ operation.name }}
                            </label>
                        </div>
                    </fieldset>
                </ng-container>

                <hr />
                <div class="checkbox-inline">
                    <input
                        id="selectLicense"
                        class="checkbox"
                        type="checkbox"
                        [checked]="savedPlace.selectLicense"
                        (change)="savedPlace.selectLicense = !savedPlace.selectLicense"
                    />
                    <label for="selectLicense">
                        {{ localizations.standards.selecting_license }}
                    </label>
                </div>
                <div class="checkbox-inline" *ngIf="savedPlace.selectLicense">
                    <input
                        id="selectMultipleLicenses"
                        class="checkbox"
                        type="checkbox"
                        [checked]="savedPlace.selectMultipleLicenses"
                        (change)="savedPlace.selectMultipleLicenses = !savedPlace.selectMultipleLicenses"
                    />
                    <label for="selectMultipleLicenses">
                        {{ localizations.standards.multiple_licenses_selection }}
                    </label>
                </div>
                <div class="checkbox-inline" *ngIf="metaData.useOtherRegionCheck">
                    <input
                        id="useOtherRegionCheck"
                        class="checkbox"
                        type="checkbox"
                        [checked]="savedPlace.useOtherRegionCheck"
                        (change)="savedPlace.useOtherRegionCheck = !savedPlace.useOtherRegionCheck"
                    />
                    <label for="useOtherRegionCheck">
                        {{ localizations.standards.using_check_by_region }}
                    </label>
                </div>

                <div class="checkbox-inline">
                    <input
                        id="saveOperationsHistoryInLicense"
                        class="checkbox"
                        type="checkbox"
                        [checked]="savedPlace.saveOperationsHistoryInLicense"
                        (change)="
                            savedPlace.saveOperationsHistoryInLicense = !savedPlace.saveOperationsHistoryInLicense
                        "
                    />
                    <label for="saveOperationsHistoryInLicense">
                        {{ localizations.standards.save_operations_history_in_license }}
                    </label>
                </div>

                <div class="form-group no-margin-bottom mt-20" *ngIf="savedPlace.selectLicense">
                    <label>
                        {{ localizations.standards.license_kind_handler }}
                    </label>
                    <evolenta-handlers
                        field="selectLicense"
                        [model]="savedPlace"
                        [setEndEvent]="true"
                    ></evolenta-handlers>
                </div>

                <div class="form-group form-group-lg mt-20">
                    <label>
                        {{ localizations.standards.url_for_registry_qr }}
                    </label>
                    <input type="text" class="form-control" [(ngModel)]="savedPlace.registryUrlForQR" />
                </div>

                <fieldset>
                    <legend class="text-bold mb-10">
                        {{ localizations.standards.name_for_license }}
                    </legend>
                    <div>
                        <div class="row">
                            <div class="col-md-4" *ngFor="let item of titles">
                                <div class="form-group form-group-lg">
                                    <label>
                                        {{ item.name }}
                                    </label>
                                    <input
                                        class="form-control"
                                        type="text"
                                        [(ngModel)]="savedPlace.titles[item.code]"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </ng-container>

            <!--  Параметры реестровой записи  -->
            <div *ngIf="activeTab == 'registryItem'">
                <div class="checkbox-block no-padding mb-20">
                    <input
                        type="checkbox"
                        class="checkbox"
                        id="useChecksTab"
                        [checked]="savedPlace.registryParams.useChecksTabs"
                        (change)="savedPlace.registryParams.useChecksTabs = !savedPlace.registryParams.useChecksTabs"
                    />
                    <label for="useChecksTab">
                        {{ localizations.standards.show_checks_tab }}
                    </label>
                </div>

                <fieldset>
                    <legend class="text-bold">
                        {{ localizations.standards.registry_item_structure }}

                        <json-file-transfer-links
                            [data]="savedPlace.registryParams"
                            field="structure"
                            fileName="registry-structure"
                        >
                        </json-file-transfer-links>
                    </legend>
                    <evolenta-registry-structure
                        [structure]="savedPlace.registryParams.structure"
                    ></evolenta-registry-structure>
                </fieldset>
            </div>
        </div>
    </div>
</div>
