import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { AccessService, ModalDialogComponent, StorageService, ToasterService, TranslateService } from '@evolenta/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AppealSubserviceAdditionalDataComponent } from '../subservice-additional-data/appeal-subservice-additional-data.component';
import { AppealValidateService } from '../../../../appeal-validate.service';
import { AppealStatusService } from '../../../../appeal-status.service';
import { AppealSubservicesService } from '../../../../appeal-subservices.service';
import { UtilityService } from '../../../../../../../common/services/utility.service';
import { Permission } from '../../../../../../../common/services/permission';
import { AppealService } from '../../../../appeal.service';
import { EqService } from '../../../../../../../common/services/eq.service';
import * as _ from 'lodash-es';
import { RsoService } from '../../../../../../../common/services/rso.service';

@Component({
    selector: 'appeal-subservice-card',
    templateUrl: './appeal-subservice-card.component.html',
    styleUrls: ['./appeal-subservice-card.component.css'],
})
export class AppealSubserviceCardComponent implements OnInit {
    @Input() public appealSubservice; // редактируемая / просматриваемая услуга дела
    @Input() public mode = 'view'; // Режим отображения: view - предпросмотр карточки в списке; edit - редактированные данных услуги
    @Input() public activeTab; // активная вкладка
    @Input() public appeal;
    @Input() public subjects; // субъекты дела

    @Output() public onEdit = new EventEmitter<object>(); // инициализация режима редактирования услуги дела
    @Output() public onApply = new EventEmitter<any>(); // инициализация сохранения / отмены изменений в услуге дела в режиме редактирования
    @Output() public initEdit = new EventEmitter<object>(); // инициализация режима редактирования
    @Output() public onDelete = new EventEmitter<object>(); // удаление услуги из состава дела
    @Output() public onExecuteAction = new EventEmitter<object>(); // переход на новый статус
    @Output() public onAddNewSubjectToAppealSubserviceGroup = new EventEmitter<object>(); // переход в режим добавления нового участника в группу услуги дела
    @Output() public onGoToLicense = new EventEmitter<string>();

    @ViewChild('additionalData', { static: false })
    public appealSubserviceAdditionalDataComponent: AppealSubserviceAdditionalDataComponent;

    public subservice; // Описательная услуга
    public editTabs; // Массив доступных вкладок при редактировании данных услуги

    public baseAppealSubservice; // редактируемая услуга, для сравнения
    public baseAppealSubserviceData; // данные сервиса услуги для сравнения
    public continueProcessingAppeal = false; // флаг продолжения операции сохранения (перехода на другой статус) дела после применения изменений в услуги
    public appealSubserviceNumber;

    @ViewChild('applyModal', { static: false }) public modalDialogComponent: ModalDialogComponent; // модальное окно для подтверждения / отмены сохранений
    public modalOperation; // Вид операции которую обрабатывает модальное окно

    @ViewChild('setWindowNumberOrInterviewUserModal', { static: false })
    public setWindowNumberOrInterviewUserModal: TemplateRef<any>; // модальное окно для задания номера окна (для оценки)
    public modalRef: BsModalRef;
    public windowNumber;

    public allowDelete = true;
    public data; // описательный объект услуги в сервисе

    public showDateInfo;

    public permissions = Permission; // описание всех доступных прав доступа
    public subserviceCode; // код регламента в СПЭР

    public mkguInterviewApplicationSettings = this.storage.getItem('mkguInterviewApplication'); // настройки приложения для оценки
    @ViewChild('interviewModal', { static: false }) public interviewModal: TemplateRef<any>; // модальное окно c интерейсом опроса
    public interviewApplicationAddress; // URL приложения для оценки
    // agreeAllowInterviewObjects = []; // объекты дела, участвующие в текущей услуге, согласные на опрос в МКГУ
    public interviewObject; // участник дела, который оценивает услугу
    public selectedInterviewObject; // выбранный в модальном окне участник

    public notAdditionalDataTabs = ['common', 'ogv', 'variant', 'objects'];
    public localizations;

    public isDisabledByRso = false;

    public constructor(
        public validateService: AppealValidateService,
        public appealStatusService: AppealStatusService,
        public accessService: AccessService,
        public eqService: EqService,
        private appealSubservicesService: AppealSubservicesService,
        private utility: UtilityService,
        private appealService: AppealService,
        private storage: StorageService,
        private http: HttpClient,
        private toaster: ToasterService,
        private modalService: BsModalService,
        private sanitizer: DomSanitizer,
        private translate: TranslateService,
        private rsoService: RsoService,
    ) {}

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this._loadTranslations();
        this.data = this.appealSubservicesService.data[this.appealSubservice.guid];
        // Номер услуги в списке
        this.appealSubserviceNumber = null;
        // Если услуга одна в деле или статус дела не черновик, удаление услуги запрещено
        this.allowDelete = false;

        this.subservice = this.data.subservice;
        if (this.mode === 'edit') {
            // Сохранения передаваемой на редактирование услуги с целью последующего сравнения с ней
            this.baseAppealSubservice = _.cloneDeep(this.appealSubservice);
            this.baseAppealSubserviceData = _.cloneDeep(this.data);

            // Если не установлена активная вкладка из родительского элемента, устанавливаем вкладку по умолчанию
            if (!this.activeTab) {
                // this.activeTab = 'common';
                if (this.data.allowEdit) {
                    if (this.subservice.responsibleOrgs && this.subservice.responsibleOrgs.length > 1) {
                        this.activeTab = 'ogv';
                    } else if (this.subservice.variants) {
                        this.activeTab = 'variant';
                    } else {
                        this.activeTab = 'objects';
                    }
                } else if (
                    this.subservice.xsd ||
                    (this.appealSubservice.variant && this.appealSubservice.variant.xsd)
                ) {
                    this.activeTab = 'additionalData';
                }
            } else {
                // console.log('activate tab', this.activeTab);
            }

            // Формирование массива вкладок для редактирования информации по услуге
            this.initEditTabs();
        }

        const serviceIdItems = this.appealSubservice.serviceId.split('_');
        this.subserviceCode = serviceIdItems[3] ? parseInt(serviceIdItems[3], 10) : null;
        this.isDisabledByRso = this.rsoService.canNotEditAppeal(this.appealSubservicesService.appeal);
    }

    public _loadTranslations() {
        this.translate.get(['common', 'appeals.subservices']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    /**
     * Инициализация вкладок для доступа к блокам редактирования услуги дела
     */
    public initEditTabs() {
        this.editTabs = [
            {
                code: 'common',
                name: 'Общие данные',
                visible: false,
            },
            {
                code: 'ogv',
                name: 'ОГВ',
                visible:
                    this.subservice.responsibleOrgs &&
                    this.subservice.responsibleOrgs.length > 0 &&
                    this.subservice.responsibleOrgs.length !== 1 &&
                    this.data.allowEdit,
            },
            {
                code: 'variant',
                name: 'Вариант',
                visible: this.subservice.variants && this.subservice.variants.length > 0 && this.data.allowEdit,
            },
            {
                code: 'subjects',
                name: 'Участники',
                visible: this.data.allowEdit,
            },
            {
                code: 'additionalData',
                name: 'Доп.данные',
                visible: this.appealSubservice.variantXsd || this.appealSubservice.xsd,
            },
        ];

        this.initAdditionalDataTabs();

        this.editTabs.forEach(tab => {
            if (this.activeTab && tab.code === this.activeTab) {
                tab.active = true;
            }
        });
    }

    /**
     * Добавление вкладок с доп.данными
     */
    public initAdditionalDataTabs() {
        if (this.subservice.xsdTabs && this.subservice.xsdTabs.length > 0) {
            const globalForms = this.subservice.xsdTabs.filter(item => item.isGlobal && item.xsd);
            globalForms.forEach(form => {
                this.editTabs.push({
                    code: form.tabCode,
                    name: form.name,
                    visible: true,
                });
            });
        }
    }

    public get activeTabInfo() {
        const find = this.editTabs.find(item => item.code === this.activeTab);

        return find;
    }

    /**
     * Инициализация режима редактирования услуги в деле (нажатие кнопки "Редактировать")
     */
    public edit() {
        this.onEdit.emit(this.appealSubservice);
        this.appealService.editing = true;
    }

    /**
     * Сохранение изменений внесенных в услугу дела (нажатие кнопки "Применить")
     * @param continueProcessingAppeal - продолжить сохранение (перевод на другой статус) дела после применения изменений
     */
    public apply(continueProcessingAppeal = false) {
        this.continueProcessingAppeal = continueProcessingAppeal;
        // если текущая вкладка "Доп.информация", то нужно сохранить данные
        if (this.appealSubserviceAdditionalDataComponent) {
            this.appealSubserviceAdditionalDataComponent.save(null, true);
        } else {
            this.onApply.emit({
                appealSubservice: this.appealSubservice,
                continueProcessingAppeal: continueProcessingAppeal,
            });
        }
        this.appealService.editing = false;
    }

    /**
     * Отмена изменений внесенных в услугу дела (нажатие кнопки "Отменить")
     */
    public cancel() {
        this.onApply.emit(false);
        this.appealService.editing = false;
    }

    /**
     * Нажатие кнопки назад (переход к списку услуг) - КНМ изменений
     */
    public back() {
        if (this.activeTab === 'additionalData') {
            this.appealSubserviceAdditionalDataComponent.save(null, false, true);
        } else {
            this.checkSubserviceChange();
        }
    }

    /**
     * КНМ услуги на изменения
     */
    public checkSubserviceChange() {
        if (
            !_.isEqual(this.baseAppealSubservice, this.appealSubservice) ||
            !_.isEqual(this.baseAppealSubserviceData.objects, this.data.objects) ||
            !_.isEqual(this.baseAppealSubserviceData.xsdData, this.data.xsdData) ||
            !_.isEqual(this.baseAppealSubserviceData.variantXsdData, this.data.variantXsdData)
        ) {
            this.modalOperation = 'saveChanges';
            this.modalDialogComponent.title = this.localizations['common'].save_changes;
            this.modalDialogComponent.message = this.localizations['appeals.subservices'].apply_changes_list;
            this.modalDialogComponent.showModal();
        } else {
            this.onApply.emit({
                appealSubservice: this.appealSubservice,
                continueProcessingAppeal: this.continueProcessingAppeal,
            });
        }
    }

    /**
     * Инициализация удаления услуги из списка комплексных услуг
     */
    public deleteSubservice() {
        this.modalOperation = 'deleteSubservice';
        this.modalDialogComponent.title = this.localizations['appeals.subservices'].deletion;
        this.modalDialogComponent.message = this.localizations['appeals.subservices'].confirm_delete;
        this.modalDialogComponent.showModal();
    }

    /**
     * Обработка действий из модального окна: применение изменений, либо отмена
     * @param data
     */
    public processingBack(data) {
        if (data === 'yes') {
            // Нажатие кнопки "Да"
            if (this.modalOperation === 'saveChanges') {
                this.onApply.emit({
                    appealSubservice: this.appealSubservice,
                    continueProcessingAppeal: this.continueProcessingAppeal,
                });
            } else {
                this.onDelete.emit(this.appealSubservice);
            }
        } else if (data === 'no') {
            // Нажатие кнопки "Нет"
            if (this.modalOperation === 'saveChanges') {
                this.cancel();
            }
        }
    }

    /**
     * Выставление активной (открытой) вкладки для редактирования свойств элемента
     * @param tab
     */
    public activateTab(tab) {
        if (this.activeTab === 'additionalData') {
            // Если текущая вкладка "Доп. инфо", то перед переходом на новую сохраняем значения
            this.appealSubserviceAdditionalDataComponent.save(tab);
        } else {
            this.activeTab = tab;
            if (this.mode === 'view') {
                this.initEdit.emit({ appealSubserviceId: this.appealSubservice.id, tab: this.activeTab });
            }
        }
    }

    /**
     * Совершение дополнительных действий после сохранения json-формы на вкладке "Доп.данные"
     * @param data - объект {tab: активация определенной вкладки; apply: переход к режиму сохранения}
     */
    public afterSaveAdditionalData(data) {
        // Переход на другую вкладку
        if (data.tab) {
            this.activeTab = data.tab;
        }
        // Сохранение изменений
        if (data.apply) {
            this.onApply.emit({
                appealSubservice: this.appealSubservice,
                continueProcessingAppeal: this.continueProcessingAppeal,
            });
        }
        // КНМ изменений
        if (data.checkChanges) {
            this.checkSubserviceChange();
        }
    }

    /**
     * В случае выбора варианта с наличием доп данных (XSD для варианта), переинициализируем вкладки для доступа к редактированию
     */
    public afterChangeVariant() {
        this.initEditTabs();
    }

    /**
     * КНМ валидности услуги
     * @returns {boolean}
     */
    public checkSubserviceValid() {
        return this.validateService.checkElementValid(this.appealSubservice.guid, 'subservices');
    }

    /**
     * Получения визуального свойства по статусу услуги в деле
     * @param property - возвращаемое значение свойства
     * @param rejectedProperty
     * @returns {any}
     */
    public getStatusProperty(property, rejectedProperty = null) {
        // let rejectedProperty = '';
        // if (this.appealSubservice.resultType && this.appealSubservice.resultType.code === 'negative') {
        //     rejectedProperty = 'rejected' + property.charAt(0).toUpperCase() + property.slice(1);
        // }
        return this.appealStatusService.getStatusProperty(this.appealSubservice.status, property, rejectedProperty);
    }

    /**
     * Переход услуги на другой статус
     * @param action - выполняемое действие
     * @param isRollBack - откат предыдущего статуса
     */
    public executeAction(action, isRollBack = false) {
        this.data.activeAction = action;
        this.data.isProcessExecuteAction = true;
        if (isRollBack) {
            if (!action) {
                action = {
                    nextStatusGuid: '123',
                };
            }
            action = _.cloneDeep(action);
            action.additionActions = ['notValidate'];
            action.code = 'rollback';
        }
    }

    /**
     * Срок оказания из описательной услуги
     * @returns {any}
     */
    public getSubservicePlaneDate() {
        let termCondition = this.subservice.terms.condition.find(item =>
            this.appealSubservice.variant
                ? item.variantGuid && item.variantGuid.indexOf(this.appealSubservice.variant.guid) !== -1
                : !item.variantGuid,
        );
        if (!termCondition) {
            termCondition = this.subservice.terms.condition.find(item => !item.variantGuid);
        }
        const term = this.subservice.terms.term.find(item => item.guid === termCondition.termGuid);
        const mainTerm = term.times.find(item => item.typeTimeCode === 'resp_processing');
        if (mainTerm) {
            return mainTerm.processingTime + this.utility.getServiceTermsType(mainTerm.processingTimeType);
        }

        return '';
    }

    /**
     * Обновление информации об ошибках
     */
    public updateErrors() {
        this.validateService.validateAppeal(this.validateService.processValidate);
    }

    /**
     * Инициализация режима редактирования варианта выдачи результата услуги
     */
    public changeIssueResultForm() {
        this.appealSubservice.issueResultForm = this.data.issueResultForm;
        this.updateErrors();
    }

    /**
     * Вывод услуг, от которых зависит данная услуга
     */
    public getAfterStartSubservicesList() {
        let result = '';
        this.appealSubservice.startAfterGuids.forEach(guid => {
            if (this.appealService.appeal.subservice.guid === guid) {
                result +=
                    '<li><span class="badge badge-info badge-xs mr-5">' +
                    this.appealService.appeal.subservice.displayOrder +
                    '</span>' +
                    this.appealService.appeal.subservice.shortTitle +
                    '</li>';
            }
        });

        return result;
    }

    /**
     * КНМ возможности проведения опроса о качестве оказания услуги
     */
    public checkAllowMkguInterview() {
        return (
            this.data.notAgreeMkguObjects &&
            this.data.notAgreeMkguObjects.length > 0 &&
            this.appealStatusService.issuedStatuses.indexOf(this.appealSubservice.status.code) !== -1 &&
            this.mkguInterviewApplicationSettings &&
            this.mkguInterviewApplicationSettings !== 'none' &&
            this.data.subservice &&
            this.data.subservice.additionalInfo &&
            this.data.subservice.additionalInfo.rguServiceId
        );
    }

    /**
     * Запуск процедуры опроса (ввод номера окна, либо переход к процедуре)
     */
    public startInterview() {
        const needSelectObject = this.data.notAgreeMkguObjects.length > 1;
        const needSelectWindowNumber = !this.eqService.window || !this.eqService.window.number;
        if (this.data.notAgreeMkguObjects.length === 1) {
            this.interviewObject = this.data.notAgreeMkguObjects[0];
        } else {
            this.interviewObject = null;
        }
        if (!needSelectObject && !needSelectWindowNumber) {
            this.startingInterview();
        } else {
            // Отображение модального окна для ввода номера окна / выбора интервьюируемого
            this.selectedInterviewObject = null;
            this.modalRef = this.modalService.show(this.setWindowNumberOrInterviewUserModal, {
                backdrop: 'static',
                class: 'modal-sm',
            });
        }
    }

    /**
     * Установка номера окна
     */
    public setWindowNumber() {
        const errors = [];
        if (!this.windowNumber && (!this.eqService.window || !this.eqService.window.number)) {
            errors.push('Не заполен номер окна.');
        }

        if (this.data.notAgreeMkguObjects.length > 1 && !this.selectedInterviewObject) {
            errors.push('Не выбран участник.');
        }
        if (errors.length > 0) {
            this.toaster.error(errors.join(' '));
        } else {
            if (this.windowNumber) {
                if (!this.eqService.window) {
                    this.eqService.window = {
                        number: null,
                    };
                }
                this.eqService.window.number = this.windowNumber;
            }
            if (this.selectedInterviewObject) {
                this.interviewObject = _.cloneDeep(this.selectedInterviewObject);
            }

            this.modalRef.hide();
            this.startingInterview();
        }
    }

    /**
     * Запуск процедуры опроса посетителя
     */
    public startingInterview() {
        if (this.mkguInterviewApplicationSettings.useTablet) {
            this.processingInterview();
        } else {
            const windowNumber = this.eqService.window.number.toString();
            const currentOrganization = this.storage.getItem('currentOrganization');
            this.interviewApplicationAddress = this.sanitizer.bypassSecurityTrustResourceUrl(
                this.mkguInterviewApplicationSettings.url +
                    'init/' +
                    windowNumber +
                    '/1/' +
                    currentOrganization['code'],
            );
            // Отображение модального окна для ввода номера окна
            this.modalRef = this.modalService.show(this.interviewModal, { backdrop: 'static', class: 'modal-xlg' });

            setTimeout(() => {
                this.processingInterview();
            }, 5000);
        }
    }

    /**
     * Непосредственная процедура опроса
     */
    public processingInterview() {
        const windowNumber = this.eqService.window.number.toString();
        const currentOrganization = this.storage.getItem('currentOrganization');
        const params = {
            windowId: windowNumber,
            unitId: currentOrganization.code,
            newEvent: 'showRateForms',
            params: {
                services: [
                    {
                        serviceId: this.appealSubservice.serviceId,
                        name: this.appealSubservice.shortName,
                        dealNumber: this.appealService.appeal.shortNumber,
                        pin: this.appealService.appeal.pin,
                    },
                ],
            },
        };
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');

        this.http
            .post(this.mkguInterviewApplicationSettings.url + 'enforceRate', params, { headers: headers })
            .toPromise()
            .then(data => {
                switch (data['state']) {
                    case 'ok':
                        this.toaster.success('Опрос посетителя успешно активирован');
                        break;
                    case 'unavailable':
                        this.toaster.error(
                            'Не удалось активировать опрос посетителя: Устройство сенсорного ввода не подключено к окну №' +
                                windowNumber +
                                '.',
                        );
                        break;
                    case 'off':
                        this.toaster.error(
                            'Не удалось активировать опрос посетителя: Устройство сенсорного ввода окна №' +
                                windowNumber +
                                ' было отключено, либо возникли сетевые проблемы.',
                        );
                        break;
                    case 'processing':
                        this.toaster.info(
                            'Опрос посетителя окна №' + windowNumber + ' в текущий момент уже производится.',
                        );
                        break;
                    case 'alreadyRated':
                        this.toaster.error(
                            'Дело №' +
                                this.appealService.appeal.shortNumber +
                                ' ранее уже оценивалось, повторная оценка невозможна.',
                        );
                        break;
                    case 'serviceNotForRate':
                        this.toaster.error(
                            'Дело №' +
                                this.appealService.appeal.shortNumber +
                                ' не может быть оценено, услуга не настроена',
                        );
                        break;
                    default:
                        this.toaster.error('Не удалось активировать опрос посетителя: ' + data['state']);
                        console.log('ERROR INTERVIEW', data);
                        break;
                }
            });
    }

    public addNewSubjectToAppealSubserviceGroup(data) {
        this.onAddNewSubjectToAppealSubserviceGroup.emit(data);
    }

    public goToLicense(licenseId) {
        this.onGoToLicense.emit(licenseId);
    }
}
