<ng-container>
    <div class="pt-20 pl-20 pr-20">
        <ul class="info-row mb-15 no-margin-left no-margin-right">
            <ng-container *ngFor="let tab of globalTabs">
                <li [ngClass]="{ active: activeGlobalTab == tab.code }" (click)="activateGlobalTab(tab.code)">
                    {{ tab.name }}
                </li>
            </ng-container>
        </ul>
    </div>
</ng-container>

<div class="panel panel-shadow m-20">
    <ng-container *ngIf="activeGlobalTab === 'common'">
        <div class="panel-heading">
            <h4 class="panel-title">
                <span>
                    {{ localizations.standards.variant_editing }}
                </span>
            </h4>
            <div class="heading-elements">
                <button class="btn btn-success-overline heading-btn btn-labeled" *ngIf="allowEdit" (click)="save()">
                    {{ localizations.common.apply }}
                </button>
                <button class="btn btn-danger-overline heading-btn btn-labeled" (click)="cancel()">
                    {{ allowEdit ? localizations.common.do_cancel : localizations.common.close }}
                </button>
            </div>
        </div>

        <div class="panel-body">
            <ng-container *ngIf="tabs.length > 1">
                <ul class="info-row no-margin">
                    <ng-container *ngFor="let tab of tabs">
                        <li [ngClass]="{ active: activeTab == tab.code }" (click)="activeTab = tab.code">
                            {{ tab.name }}
                        </li>
                    </ng-container>
                </ul>
            </ng-container>

            <div class="mt-20" [hidden]="activeTab != 'common'">
                <!-- Основные данные -->
                <dynamic-form
                    #mainData
                    *ngIf="metaData.variantsData.xsd"
                    [xsd]="metaData.variantsData.xsd"
                    [model]="variant"
                    [saveInMainObject]="true"
                    [disabled]="!allowEdit"
                >
                </dynamic-form>

                <!-- Сроки -->
                <fieldset *ngIf="metaData.variantsData.isTermSetting && termsData">
                    <legend class="text-bold">
                        {{ localizations.standards.terms }}
                    </legend>
                    <dynamic-form
                        [xsd]="termsData.xsd"
                        [model]="variant"
                        [saveInMainObject]="true"
                        [disabled]="!allowEdit"
                    >
                    </dynamic-form>
                </fieldset>

                <!-- Настройка запросов на вариант -->
                <common-standard-entity-select-data
                    elementIdField="_id"
                    elementNameField="name"
                    entityField="requestIds"
                    [selectedDataName]="localizations.standards.requests"
                    [selectingText]="localizations.standards.select_requests"
                    [entity]="variant"
                    [elements]="commonStandardsService.requests"
                    *ngIf="metaData.variantsData.isRequestsSetting"
                >
                </common-standard-entity-select-data>

                <!-- Настройка адм. процедур на вариант -->
                <common-standard-entity-select-data
                    elementIdField="guid"
                    elementNameField="name"
                    entityField="stageGuids"
                    [selectedDataName]="localizations.standards.administrative_processes"
                    [selectingText]="localizations.standards.select_administrative_processes"
                    [elements]="entity.stages"
                    [entity]="variant"
                    *ngIf="metaData.variantsData.isStaticProcessSetting"
                >
                </common-standard-entity-select-data>

                <!-- Настройка причин отказа в оказании услуги на вариант -->
                <common-standard-entity-select-data
                    entityField="serviceRejectReasonCodes"
                    [elements]="getCustomElements('serviceRejectReasonsData')"
                    [entity]="variant"
                    [elementIdField]="getCustomParam('serviceRejectReasonsData', 'elementIdField')"
                    [elementNameField]="getCustomParam('serviceRejectReasonsData', 'elementNameField')"
                    [selectedDataName]="localizations.standards.decline_reason_for_service"
                    [selectingText]="localizations.standards.select_decline_reason_for_service"
                    *ngIf="metaData.variantsData.isServiceRejectReasonsSetting"
                ></common-standard-entity-select-data>

                <!-- Настройка причин отказа в приеме документов на вариант -->
                <common-standard-entity-select-data
                    entityField="docsRejectReasonCodes"
                    [elements]="getCustomElements('docsRejectReasonsData')"
                    [entity]="variant"
                    [elementIdField]="getCustomParam('docsRejectReasonsData', 'elementIdField')"
                    [elementNameField]="getCustomParam('docsRejectReasonsData', 'elementNameField')"
                    [selectedDataName]="localizations.standards.decline_reason_for_documents"
                    [selectingText]="localizations.standards.select_decline_reason_for_documents"
                    *ngIf="metaData.variantsData.isDocsRejectReasonsSetting"
                >
                </common-standard-entity-select-data>

                <!-- Настройка причин приостановки на вариант -->
                <common-standard-entity-select-data
                    entityField="serviceSuspendReasonCodes"
                    [elements]="getCustomElements('suspendReasonsData')"
                    [entity]="variant"
                    [elementIdField]="getCustomParam('suspendReasonsData', 'elementIdField')"
                    [elementNameField]="getCustomParam('suspendReasonsData', 'elementNameField')"
                    [selectedDataName]="localizations.standards.suspend_reason"
                    [selectingText]="localizations.standards.select_suspend_reason"
                    *ngIf="metaData.variantsData.isSuspendReasonsSetting"
                ></common-standard-entity-select-data>

                <!-- Настройка дополнительных данных -->
                <p *ngIf="metaData.variantsData.allowCreateXsd">
                    <span class="text-bold">
                        {{ getAdditionalFieldsSetUpStatusMessage() }}
                    </span>
                    <ng-container *ngIf="allowEdit">
                        <span class="text-size-small anchor text-info ml-10" (click)="editXsd()">
                            {{ !variant.xsd ? localizations.common.setup : localizations.common.change_small }}
                        </span>
                        <span
                            class="text-size-small anchor text-slate ml-10"
                            (click)="variant.xsd = null"
                            *ngIf="variant.xsd"
                        >
                            {{ localizations.common.delete_small }}
                        </span>
                    </ng-container>
                </p>
            </div>

            <!-- Платность -->
            <div class="pt-20" *ngIf="activeTab == 'paid'">
                <dynamic-form
                    [xsd]="metaReglament.paidData.xsd"
                    [model]="variant.paid"
                    [saveInMainObject]="true"
                    [disabled]="!allowEdit"
                >
                </dynamic-form>
            </div>

            <!-- Реестровая модель -->
            <div class="pt-20" *ngIf="activeTab == 'registersModel'">
                <fieldset *ngIf="registersModel?.operationTypes?.length">
                    <label for="operationType">
                        {{ localizations.standards.operation_kind }}
                    </label>
                    <ng-select
                        id="operationType"
                        [allowClear]="true"
                        [items]="registersModel.operationTypes"
                        textField="name"
                        idField="guid"
                        [active]="activeOperationTypes"
                        (selected)="selectOperationType($event.id)"
                        (removed)="selectOperationType(null)"
                    >
                    </ng-select>
                </fieldset>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="activeGlobalTab === 'stages'">
        <div class="panel-heading">
            <h4 class="panel-title">
                <span>{{ localizations.standards.periods.periods_settings }}</span>
            </h4>
            <div class="heading-elements">
                <button
                    class="btn btn-success-overline heading-btn btn-labeled"
                    (click)="savePeriods()"
                    [disabled]="periodsIsEditing"
                >
                    {{ localizations.common.apply }}
                </button>
                <button class="btn btn-danger-overline heading-btn btn-labeled" (click)="cancel()">
                    {{ localizations.common.do_cancel }}
                </button>
            </div>
        </div>

        <div class="panel-body">
            <div class="form-group">
                <input
                    id="autoFill"
                    class="checkbox form-control"
                    type="checkbox"
                    name="setDefault"
                    [(ngModel)]="periodsData.periods.autoFill"
                />
                <label class="control-label" for="autoFill">
                    {{ localizations.standards.periods.execution_control }}
                </label>
            </div>

            <ng-container *ngIf="periodsData.processOptions.length">
                <div class="form-group form-group-lg" style="width: 700px">
                    <label class="text-bold">{{ localizations.standards.periods.select_subprocess }}</label>
                    <ng-select
                        [allowClear]="false"
                        [items]="periodsData.processOptions"
                        [multiple]="false"
                        (selected)="selectCurrentProcess($event.id)"
                        [disabled]="periodsIsEditing"
                        [active]="getSelectedProcess()"
                        required
                    >
                    </ng-select>
                </div>
            </ng-container>

            <common-standard-periods
                [initData]="periodsData"
                (confirmChanges)="editPeriodsLocal($event)"
                (changeEditingState)="periodsIsEditing = $event"
            ></common-standard-periods>
        </div>
    </ng-container>
</div>
