<!-- Основная кнопка + глобальный поиск -->
<ng-container *ngIf="initialized">
    <div class="navbar navbar-default no-padding no-border-top navbar-inside-component">
        <breadcrumbs></breadcrumbs>
        <button
            class="btn btn-success btn-main btn-xs btn-labeled btn-labeled-right"
            type="button"
            [disabled]="
                appealService.editing ||
                appealSaveService.isProcessSavingAppeal ||
                isDisabledByRso ||
                (appeal._id
                    ? !accessService.hasAccess([permissions.Appeal_Update], true, appeal.status)
                    : !accessService.hasAccess([permissions.Appeal_Create], true, appeal.status))
            "
            (click)="saveAppeal()"
        >
            <b><i class="icon-checkmark-circle" [ngClass]="{ flash: appealSaveService.isProcessSavingAppeal }"></i></b>
            {{ localizations.common.save }}
        </button>
        <div class="ml-20 navbar-title mt-20">
            <h4>
                <span *ngIf="!appeal._id">
                    {{ localizations.appeals.edit.creation }}
                </span>
                <ng-container *ngIf="appeal._id && appeal.status.code != 'consultation'">
                    <span>
                        {{ localizations.appeals.appeal }}&nbsp;
                        <ng-container *ngIf="appeal.number">
                            <span class="text-muted"> № </span>
                            <span class="hidden-md"> {{ appeal.number }} </span>
                            <span class="visible-md-inline-block"> {{ appeal.shortNumber }} &nbsp;</span>
                            <span class="text-muted">
                                {{ localizations.common.from }}
                                {{ appeal.dateRegister | date : 'shortDate' }}
                            </span>
                        </ng-container>
                    </span>
                </ng-container>
                <ng-container *ngIf="appeal._id && appeal.status.code == 'consultation'">
                    <span>
                        {{ localizations.common.consultation }}
                    </span>
                    <span *ngIf="appeal.consultationNumber">
                        № {{ appeal.consultationNumber }} {{ localizations.common.from }}
                        {{ appeal.dateConsultation | date : 'shortDate' }}
                    </span>
                </ng-container>

                <!-- Статус дела -->
                <label class="badge text-uppercase ml-15 mb-15" [ngClass]="getStatusProperty('background')">
                    {{ getStatusProperty('name') }}
                </label>
                <label
                    class="badge text-uppercase bg-danger-300 ml-10 mb-15"
                    *ngIf="appeal.fromPgu || appeal.pguEventId"
                >
                    {{ localizations.common.rpgu }}
                </label>
                <label
                    class="badge text-uppercase bg-teal-300 ml-10 mb-15"
                    *ngIf="appeal.fromPgu2 || appeal.pgu2EventId"
                >
                    {{ localizations.common.rpgu2 }}
                </label>
                <label
                    class="badge text-uppercase bg-brown-300 ml-10 mb-15"
                    *ngIf="appeal.fromMfc || appeal.mfcEventId"
                >
                    {{ localizations.common.mfc }}
                </label>
                <label
                    class="badge text-uppercase bg-green-300 ml-10 mb-15"
                    *ngIf="appeal.fromStroyu || appeal.stroyuEventId"
                >
                    {{ localizations.common.yastroyu }}
                </label>
                <label
                    class="badge text-uppercase bg-orange-300 ml-10 mb-15"
                    *ngIf="appeal.fromLocolo || appeal.locoloEventId"
                >
                    {{ localizations.common.locolo }}
                </label>
                <label
                    class="badge text-uppercase bg-success-300 ml-10 mb-15"
                    *ngIf="appeal.fromZabota || appeal.zabotaEventId"
                >
                    {{ localizations.common.zabota }}
                </label>
                <label
                    class="badge text-uppercase bg-purple-200 ml-10 mb-15"
                    *ngIf="appeal.fromEpgu || appeal.epguOrderId"
                >
                    {{ localizations.common.epgu }}
                </label>
            </h4>
        </div>

        <div class="navbar-menu">
            <user-tile></user-tile>
        </div>
    </div>

    <div class="page-container">
        <!-- Secondary sidebar -->
        <div class="sidebar sidebar-secondary sidebar-default">
            <div class="sidebar-content">
                <!-- Вкладка "Активные задачи" -->
                <div
                    class="sidebar-category"
                    *ngIf="canShowActiveTasksTab()"
                    (click)="activateTab(TABS.ACTIVE_TASKS, false)"
                    [ngClass]="{
                        active: checkIsActive(TABS.ACTIVE_TASKS),
                        'cursor-pointer': !checkIsActive(TABS.ACTIVE_TASKS)
                    }"
                >
                    <div class="category-title">
                        <span>
                            {{ localizations.appeals.edit.active_tasks }}
                            <span class="badge badge-primary badge-small ml-5 text-semibold">
                                {{ appealProcessService.activeTasks.length }}
                            </span>

                            <!-- Уведомление о непрочитанного ответа от пользователя -->
                            <i
                                class="icon-bookmark2 ml-5 text-primary"
                                title="Непрочитанные ответы от пользователя"
                                *ngIf="checkHasUnreadUserAnswer()"
                            >
                            </i>
                        </span>
                    </div>
                </div>

                <!-- Вкладка "Процессы" -->
                <div
                    class="sidebar-category"
                    *ngIf="canShowCompletedTasksTab()"
                    (click)="activateTab(TABS.COMPLETED_TASKS, false)"
                    [ngClass]="{
                        active: checkIsActive(TABS.COMPLETED_TASKS),
                        'cursor-pointer': !checkIsActive(TABS.COMPLETED_TASKS)
                    }"
                >
                    <div class="category-title">
                        <span>
                            {{ localizations.appeals.edit.completed_tasks }}
                        </span>
                    </div>
                </div>

                <!-- Вкладка "Услуги" -->
                <div
                    class="sidebar-category"
                    (click)="activateTab(TABS.SUBSERVICES, false)"
                    [ngClass]="{
                        active: checkIsActive(TABS.SUBSERVICES),
                        'cursor-pointer': !checkIsActive(TABS.SUBSERVICES)
                    }"
                >
                    <div class="category-title">
                        <span>
                            {{ localizations.services.title }}
                            <span
                                class="badge badge-danger badge-small ml-5 text-semibold"
                                *ngIf="validateService.getEntityErrorsCount('subservice') > 0"
                            >
                                {{ validateService.getEntityErrorsCount('subservice') }}
                            </span>
                        </span>
                    </div>
                </div>

                <!-- Вкладка "Участники" -->
                <div
                    class="sidebar-category"
                    *ngIf="!isDisabledByRso"
                    (click)="activateTab(TABS.SUBJECTS, false)"
                    [ngClass]="{
                        active: checkIsActive(TABS.SUBJECTS),
                        'cursor-pointer': !checkIsActive(TABS.SUBJECTS) && !disabledSubjectsTab,
                        disabled: disabledSubjectsTab
                    }"
                >
                    <div class="category-title">
                        <span>
                            {{ localizations.common.participants }}
                            <span
                                class="badge badge-danger badge-small ml-5 text-semibold"
                                *ngIf="validateService.getEntityErrorsCount('subjects') > 0"
                            >
                                {{ validateService.getEntityErrorsCount('subjects') }}
                            </span>
                        </span>
                    </div>
                </div>

                <!-- Вкладка "Документы" -->
                <div
                    class="sidebar-category"
                    [ngClass]="{
                        active: checkIsActive(TABS.DOCUMENTS),
                        'cursor-pointer': !checkIsActive(TABS.DOCUMENTS) && !disabledDocumentTab,
                        disabled: disabledDocumentTab
                    }"
                    (click)="activateTab(TABS.DOCUMENTS, false)"
                >
                    <div class="category-title">
                        <span>
                            {{ localizations.appeals.edit.documents }}

                            <!-- Уведомление о наличии необработанных ответов на запросы в документах дела -->
                            <i
                                class="icon-bookmark2 ml-5 text-primary left-sidebar-unread-icon"
                                title="Необработанные ответы на запросы"
                                *ngIf="appeal.isHavingResult || checkHasUserDocuments()"
                            >
                            </i>

                            <!-- Уведомление о ошибках в документах дела -->
                            <span
                                class="badge badge-danger badge-small ml-5 text-semibold"
                                *ngIf="validateService.getEntityErrorsCount('documents') > 0"
                            >
                                {{ validateService.getEntityErrorsCount('documents') }}
                            </span>
                        </span>
                    </div>
                </div>

                <!-- Вкладка "История" -->
                <div
                    class="sidebar-category"
                    *ngIf="canShowHistoryTab() && !isDisabledByRso"
                    (click)="activateTab(TABS.HISTORY, false)"
                    [ngClass]="{ active: checkIsActive(TABS.HISTORY), 'cursor-pointer': !checkIsActive(TABS.HISTORY) }"
                >
                    <div class="category-title">
                        <span>
                            {{ localizations.appeals.edit.history }}
                        </span>
                    </div>
                </div>

                <div class="mt-30">
                    <!-- Доступные действия для дела -->
                    <div class="status-action" *ngIf="isAllowAppealActions">
                        <div class="status-action-btn">
                            <ul class="list-unstyled">
                                <ng-container *ngFor="let action of actions; trackBy: trackAction">
                                    <li class="text-center m-15" *ngIf="checkAllowAction(action)">
                                        <div class="btn-group btn-group-sm flex">
                                            <button
                                                class="btn bg-blue-800 btn-labeled"
                                                [disabled]="
                                                    appealService.editing ||
                                                    !accessService.hasAccess(
                                                        [permissions.Appeal_Execute_Action],
                                                        true,
                                                        appeal.status
                                                    ) ||
                                                    processingAction
                                                "
                                                (click)="activateAction(action)"
                                            >
                                                <i
                                                    class="icon-checkmark3"
                                                    [ngClass]="{
                                                        flash: processingAction && processingAction.guid == action.guid
                                                    }"
                                                >
                                                </i>
                                            </button>
                                            <button
                                                class="btn no-white-space bg-blue-800 text-center p-5"
                                                data-btn="appeal"
                                                [disabled]="
                                                    appealService.editing ||
                                                    !accessService.hasAccess(
                                                        [permissions.Appeal_Execute_Action],
                                                        true,
                                                        appeal.status
                                                    ) ||
                                                    processingAction
                                                "
                                                (click)="executeAction({ action: action })"
                                            >
                                                {{ action.name }}
                                            </button>
                                        </div>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>

                    <!-- Доступные действия для услуги дела -->
                    <div
                        class="status-action"
                        *ngIf="appeal._id && appealSubserviceActions.length > 0 && appeal.status.code !== 'complete'"
                    >
                        <div class="status-action-btn">
                            <ul class="list-unstyled">
                                <li *ngFor="let action of appealSubserviceActions" class="text-center m-15">
                                    <div class="btn-group flex">
                                        <button
                                            class="btn"
                                            [ngClass]="{
                                                'bg-blue-800': action.guid == activeAction.guid,
                                                'bg-blue-600': action.guid != activeAction.guid
                                            }"
                                            [disabled]="
                                                !accessService.hasAccess(
                                                    [permissions.Appeal_Execute_Action],
                                                    true,
                                                    appeal.status
                                                ) || processingAction
                                            "
                                            (click)="activateAction(action)"
                                        >
                                            <i
                                                class="icon-checkmark3"
                                                [ngClass]="{
                                                    'icon-spinner2 spinner':
                                                        processingAction && processingAction.guid == action.guid,
                                                    'icon-checkmark3':
                                                        !processingAction || processingAction.guid != action.guid
                                                }"
                                            >
                                            </i>
                                        </button>
                                        <button
                                            class="btn no-white-space p-5"
                                            data-btn="service"
                                            [ngClass]="{
                                                'bg-blue-700': action.guid == activeAction.guid,
                                                'bg-blue': action.guid != activeAction.guid
                                            }"
                                            [disabled]="
                                                !accessService.hasAccess(
                                                    [permissions.Appeal_Execute_Action],
                                                    true,
                                                    appeal.status
                                                ) || processingAction
                                            "
                                            (click)="
                                                executeAction({ action: action, appealSubservice: appeal.subservice })
                                            "
                                        >
                                            {{ action.name }}
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- Действия для дел, находящихся в преархивной коллекции -->
                    <div class="status-action" *ngIf="appeal.status.code == 'archive' && appeal.inPreArchive">
                        <div class="status-action-btn">
                            <ul class="list-unstyled">
                                <li class="text-center m-15 mt-30">
                                    <button
                                        class="btn no-white-space btn-primary form-control"
                                        (click)="sendToArchive()"
                                        [disabled]="isProcessingCleanupAndMoveToArchive"
                                    >
                                        {{ localizations.appeals.edit.depersonalize }}
                                        <i class="icon-spinner2 spinner" *ngIf="isProcessingCleanupAndMoveToArchive">
                                        </i>
                                    </button>
                                </li>

                                <li class="text-center m-15">
                                    <button
                                        class="btn no-white-space btn-primary form-control"
                                        (click)="returnFromArchive()"
                                        [disabled]="isProcessingRollbackFromPreArchive"
                                    >
                                        {{ localizations.appeals.edit.return_from_archive }}
                                        <i class="icon-spinner2 spinner" *ngIf="isProcessingRollbackFromPreArchive">
                                        </i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- Всегда доступные действия -->
                    <div class="status-action" *ngIf="checkIsActive(TABS.DOCUMENTS)">
                        <div class="status-action-btn">
                            <ul class="list-unstyled">
                                <li class="text-center m-15 mt-30">
                                    <button
                                        class="btn no-white-space btn-primary download-files-button"
                                        (click)="uploadDocuments()"
                                    >
                                        {{ localizations.appeals.edit.uploadDocuments }}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Блоки с настройками элементов дела -->
        <div class="panel-main">
            <evolenta-scrollbar scrollClass="panel panel-default no-border-top no-border-radius no-margin no-padding">
                <router-outlet> </router-outlet>
            </evolenta-scrollbar>
        </div>
    </div>
</ng-container>

<!-- модальное окно для заполнения дополнительных настроек для действия при переходе на новый статус -->
<reason-modal
    #reasonModal
    [items]="reasonItems"
    [fields]="reasonFields"
    [title]="reasonTitle"
    (onComplete)="completeReasons($event)"
    (onCancel)="clearProcessExecuteActionData()"
>
</reason-modal>

<!-- модальное окно для выбора соответствия сохраняемого объекта дела и объекта в реестре клиентов -->
<reestr-modal #reestrModal> </reestr-modal>
