import { ApplicationRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessService, FiltersBarComponent, RestService, StorageService, TranslateService } from '@evolenta/core';
import { AppealService } from './appeal.service';
import { AppealStatusService } from './appeal-status.service';
import { Permission } from '../../../common/services/permission';
import * as moment from 'moment';
import {
    ACTUALITY_STATUS,
    APPEAL_STATUSES,
    APPEALS_ACTUALITY,
    APPEALS_DEFAULT_LIST_SIZE,
    APPEALS_STATUS,
    ASCENDING_ORDER,
    DEFAULT_APPEALS_PROJECTION,
    DEFAULT_SORT_FIELD,
    LOAD_DATA_MODES,
} from '../../../common/constants';
import _ from 'lodash';
import { AppealData } from './appeal.data';
import cloneDeep from 'lodash-es/cloneDeep';

@Component({
    selector: 'appeals',
    templateUrl: './appeals.component.html',
    styleUrls: ['../../elements-list.css'],
    styles: [
        '.notify-label { position: absolute; top: 12px; right: 15px; font-size: 8px; }',
        '.rejected-status { position: absolute; bottom: -6px; right: 0; }',
        '.reject-status i { width: 18px; }',
    ],
})
export class AppealsComponent implements OnInit {
    public constructor(
        public appealService: AppealService,
        public accessService: AccessService,
        private route: ActivatedRoute,
        private ref: ApplicationRef,
        private router: Router,
        private rest: RestService,
        private appealStatusService: AppealStatusService,
        private storage: StorageService,
        private translate: TranslateService,
    ) {}
    // @ViewChild('filtersPanel', { static: true }) private filtersPanel: CustomFiltersBarComponent; // панель фильтров
    @ViewChild('filtersPanel', { static: true }) private filtersPanel: FiltersBarComponent; // панель фильтров

    public appeals = []; // список дел
    public filtersPanelItems; // Настройки панели фильтрации

    public globalSearch; // Глобальный поиск (верхняя строка)

    public mode = 'short';

    // Параметры пагинации
    public totalAppealsCount = 0; // Общее число дел
    public currentPage = 0;
    public totalPages: number;

    // Параметры предпросмотра
    public previewedAppeal = null; // Дело, отображаемое в режиме предпросмотра
    public prevent = false;
    public previewMode = false;
    public timer = null;

    // Параметры для работы с групповой операцией
    public isAllAppealsSelected = false;

    public permissions = Permission; // Описание всех полномочий системы

    public baseSearch = []; // Параметры базового поиска

    public currentOrganization = this.storage.getItem('currentOrganization');
    public moduleBaseUrl;
    public metaReglament;

    public searchCollection;
    public disabledFilters = ['dateFinish'];
    public localizations;
    public sortOpts = {
        field: DEFAULT_SORT_FIELD,
        order: ASCENDING_ORDER,
    };
    public initialized = false;
    public selectedSortField = DEFAULT_SORT_FIELD;
    public selectedSortOrder = 'ASC';

    public ADD = LOAD_DATA_MODES.ADD;
    public REPLACE = LOAD_DATA_MODES.REPLACE;

    private defaultSearchFields: Set<string>;
    private sourceFields: Set<string>;

    // ------------- МЕТОДЫ ОБРАБОТКИ ГРУППОВЫХ ОПЕРАЦИЙ ------------- //

    /**
     * Инициализация компонента
     */
    public ngOnInit() {
        this.sourceFields = new Set();
        this.sourceFields.add('fromEpgu');
        this.sourceFields.add('fromPgu');
        this.sourceFields.add('fromMfc');
        this.sourceFields.add('fromStroyu');

        this.defaultSearchFields = new Set();
        this.defaultSearchFields.add('status.code');
        this.defaultSearchFields.add('unit.id');
        this.defaultSearchFields.add('subservices.version');
        this.defaultSearchFields.add('organizationExecutor.id');

        this._loadTranslations();
        this.route.parent.parent.url.subscribe(urlPath => {
            this.moduleBaseUrl = urlPath[urlPath.length - 1].path;
        });

        // Параметры базового поиска
        // Если не отключено ограничение по текущему МФЦ
        this.baseSearch = [
            {
                orSubConditions: [
                    {
                        field: 'unit.id',
                        operator: 'eq',
                        value: this.currentOrganization._id,
                    },
                    {
                        field: 'organizationExecutor.id',
                        operator: 'eq',
                        value: this.currentOrganization._id,
                    },
                ],
            },
        ];

        this.appealService.prepareSearchBySubservices(this.baseSearch);

        this._prepareFilters();
        this._prepareSort();

        // Получение
        this.route.data.subscribe(async response => {
            if (response.version) {
                this.baseSearch.push({ field: 'subservices.version', operator: 'eq', value: response.version });
            }

            // this.appeals = this.appealService.processAppealsProperties(response.resolves.appeals);
            this.metaReglament = response.resolves.metaReglament;

            while (!this.filtersPanel.insideFilters.length) {
                await new Promise(resolve => {
                    setTimeout(() => {
                        resolve();
                    }, 0);
                });
            }

            await this.loadData(this.REPLACE);
            this.initialized = true;
            // this.totalAppealsCount = this.rest.pagination.total;
            if (this.appeals.length > 0) {
                this.clickByAppeal(this.appeals[0]);
            }
        });

        this.route.data.subscribe(async response => {
            this.metaReglament = response.resolves.metaReglament;
        });

        this._prepareFilters();

        // Настройки для групповых операций
        this._selectAppeals();
    }

    private _loadTranslations(): void {
        this.translate.get(['common', 'appeals']).subscribe(res => {
            this.localizations = res;
        });
    }

    private _prepareFilters(): void {
        const userFilters = this.storage.getItem('appealsFilters');
        // Панель фильтрации
        this.filtersPanelItems = _.cloneDeep(this.appealService.filtersPanelItems);
        // При инициализации панели фильтров в разделе "актуальность дел" по-умолчанию пункт "активные"
        const appealsActuality = this.filtersPanelItems[0].data.find(block => block.code === 'appealsActuality');

        if (appealsActuality && !userFilters) {
            const filter = appealsActuality.filters[0].items.find(f => f.code === 'appeals:actual');

            appealsActuality.filters[0].value = filter;

            if (filter.labelName) {
                appealsActuality.filters[0].label = filter[filter.labelName];
            } else {
                appealsActuality.filters[0].label = filter.shortName ? filter.shortName : filter.name;
            }
            this.filtersPanel.processDependFilter(appealsActuality);
        }

        if (this.metaReglament && !userFilters) {
            const appealData = this.metaReglament.blocks.find(item => item.code === 'appealData');
            const appealStatuses = appealData.appealStatuses;
            const find = this.filtersPanelItems[0].data.find(item => item.code && item.code === 'appealStatuses');

            find.filters[0].items = appealStatuses.map(item => ({
                code: item.code,
                name: item.name,
                background: 'bg-' + item.theme + '-300',
            }));

            const registersModel = this.metaReglament.blocks.find(item => item.code === 'registersModel') || {};
            const registryOperationTypes = this.filtersPanelItems[0].data.find(
                block => block.code === 'registryOperationTypes',
            );
            const registryStatuses = this.filtersPanelItems[0].data.find(block => block.code === 'registryStatuses');
            registryOperationTypes.filters[0].items = registersModel.operationTypes || [];
            registryStatuses.filters[0].items = registersModel.licenseStatuses || [];
        }

        if (userFilters) {
            this.filtersPanelItems = userFilters;
        }

        const user = this.storage.getItem('user');
        const externalAuth = this.storage.getItem('isExternalAuth');
        // Добавление возможности отключения фильтра по текущему МФЦ при наличии соответствующего полномочия
        if (
            (!externalAuth && this.accessService.existPermission(this.permissions.No_Limit_By_Current_Unit)) ||
            (externalAuth && user.isSuperAdmin)
        ) {
            this.filtersPanelItems[0].data.unshift({
                name: 'Все организации',
                filters: [
                    {
                        type: 'checkboxes',
                        isInParentComponentProcessing: true,
                        code: 'noLimitByUnit',
                        items: [
                            {
                                name: 'Не фильтровать',
                                code: 'noFilter',
                                placeholder: 'Все организации',
                            },
                        ],
                    },
                ],
            });
        }
    }

    private _prepareSort(): void {
        const userSort = this.storage.getItem('appealsSortQuery');
        if (userSort) {
            const parts = userSort.split(',');
            this.selectedSortField = parts[0].trim();
            this.selectedSortOrder = parts.length > 1 ? parts[1] : 'ASC';
        } else {
            this.selectedSortField = DEFAULT_SORT_FIELD;
            this.selectedSortOrder = 'ASC';
        }
        this.sortOpts.field = this.selectedSortField;
        this.sortOpts.order = this.selectedSortOrder;
    }

    /**
     * Индексирование дел в списке
     * @param index
     * @param appeal - дело
     */
    public indexedAppeals(index, appeal): string {
        return appeal._id;
    }

    /**
     * Обработка одинарного клика по карточке дела - загрузка карточки предпросмотра
     * @param appeal - обрабатываемое дело
     */
    public clickByAppeal(appeal): void {
        this.previewedAppeal = null;
        this.timer = setTimeout(() => {
            if (!this.prevent) {
                this.previewMode = true;
                this.ref.tick();

                let collectionName = 'appeals';

                if (appeal.status.code === 'archive') {
                    collectionName = 'appealsArchive';
                }
                this.rest.find(collectionName, appeal._id).then(item => {
                    this.previewedAppeal = item;
                });
            }

            this.prevent = false;
        }, 300);
    }

    /**
     * Обработка двойного клика по карточке дела - переход к редактированию дела
     * @param appeal - обрабатываемое дело
     */
    public dblclickByAppeal(appeal): void {
        // Если список дел открыт в режиме выбора дел, то не позволяем открывать дело на редактирование
        if (!this.appealService.groupOperation) {
            clearTimeout(this.timer);
            this.prevent = true;
            if (appeal._id) {
                this.router.navigate([this.moduleBaseUrl, 'appeals', 'edit', appeal._id]);
            }
        }
    }

    /**
     * Определение классов для карточки дела в соответствии с его статусом
     * @param item - дело
     * @param last - признак последнего элемента в списке
     * @returns {string}
     */
    public appealCardStyle(item, last): string {
        let classes = 'short border-left-4 border-right-4';

        if (item.status) {
            // Если обрабатываемое дело выбрано для предпросмотра
            if (this.previewedAppeal && this.previewedAppeal.guid === item.guid) {
                classes += ' ' + this.appealStatusService.getStatusProperty(item.status, 'borderLeft');
                classes += ' active';
            }
        }

        if (last) {
            classes += ' border-bottom border-bottom-default';
        }

        return classes;
    }

    /**
     * Поиск осуществляется при вводе каждой буквы
     */
    public async search(event?) {
        if (event) {
            await this.loadData(LOAD_DATA_MODES.REPLACE);
        } else {
            this.clearSearch();
        }
    }

    /**
     * Очистка поискового запроса
     */
    public async clearSearch() {
        this.globalSearch = null;
        await this.loadData(LOAD_DATA_MODES.REPLACE);
    }

    /**
     * Определение свойства для статуса дела
     * @param appeal
     * @param property
     * @returns {any}
     */
    public getStatusProperty(appeal, property): any {
        if (!this.metaReglament) {
            return this.appealStatusService.getStatusProperty(appeal.status, property);
        }

        const appealData = this.metaReglament.blocks.find(currentStatus => currentStatus.code === 'appealData');
        const statuses = appealData.appealStatuses;
        const status = statuses.find(elm => appeal.subservice.status && elm.code === appeal.subservice.status.code);

        if (status) {
            return property === 'background' ? 'bg-' + status.theme + '-300' : status[property];
        }
    }

    /**
     * Определение стиля Label для карточки дела в соответствии с его статусом
     * @param item - дело
     * @returns {any}
     */
    public colorLabelStatuses(item): any {
        const code = item.status.code ? item.status.code : item.status.mainStatusCode;

        return this.appealService.getStatusProperty(code, 'background');
    }

    /**
     * Проверяет, есть ли в деле непрочитанные документы обратной связи от заявителя
     */
    public checkAppealHasUnreadUserDocuments(appeal) {
        return (
            appeal.documents &&
            appeal.documents.some(document => document.feedbackMessageGuid && document.feedbackDocumentUnchecked)
        );
    }

    /**
     * Расчет ширины Label в карточке дела
     * @param item - дело
     * @returns {string}
     */
    public widthLabel(item): number {
        if (item.status && (item.status.code === 'issuedPost' || item.status.code === 'issuedReturnToOGV')) {
            return 155;
        } else {
            return 115;
        }
    }

    public progressContainerWidth(item): string {
        return 'calc(100% - ' + this.widthLabel(item) + 'px)';
    }

    /**
     * Получение наименования статуса дела (с учетом возможного нахождения дела в подстатусе)
     * @param item - дело
     */
    public getStatusName(item): string {
        if (item.status.code) {
            return item.status.name;
        } else if (item.status.mainStatusCode) {
            const status = this.appealService.statuses.find(st => item.status.mainStatusCode === st.code);

            return status.name;
        }
    }

    /**
     * Создание нового дела (переход на страницу с выбором услуги)
     */
    public createNewDeal(): void {
        this.router.navigate([this.moduleBaseUrl, 'appeals', 'services']);
    }

    /**
     * КНМ на то, что осуществляется поиск по коллекции архивных дел
     * @param search
     * @returns {boolean}
     */
    public checkArchiveAppealsSearch(search): boolean {
        let isArchiveSearch = false;

        search.forEach(item => {
            if (
                item.field &&
                ['status.code', 'subservices.status.code'].indexOf(item.field) !== -1 &&
                item.value === 'archive'
            ) {
                isArchiveSearch = true;
            } else if (item.orSubConditions) {
                const value = this.checkArchiveAppealsSearch(item.orSubConditions);

                isArchiveSearch = value ? value : isArchiveSearch;
            }
        });

        return isArchiveSearch;
    }

    private _correctSearch(search) {
        const searchItems = [];
        let collection = 'appeals';

        search.forEach(searchItem => {
            if (searchItem.field !== 'collection') {
                searchItems.push(searchItem);
            } else {
                collection = searchItem.value;
            }
        });

        return { collection: collection, search: searchItems };
    }

    public async setSortOptsAndLoadData(sortOpts) {
        this.selectedSortField = sortOpts.field;
        this.sortOpts = sortOpts;
        await this.loadData(LOAD_DATA_MODES.REPLACE, sortOpts);
    }

    /**
     * Метод получения дел после фильтрации, либо скроллинга
     * @param type: replace - полное обновление списка; add - добавление записей в конец списка (скроллинг)
     * @param sortOpts - поле и порядок сортировки
     * @param disableDraftsOnDefaultSortField - скрывать ли черновики в этой выборке (для фитрации по datePlaneFinish, когда это поле null и черновики отображаются вначале)
     */
    public async loadData(type, sortOpts = this.sortOpts, disableDraftsOnDefaultSortField = true) {
        const noPagesOrNotLastPage = !this.totalPages || this.currentPage < this.totalPages - 1;
        const isReplace = type === LOAD_DATA_MODES.REPLACE;

        if (!isReplace && !noPagesOrNotLastPage) {
            return;
        }

        // tslint:disable-next-line:prefer-const
        let { search, executor, controlOperator, countSearch } = this._prepareSearchClause();

        const searchHasFilterSettings = this._searchHasFilterSettings(search);
        if (!searchHasFilterSettings) {
            const sortFieldNotNullClause = {
                field: sortOpts.field,
                operator: 'neq',
                value: null,
            };

            search.push(sortFieldNotNullClause);
        }

        // Страница
        let page = 0;
        if (type === LOAD_DATA_MODES.ADD) {
            page = this.currentPage + 1;
        }

        // Параметры сортировки
        const sort = `${sortOpts.field || DEFAULT_SORT_FIELD}${sortOpts.order ? ',' + sortOpts.order : ''}`;
        const params = {
            search: {
                search,
                executor,
                controlOperator,
            },
            size: APPEALS_DEFAULT_LIST_SIZE,
            page,
            sort,
            prj: DEFAULT_APPEALS_PROJECTION,
        };

        this.storage.setItem('appealsSortQuery', sort);

        // Получение списка дел с сервера
        const appeals = await this.rest.search(this.searchCollection, params);

        if (sortOpts && sortOpts.field && !searchHasFilterSettings) {
            const countParams = cloneDeep(params);
            countParams.search.search = countSearch;
            await this.rest.search(this.searchCollection, countParams);
        }

        if (type === LOAD_DATA_MODES.REPLACE) {
            if (appeals.length || searchHasFilterSettings) {
                this.appeals = appeals;
            } else if (this.appeals.length && disableDraftsOnDefaultSortField) {
                await this.loadData(LOAD_DATA_MODES.REPLACE, sortOpts, false);

                return;
            }
        } else if (appeals.length) {
            appeals.forEach(item => {
                this.appeals.push(item);
            });
        }

        this.appeals = this.appealService.processAppealsProperties(this.appeals);
        this._selectAppeals();

        this.currentPage = this.rest.pagination.page;
        this.totalPages = this.rest.pagination.totalPages;
        this.totalAppealsCount = this.rest.pagination.total;

        if (searchHasFilterSettings || (appeals && appeals.length >= APPEALS_DEFAULT_LIST_SIZE)) {
            return;
        }

        // убираем условия, связанные с полями сортировки и указываем явно, что хотим искать по таким нулевым полям
        search = search.filter(
            clause => !(clause.field === sortOpts.field && clause.operator === 'neq' && !clause.value),
        );
        const sortFieldNullClause = {
            field: sortOpts.field,
            operator: 'eq',
            value: null,
        };
        search.push(sortFieldNullClause);

        this.rest.pagination.page = 0;
        params.search.search = search;

        let nullFieldAppeals;
        let currentNullPage = 0;
        let totalNullPages = 0;
        let notOnLastNullPage;

        // догружаем дел с нулевыми полями сортировки
        do {
            params.page = currentNullPage;
            nullFieldAppeals = await this.rest.search(this.searchCollection, params);
            const processedNullFieldAppeals = this.appealService.processAppealsProperties(nullFieldAppeals);
            this.appeals = this.appeals.concat(processedNullFieldAppeals);

            currentNullPage = this.rest.pagination.page;
            totalNullPages = this.rest.pagination.totalPages;
            currentNullPage++;

            notOnLastNullPage = currentNullPage + 1 <= totalNullPages;
        } while (notOnLastNullPage);
    }

    private _searchHasFilterSettings(search) {
        return (
            search &&
            search.some(
                query =>
                    this._hasNonDefaultFilters(query) ||
                    this._hasPlaneFinishFilter(query) ||
                    this._hasSourceFilter(query),
            )
        );
    }

    private _hasNonDefaultFilters(query) {
        return (
            (query.field && !this.defaultSearchFields.has(query.field)) ||
            (query.orSubConditions &&
                query.orSubConditions.some(item => {
                    if (item.field === 'status.code') {
                        return item.value !== APPEAL_STATUSES.DRAFT && item.value !== APPEAL_STATUSES.PROCESS;
                    }

                    return !this.defaultSearchFields.has(item.field);
                }))
        );
    }

    private _hasPlaneFinishFilter(query) {
        return query.andSubConditions && query.andSubConditions.some(item => item.field === 'datePlaneFinish');
    }

    private _hasSourceFilter(query) {
        return (
            (query.field && this.sourceFields.has(query.field)) ||
            (query.orSubConditions && query.orSubConditions.some(item => this.sourceFields.has(item.field)))
        );
    }

    private _prepareSearchClause() {
        // Параметры настроенных фильтров
        let search = this.filtersPanel.prepareFilters();

        // Поиск фильтра по исполнителю и преобразование его в поддерживаемый беком формат
        let executor;
        const executorFilter = search.find(clause => clause.field && clause.field === AppealData.EXECUTOR_FILTER_NAME);
        if (executorFilter) {
            executor = executorFilter.value;
        }

        // Поиск фильтра по ответственному и преобразование его в поддерживаемый беком формат
        let controlOperator;
        const controlOperatorFilter = search.find(
            clause => clause.field && clause.field === AppealData.CONTROL_OPERATOR_FILTER_NAME,
        );
        if (controlOperatorFilter) {
            controlOperator = controlOperatorFilter.value;
        }

        // Очищаем запрос от данных по исполнителю и ответственному
        search = search.filter(
            clause =>
                !(
                    clause.field &&
                    (clause.field === AppealData.EXECUTOR_FILTER_NAME ||
                        clause.field === AppealData.CONTROL_OPERATOR_FILTER_NAME)
                ),
        );

        // Корректировка поиска
        const correctSearch = this._correctSearch(search);

        this.searchCollection = correctSearch.collection;
        search = correctSearch.search;

        // Обработка параметров "Истекает срок выполнения" "Просрочено"
        const filterByTaskActualProperty = this.filtersPanel.checkInComponentProcessingParam('actualType');

        if (filterByTaskActualProperty) {
            search = search.concat(this._prepareFilterByActualProperty(filterByTaskActualProperty));
        }

        // Обработка фильтра "Источник"
        const filterBySourceProperties = this.filtersPanel.checkInComponentProcessingParam('source');

        if (Array.isArray(filterBySourceProperties) && filterBySourceProperties.length > 0) {
            search.push(this._prepareFilterBySource(filterBySourceProperties));
        }

        let countSearch = cloneDeep(search);

        // Обработка "не фильтровать"
        search = this._setFilterAllUnits(search);
        countSearch = this._setFilterAllUnits(countSearch, false);

        // Обработка параметров глобального поиска - добавляем в результирующий поиск orSubCondition по нескольким полям
        if (this.globalSearch) {
            const fieldsParams = ['subjects.header', 'subservices.title', 'subservices.shortTitle', 'shortNumber'].map(
                field => ({ field, operator: 'like', value: this.globalSearch }),
            );
            const orSubConditions = {
                orSubConditions: fieldsParams,
            };

            search.push(orSubConditions);
        }

        return { search, executor, controlOperator, countSearch };
    }

    public saveFilters(filters) {
        this.storage.setItem('appealsFilters', filters);
    }

    // ------------- МЕТОДЫ ОБРАБОТКИ ГРУППОВЫХ ОПЕРАЦИЙ ------------- //
    private _selectAppeals(): void {
        this.appeals.forEach(appeal => {
            if (this.appealService.groupOperation) {
                this.checkForSelect(appeal);
            } else {
                appeal.selected = false;
            }
        });
    }

    public getSelectedAppealsCount(): number {
        return this.appeals.filter(item => item.selected).length;
    }

    /**
     * Добавление / удаление краткой информации по дела в / из состава дел групповой операции
     * @param appeal - обрабатываемое дело
     */
    public addOrRemoveShortAppeal(appeal): void {
        appeal.selected = !appeal.selected;

        // Если включен режим выбора дел
        if (
            appeal.selected &&
            !this.appealService.groupOperation &&
            !this.appealService.isProcessSelectAppealsForPacket
        ) {
            this.appealService.groupOperation = { appeals: [] };
        }
        // Если активирован режим выбора дел для пакета
        if (this.appealService.isProcessSelectAppealsForPacket) {
            this._addAppealToPacket(appeal);
        }

        if (this.appealService.groupOperation) {
            // Дело выбрано - создаем его краткий объект и помещаем в общую коллекцию
            if (appeal.selected) {
                this.createAndAddShortAppeal(appeal);
            } else {
                // Дело не выбрано - находим его индекс и удаляем из общей коллекции
                const appealIndex = this.appealService.groupOperation.appeals.findIndex(
                    item => item._id === appeal._id,
                );

                this.appealService.groupOperation.appeals.splice(appealIndex, 1);

                if (
                    !this.appealService.groupOperation.status &&
                    this.appealService.groupOperation.appeals.length === 0
                ) {
                    this.appealService.groupOperation = null;
                }
            }
        }
    }

    /**
     * Добавление / удаление дела в состав дел пакета
     * @param appeal
     */
    private _addAppealToPacket(appeal): void {
        const foundIndex = this.appealService.packet.appeals.findIndex(item => item.id === appeal._id);

        if (!appeal.selected && foundIndex !== -1) {
            this.appealService.packet.appeals.splice(foundIndex, 1);

            return;
        }

        if (appeal.selected && (foundIndex === -1 || foundIndex === undefined)) {
            const appealForAdd = {
                id: appeal._id,
                subservice: {
                    id: appeal.subservice.id,
                    title: appeal.subservice.shortTitle,
                },
                shortNumber: appeal.shortNumber,
            };

            this.appealService.packet.appeals.push(appealForAdd);
        }
    }

    /**
     * Создание нового короткого дела
     * @param appeal - либо проекция, либо полное дело
     */
    public createAndAddShortAppeal(appeal): void {
        const shortAppeal = {
            _id: appeal._id,
            auid: appeal.auid,
            shortNumber: appeal.shortNumber,
            complexTitle: appeal.complexSubservice ? appeal.complexSubservice.name : '',
            firstSubserviceTitle: appeal.subservice ? appeal.subservice.shortTitle : '',
            subservicesTitles: [],
        };

        shortAppeal.subservicesTitles.push(appeal.subservice.shortTitle);

        this.appealService.groupOperation.appeals.push(shortAppeal);
    }

    /**
     * Переход на создание новой сущности, для которой отмечались дела и создавались краткие объекты
     * @param type
     */
    public createNewObjectByShortAppealList(type): void {
        // Обновляем список коротких дел в сервисе
        if (type === 'groupOperation') {
            if (!this.appealService.groupOperation._id) {
                // Открываем новую групповую операцию
                this.router.navigate(['/ais/group-operations/create']);
            } else {
                // Переходим в уже созданну (сохраненную) групповую операцию
                this.router.navigate(['/ais/group-operations/edit', this.appealService.groupOperation._id]);
            }
        } else if (type === 'transportAppeal') {
            // todo: переход на передачу дел
        }
    }

    /**
     * КНМ на то, что дело отмечено для помещения в объект
     * @param appeal
     */
    public checkForSelect(appeal): void {
        appeal.selected = false;

        if (this.appealService.groupOperation && this.appealService.groupOperation.appeals) {
            const find = this.appealService.groupOperation.appeals.find(item => item._id === appeal._id);

            if (find) {
                appeal.selected = true;
            }
        }
    }

    /**
     * Выбор (снятие выбора) всех дел по заданным фильтрам и получение кратких дел
     */
    public selectAllAppeals(): void {
        this.isAllAppealsSelected = !this.isAllAppealsSelected;

        if (this.isAllAppealsSelected) {
            // Параметры настроенных фильтров
            const search = this.filtersPanel.prepareFilters();

            this.appealService.prepareExternalNumberFilters(search);

            const params = {
                search: { search: search, textSearch: '' },
                page: 0,
                size: 1000,
                sort: 'datePlaneFinish',
                prj: 'shortAppealList',
            };

            // Обработка параметров глобального поиска
            if (this.globalSearch) {
                params.search['textSearch'] = this.globalSearch;
            }

            this.rest.search('appeals', params).then((shortAppeals: any) => {
                this.appealService.groupOperation.appeals = [];

                for (const shortAppeal of shortAppeals) {
                    this.createAndAddShortAppeal(shortAppeal);
                }

                this._selectAppeals();
            });
        } else {
            this._selectAppeals();
        }
    }

    /**
     * Очистка всех фильтров в панели фильтров
     */
    public clearFilters(): void {
        this.clearSearch();
        this.filtersPanel.clearFilters();
    }

    /**
     * Возврат к режиму редактирования / создания пакета после выбора дел
     */
    public returnToPacket(): void {
        if (this.appealService.packet) {
            if (this.appealService.packet._id) {
                this.router.navigate(['ais/packets/edit', this.appealService.packet._id]);
            } else {
                this.router.navigate(['ais/packets/create']);
            }
        }
    }

    /**
     * Фильтрация по сроку выполнения (просроченная/истекает)
     * @param data
     */
    private _prepareFilterByActualProperty(data): any[] {
        const statusReject = ['complete', 'draft', 'serviceReject', 'receiveDocsReject', 'annulled', 'archive'];
        const arr = [];
        let usedArr = arr;

        if (data.length > 1) {
            arr.push({
                orSubConditions: [],
            });
            usedArr = arr[0].orSubConditions;
        }

        const findExpire = data.find(item => item.code === 'expired');

        if (findExpire) {
            usedArr.push({
                andSubConditions: [
                    { field: 'datePlaneFinish', operator: 'lt', value: moment().format('YYYY-MM-DD') },
                    { field: 'status.code', operator: 'eq', value: 'process' },
                    { field: 'subservices.status.code', operator: 'nin', value: statusReject },
                ],
            });
        }

        const findEnding = data.find(item => item.code === 'termEnding');

        if (findEnding) {
            usedArr.push({
                andSubConditions: [
                    {
                        field: 'datePlaneFinish',
                        operator: 'gt',
                        value: moment().startOf('day').format('YYYY-MM-DD'),
                    },
                    {
                        field: 'datePlaneExpired',
                        operator: 'lt',
                        value: moment().startOf('day').add(+1, 'days'),
                    },
                    { field: 'status.code', operator: 'eq', value: 'process' },
                    { field: 'subservices.status.code', operator: 'nin', value: statusReject },
                ],
            });
        }

        return arr;
    }

    /**
     * Фильтрация по всем организациям
     * @param search
     */
    private _setFilterAllUnits(search, unshift = true): any {
        const noLimitUnit = this.filtersPanel.checkInComponentProcessingParam('noLimitByUnit');
        const index = search.findIndex(item => item.orSubConditions && item.orSubConditions[0].field === 'unit.id');

        if (noLimitUnit && noLimitUnit[0] && index !== -1) {
            search.splice(index, 1);
        } else {
            if (index === -1 && unshift) {
                search.unshift(this.baseSearch);
            }
        }

        return search;
    }

    /**
     * Фильтрация по источнику (ЕПГУ, РПГУ ...)
     *
     * @param data - перечень активных чекбоксов
     */
    private _prepareFilterBySource(data: any[]): any {
        const params = data.map(item => ({ field: item.code, operator: 'eq', value: true }));

        return data.length > 1 ? { orSubConditions: params } : params[0];
    }

    protected controlOperator(appeal) {
        return Array.isArray(appeal.controlOperator) ? appeal.controlOperator.join(', ') : appeal.controlOperator;
    }
}
