import { Component, EventEmitter, Input, OnInit, Output, ViewChildren } from '@angular/core';
import { AccessService, TranslateService } from '@evolenta/core';
import { CommonUtilities } from '@evolenta/utilities';
import { DocumentService } from '../../document.service';
import { AppealValidateService } from '../../../../appeal-validate.service';
import { AppealStatusService } from '../../../../appeal-status.service';
import { Permission } from '../../../../../../../common/services/permission';
import * as _ from 'lodash-es';
import { RsoService } from '../../../../../../../common/services/rso.service';

@Component({
    selector: 'appeal-subservice-document-groups',
    templateUrl: 'appeal-subservice-document-groups.component.html',
})
export class AppealSubserviceDocumentGroupsComponent implements OnInit {
    @Input() public appeal; // формируемое дело
    @Input() public appealSubservice; // Настройки услуги в деле
    @Input() public groupCode = ''; // Код группы
    @Input() public task; // задача процесса
    @Input() public onlyGroups; // обработка только определенных групп
    @Input() public allowEdit = true;
    @Input() public feedbackMessageGuid;

    @Output() public onEditDocument = new EventEmitter<object>(); // Перевод в режим редактирования документа (нажатие кнопки "Редактировать")
    @Output() public onPrintDocument = new EventEmitter<object>(); // Передача управления в родительский компонент для формирования печатной формы
    @Output() public onDeleteDocument = new EventEmitter<object>(); // Передача управления в родительский компонент для удаления документа дела
    @Output() public onApplyDocument = new EventEmitter<object>(); // Передача управления в родительский компонент для удаления документа дела
    @ViewChildren('documentLink') public documentLinks;

    public groups = [];

    public allowAddDocument = true; // Возможность добавления документов в группы документов
    public permissions = Permission; // Набор прав системы

    public localizations;

    public isDisabledByRso = false;

    public constructor(
        public accessService: AccessService,
        private documentService: DocumentService,
        private validateService: AppealValidateService,
        private appealStatusService: AppealStatusService,
        private translate: TranslateService,
        private rsoService: RsoService
    ) {}

    /**
     * Инициализация компонента - получение списка груп для услуги дела
     */
    public ngOnInit() {
        this._loadTranslations();
        this.initGroups();
        this.isDisabledByRso = this.rsoService.canNotEditAppeal(this.appeal);
    }

    public _loadTranslations() {
        this.translate.get(['common', 'appeals.documents']).subscribe((res: string) => {
            this.localizations = res;
        });
    }

    /**
     * Инициализация данных групп документов
     */
    public initGroups() {
        if (this.appealSubservice) {
            this.groups = this.documentService.appealSubservicesData[this.appealSubservice.guid].appealDocumentGroups;

            if (this.onlyGroups && this.onlyGroups.length) {
                this.groups = this.groups.filter((group) => this.onlyGroups.indexOf(group.code) !== -1);
            }

            this.groups = this.groups.map((group) => {
                group.feedbackMessageGuid = this.feedbackMessageGuid;
                group.isFeedbackFileFromPGMU = true;

                return group;
            });
            this.allowAddDocument =
                this.appealStatusService.finishStatuses.indexOf(this.appealSubservice.status.code) === -1;

            return;
        }

        // Определение параметров дополнительных групп документов
        const groupName =
            this.groupCode && this.groupCode === 'complex' ? 'Документы комплексного запроса' : 'Прочие документы';
        const findOtherGroup = this.groups.find((item) => item.name === groupName);
        if (!findOtherGroup) {
            this.groups.push({ guid: null, name: groupName, min: 0, isRequired: false, code: this.groupCode });
        }
        this.allowAddDocument = this.appealStatusService.allowEdit;
    }

    /**
     * КНМ доступности кнопки "Добавить документ"
     * @param group
     * @returns {boolean}
     */
    public checkAllowAddDocument(group) {
        return (
            this.allowAddDocument ||
            (this.appealSubservice &&
                this.appealSubservice.status.code === 'beforeIssued' &&
                group.code === 'documentsRequests' &&
                this.accessService.hasAccess([this.permissions.Envelope_Create]))
        );
    }

    /**
     * Добавление документа в дело
     * @param group - группа, в которую осуществляется добавление
     */
    public addDocument(group) {
        const documentGuid = CommonUtilities.GenerateGuid();
        const document: any = {
            guid: documentGuid,
            groupName: group.name,
            subjects: [],
            parentEntries: 'appeals.documents',
            printForm: null,
            isInput: !!group.isInput,
            isOutput: !!group.isOutput,
            isResult: !!group.isOutput,
        };
        if (group.guid) {
            document.groupGuid = group.guid;
        }
        if (this.appealSubservice) {
            document.subserviceId = this.appealSubservice.id;
            document.subserviceGuid = this.appealSubservice.guid;
        }
        if (group.code) {
            document.groupCode = group.code;
        }

        // Если документ добавляется после регистрации дела / услуги, то к нему добавляется специальное свойство
        if (
            (this.appealSubservice && this.appealSubservice.status.code !== 'draft') ||
            (!this.appealSubservice && this.appeal.status.code !== 'draft')
        ) {
            document.afterRegister = true;
        }
        this.documentService.tempData = _.cloneDeep(this.documentService.data);

        // Инициализация данных в сервисе
        this.documentService.initDocumentDataFromAppeal(document);

        this.validateService.initValidateElement('documents', documentGuid);
        if (!group.docs) {
            group.docs = [];
        }
        if (group.docs && group.docs.length === 1) {
            const documentData = this.documentService.data[documentGuid];
            this.documentService.selectDocumentKind(document, documentData, group.docs[0]);
            this.onApplyDocument.emit({ document: document });
        } else {
            this.onEditDocument.emit({ document: document, group: group });
        }
    }

    /**
     * Перевод отдельного документа в режим редактирования (нажатие кнопки "Редактировать")
     * @param data - {document: редактируемый документ; group: группа документа}
     */
    public editDocument(data) {
        this.onEditDocument.emit(data);
    }

    /**
     * Определение числа документов дела в группе
     * @param group - обрабатываемая группа
     * @returns {number}
     */
    public countDocumentsInGroup(group) {
        return this.appeal.documents.filter(
            (item) =>
                (item.groupGuid &&
                    item.groupGuid === group.guid &&
                    item.subserviceGuid === this.appealSubservice.guid) ||
                (!item.groupGuid && !group.guid && !group.code && !item.groupCode) ||
                (group.code === 'complex' && item.groupCode === 'complex')
        ).length;
    }

    /**
     * Определение числа прикрепленных файлов во всех группах
     * @param group - обрабатываемая группа
     * @returns {number}
     */
    private countAllDocuments(group): number {
        if (!group && !group.guid && !this.appeal && !this.appeal.documents && Array.isArray(!this.appeal.documents)) {
            return 0;
        }
        return this.appeal.documents
            .filter(
                (item: any) =>
                    (item.groupGuid &&
                        item.groupGuid === group.guid &&
                        item.subserviceGuid === this.appealSubservice.guid) ||
                    (!item.groupGuid && !group.guid && !group.code && !item.groupCode) ||
                    (group.code === 'complex' && item.groupCode === 'complex')
            )
            .reduce((cur: number, next: { files: [] }) => {
                if (!next.files || !Array.isArray(next.files)) {
                    return cur;
                }
                return cur + next.files.length;
            }, 0);
    }

    /**
     * Получение списка ошибок для группы документов
     * @param group - проверяемая группа
     * @returns {string}
     */
    public getErrorsList(group) {
        return this.validateService.getElementErrorsList(group.guid, 'documentGroups', this.appealSubservice.guid);
    }

    /**
     * КНМ наличия ошибок в группе документов
     * @param group - проверяемая группа
     */
    public checkGroupValid(group) {
        if (group.guid) {
            return this.validateService.checkElementValid(group.guid, 'documentGroups', this.appealSubservice.guid);
        }

        return true;
    }

    /**
     * Отправка на формирование печатной формы
     * @param document
     */
    public printDocument(document) {
        this.onPrintDocument.emit(document);
    }

    /**
     * Передача управления в родительский компонент для удаления документа дела
     * @param document
     */
    public deleteDocument(document) {
        this.onDeleteDocument.emit(document);
    }

    /**
     * Запуск процесса проверки приложенных ЭП на их валидность
     */
    public async verifySignature(group) {
        await this.documentService.verifySignatures(this.appeal._id, group.guid);
        await this.documentService.initDataAfterSignVerification();
    }
}
