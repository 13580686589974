import { Inject, Injectable } from '@angular/core';
import { CORE_CONFIG, CoreDynamicConfigInterface, StorageService } from '@evolenta/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { version } from '../../../../package.json';

interface IVersionInfo {
    version: string;
}

@Injectable()
export class VersionService {
    /**
     * @deprecated use appVersion
     */
    public currentVersion: any = {}; // текущая версия сборки
    public appVersion: string = version;
    public newVersion = null; // новая актуальная версия сборки
    public checkInterval; // интервал проверки новой версии

    public constructor(
        @Inject(CORE_CONFIG) private readonly config: CoreDynamicConfigInterface,
        private storage: StorageService,
        private http: HttpClient,
    ) {
        this.currentVersion = this.storage.getItem('version');
        void this.getAppVersion(); // Получение актуальной версии сборки
    }

    /**
     * Получение информации о версии
     * @deprecated use getAppVersion()
     */
    public async getVersion(forceUpdate = false) {
        // Заголовки, отключающие кэширование запроса
        let headers = new HttpHeaders();

        headers = headers.append('Cache-Control', 'no-cache');
        headers = headers.append('Pragma', 'no-cache');
        headers = headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');

        const url = this.config.endpoints.server + 'getRevision';

        // const response: any = await this.http.get(url, { headers }).toPromise();
        const versionInfo = {
            client: version,
            fullrev: 'build-1790-g78c9121c', // TODO нужно ли это?
            hash: '78c9121c', // TODO нужно ли это?
        };

        this.currentVersion = versionInfo;
        // Если ранее выставлена текущая версия, и пришедшая с сервера версия отличается от нее
        /*if (this.currentVersion && this.currentVersion.client !== versionInfo.client) {
            // Если задано принудительное обновление
            if (forceUpdate) {
                this.storage.setItem('version', versionInfo); // сохранение новой версии в локальное хранилище
                location.reload(); // перезагрузка статики с сервера
            } else {
                this.newVersion = versionInfo; // сохранение данных о новой версии в переменную (для вывода кнопки "Обновить")
                clearInterval(this.checkInterval); // очистка интервала опроса
            }
        } else if (!this.currentVersion) {
            // Если не установлена текущая версия сборки (первичная загрузка приложения)
            this.currentVersion = versionInfo; // инициализация актуальной сборки
            this.storage.setItem('version', versionInfo); // сохранение данных о сборке в локальном хранилище
            this.checkVersion(); // запуск процедуры опроса статуса через равные промежутки времени
        } else {
            // Версия не изменилась
            this.checkVersion();
        }*/
    }

    /**
     * Процедура запуска интервальной проверки версии клиента
     */
    public checkVersion() {
        if (!this.checkInterval && !this.newVersion) {
            this.checkInterval = setInterval(async () => {
                await this.getAppVersion();
            }, 600000);
        }
    }

    public changeCurrentVersionOnNew() {
        this.currentVersion = this.newVersion;
        this.storage.setItem('version', this.newVersion);
        this.newVersion = null;
    }

    public async getAppVersion() {
        // Заголовки, отключающие кэширование запроса
        let headers = new HttpHeaders();

        headers = headers.append('Cache-Control', 'no-cache');
        headers = headers.append('Pragma', 'no-cache');
        headers = headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');

        const url = this.config.endpoints.server + 'getVersion';

        this.http
            .get(url, { headers })
            .toPromise()
            .then((response: IVersionInfo) => {
                this.appVersion = response && response.version ? response.version : version; // если нет версии приложения, берём версию фронта
                console.log('appVersion: ' + this.appVersion); // todo: remove after ok running
            });
    }
}
